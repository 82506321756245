import React, {useEffect, useState} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {Delete} from '@material-ui/icons';
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 4, marginBottom: theme.spacing.unit * 2
  },
});

const Placeholder = ({value, onChange}) => {
  return <Grid container justify="space-between" alignItems="center" style={{marginBottom: 16}}>
    <Grid item xs={5}>
      <TextField
        label="Placeholder"
        placeholder="Placeholder"
        value={value.placeholder || ''}
        fullWidth
        onChange={e => onChange({placeholder: e.target.value, title: value.title})}
        margin="normal"
      />
    </Grid>
    <Grid item xs={5}>
      <TextField
        label="Title"
        placeholder="Title"
        value={value.title || ''}
        fullWidth
        onChange={e => onChange({placeholder: value.placeholder, title: e.target.value})}
        margin="normal"
      />
    </Grid>
    <Grid item><IconButton onClick={() => onChange(null)}><Delete/></IconButton></Grid>
  </Grid>
};

function decodePlaceholders(placeholders) {
  return Object.keys(placeholders).map(placeholder => ({
    placeholder, title: placeholders[placeholder]
  }))
}

const CustomMessagePlaceholdersEditor = ({classes, customPlaceholders, onChange}) => {
  const [placeholders, setPlaceholders] = useState(decodePlaceholders(customPlaceholders));

  useEffect(() => {
    onChange(placeholders);
  }, [placeholders]);

  return <div className={classes.root}>
    {placeholders.map((placeholder, position) => <Placeholder key={position} value={placeholder} onChange={(value) => {
      const newPlaceholders = [...placeholders];
      newPlaceholders[position] = value;
      setPlaceholders(newPlaceholders.filter(val => !!val));
    }}/>)}
    <Button
      fullWidth
      color="primary"
      onClick={() => setPlaceholders(placeholders.concat([{placeholder: '', title: ''}]))}
    >
      Add custom placeholder
    </Button>
  </div>
}

export default withStyles(styles)(CustomMessagePlaceholdersEditor);
