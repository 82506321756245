import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import ReactPropTypes from 'prop-types';
import Table from 'Components/Table/Table';
import Loader from 'Components/Progress/Loader';
import { withStyles } from '@material-ui/core/styles';
import {
  Add,
  Edit,
  Delete,
  VideoCall,
  Fingerprint,
  Settings
} from '@material-ui/icons';
import ScriptForm from './ScriptForm';
import ScriptVideoRecorder from './ScriptVideoRecorder';
import ScriptVideoEditor from './ScriptVideoEditor';
import VideoPreview from 'Components/Video/Preview';
import VideoSettingsForm from './VideoSettingsForm';
import EditableImage from 'Components/Image/EditableImage';
import * as Taxonomy from 'Utils/Taxonomy';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const styles = () => ({
  categories: { overflow: 'hidden', textOverflow: 'ellipsis' },
  filter: {
    margin: '0 20px'
  },
  filterSelect: {
    minWidth: 150
  }
});

class Scripts extends React.Component {
  state = { magicLink: null };
  static propTypes = {
    classes: ReactPropTypes.object.isRequired,
    ScriptsStore: PropTypes.observableObject,
    CategoriesStore: PropTypes.observableObject,
    MerchantTypesStore: PropTypes.observableObject,
    CommonStore: PropTypes.observableObject
  };
  componentWillMount() {
    const {
      CommonStore,
      ScriptsStore,
      CategoriesStore,
      MerchantTypesStore
    } = this.props;
    CommonStore.setTitle('Library');
    ScriptsStore.reset();
    ScriptsStore.loadScripts();
    CategoriesStore.loadTree();
    CategoriesStore.loadTaxonomies();
    MerchantTypesStore.reset();
    MerchantTypesStore.load();
  }

  handleChangePage = (event, page) => {
    this.props.ScriptsStore.setPage(page);
    this.props.ScriptsStore.loadScripts();
  };

  handleChangeRowsPerPage = event => {
    this.props.ScriptsStore.setRowsPerPage(event.target.value);
    this.props.ScriptsStore.loadScripts();
  };
  handleDelete = script => {
    this.props.CommonStore.confirmAction('Delete script?', () => {
      const { ScriptsStore } = this.props;
      ScriptsStore.deleteScript(script.id);
    });
  };
  handleVideoClear = messageTemplate => {
    const { ScriptsStore, CommonStore } = this.props;
    CommonStore.confirmAction('Remove example video?', () => {
      ScriptsStore.deleteScriptVideo(messageTemplate.id);
    });
  };
  renderFilters = () => {
    const {
      classes,
      CategoriesStore: { taxonomies },
      MerchantTypesStore: { merchantTypes },
      ScriptsStore: {
        isLoading,
        filters: { taxonomyId = '', merchantTypeId = '' },
        setFilters
      }
    } = this.props;

    return (
      <React.Fragment>
        <FormControl className={classes.filter}>
          <InputLabel>Merchant type:</InputLabel>
          <Select
            fullWidth
            className={classes.filterSelect}
            value={merchantTypeId}
            onChange={e => setFilters({ merchantTypeId: e.target.value })}
            disabled={isLoading}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>

            {merchantTypes.map(type => (
              <MenuItem value={type.id} key={type.id}>
                <em>{type.name}</em>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.filter}>
          <InputLabel>Category group:</InputLabel>
          <Select
            fullWidth
            className={classes.filterSelect}
            value={taxonomyId}
            onChange={e => setFilters({ taxonomyId: e.target.value })}
            disabled={isLoading}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>

            {taxonomies.map(taxonomy => (
              <MenuItem value={taxonomy.id} key={taxonomy.id}>
                <em>{taxonomy.title}</em>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </React.Fragment>
    );
  };
  render() {
    const {
      scripts,
      scriptEditor,
      scriptVideoRecorder,
      scriptVideoEditor,
      scriptVideoSettings,
      isLoading,
      rowsPerPage,
      totalScriptsCount,
      page
    } = this.props.ScriptsStore;

    if (scriptEditor.active) {
      return <ScriptForm />;
    }

    if (scriptVideoRecorder.active) {
      return <ScriptVideoRecorder />;
    }

    if (scriptVideoEditor.active) {
      return <ScriptVideoEditor />;
    }

    if (scriptVideoSettings.active) {
      return <VideoSettingsForm tit />;
    }

    return (
      <React.Fragment>
        <Loader
          isBusy={
            this.props.CategoriesStore.isLoading ||
            (isLoading && scripts.length === 0)
          }
        >
          <Table
            data={{
              columns: this.getColumns(),
              rows: scripts,
              emptyDataText: 'There are no messages yet',
              showEmptyRows: true
            }}
            toolbar={{
              title: 'Messages',
              render: this.renderFilters,
              buttons: [
                {
                  title: 'Create message',
                  icon: <Add />,
                  onClick: () => this.props.ScriptsStore.startScriptEditor()
                }
              ]
            }}
            rowToolbar={[
              {
                title: 'Edit message',
                icon: <Edit />,
                onClick: script =>
                  this.props.ScriptsStore.startScriptEditor(script.id)
              },
              {
                title: 'Record sample video',
                icon: <VideoCall />,
                onClick: script =>
                  this.props.ScriptsStore.startScriptVideoRecorder(script.id),
                isVisible: messageTemplate => !!messageTemplate.scriptText
              },
              {
                title: 'Personalize video',
                icon: <Fingerprint />,
                onClick: script =>
                  this.props.ScriptsStore.startScriptVideoEditor(script.id),
                isVisible: messageTemplate =>
                  messageTemplate.scriptText && messageTemplate.exampleVideoUrl
              },
              {
                title: 'Video settings',
                icon: <Settings />,
                onClick: script =>
                  this.props.ScriptsStore.startScriptVideoSettings(script.id),
                isVisible: messageTemplate =>
                  messageTemplate.scriptText && messageTemplate.exampleVideoUrl
              },
              {
                title: 'Delete message',
                icon: <Delete />,
                onClick: script => this.handleDelete(script)
              }
            ]}
            pagination={{
              totalRowsCount: totalScriptsCount,
              currentPage: page,
              handleChangePage: this.handleChangePage,
              handleChangeRowsPerPage: this.handleChangeRowsPerPage,
              rowsPerPage
            }}
          />
        </Loader>
      </React.Fragment>
    );
  }
  getColumns() {
    return [
      {
        title: 'Media',
        type: 'custom',
        key: 'media',
        width: '250px',
        render: messageTemplate => {
          if (!messageTemplate.scriptText) {
            return messageTemplate.imageUrl ? (
              <EditableImage imageUrl={messageTemplate.imageUrl} />
            ) : null;
          }
          return (
            <VideoPreview
              url={
                messageTemplate.exampleVideoUrl
                  ? [
                      `${messageTemplate.exampleVideoUrl}.mp4`,
                      messageTemplate.exampleVideoUrl
                    ]
                  : null
              }
              overlay={messageTemplate.exampleVideoOverlay}
              thumbnailUrl={messageTemplate.exampleVideoThumbnailUrl}
              frameSource={messageTemplate.videoFrameImageSource}
              onClear={() => this.handleVideoClear(messageTemplate)}
            />
          );
        }
      },
      {
        title: 'Title',
        key: 'title',
        width: '300px'
      },
      {
        title: 'Category',
        type: 'custom',
        key: 'categories',
        width: '500px',
        render: messageTemplate => {
          const categories = Taxonomy.getLinkedCategories(messageTemplate.id);
          const stringPresentation = categories
            .map(({ path }) => path.join('>'))
            .join(', ');
          return (
            <p
              title={stringPresentation}
              className={this.props.classes.categories}
            >
              {stringPresentation}
            </p>
          );
        }
      },
      /*{
        title: 'Created by',
        key: 'createdBy'
      },*/
      {
        title: 'Created at',
        key: 'createdAt',
        type: 'date-time',
        width: '200px'
      }
    ];
  }
}
export default withStyles(styles)(
  inject(
    'ScriptsStore',
    'CategoriesStore',
    'MerchantTypesStore',
    'CommonStore'
  )(observer(Scripts))
);
