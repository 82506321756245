import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import Table from 'Components/Table/Table';
import { Add, Edit, Delete } from '@material-ui/icons';
import { MERCHANT_TYPE_LEVEL } from 'Stores/PricingStore';

const getColumns = () => [
  {
    title: 'Level',
    key: 'levelName',
  },
  {
    title: 'Message price, $',
    key: 'messagePrice',
  },
  {
    title: 'Minimum balance, $',
    key: 'minimumBalance',
  },
  {
    title: 'Full balance, $',
    key: 'fullBalance',
  },
];

class GlobalSettings extends React.Component {
  static propTypes = {
    PricingStore: PropTypes.observableObject,
    CommonStore: PropTypes.observableObject,
  };
  handleDelete = ({ levelId }) => {
    this.props.CommonStore.confirmAction('Delete user type settings?', () => {
      const { PricingStore } = this.props;
      PricingStore.deleteLevelSettings(MERCHANT_TYPE_LEVEL, levelId);
    });
  };
  render() {
    const { merchantTypeSettings, startEditor } = this.props.PricingStore;

    return (
      <Table
        toolbar={{
          title: 'User type level settings',
          buttons: [
            {
              title: 'Add',
              icon: <Add />,
              onClick: () => startEditor(MERCHANT_TYPE_LEVEL),
            },
          ],
        }}
        data={{
          columns: getColumns(),
          rows: merchantTypeSettings,
          keyAttribute: 'levelId',
          showEmptyRows: true,
        }}
        rowToolbar={[
          {
            title: 'Edit',
            icon: <Edit />,
            onClick: ({ levelId }) => startEditor(MERCHANT_TYPE_LEVEL, levelId),
          },
          {
            title: 'Delete',
            icon: <Delete />,
            onClick: this.handleDelete,
          },
        ]}
      />
    );
  }
}

export default inject('PricingStore', 'CommonStore')(observer(GlobalSettings));
