import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import Table from 'Components/Table/Table';
import Grid from '@material-ui/core/Grid';
import MerchantPicker from 'Components/MerchantPicker';
import Progress from './Progress';
import MerchantTypeSelector from './MerchantTypeSelector';
import IndividualMessageReport from './IndividualMessageReport';
import VideoPreview from '../Video/Preview';

function MessagesReport({ AnalyticStore }) {
  const {
    isLoadingMessagesReport,
    messagesReport: { data, total },
    messagesReportFilters: { merchant, merchantType },
    messagesReportPagination: { page, pageSize },
    changeMessagesReportFilters,
    changeMessagesReportPageSettings,
    dateRange: [startDate, endDate],
  } = AnalyticStore;
  if (isLoadingMessagesReport) {
    return <Progress />;
  }

  return (
    <Table
      noWrap
      minWidth={1400}
      toolbar={{
        title: 'Messages',
        render: () => {
          return (
            <div style={{ width: '100%', paddingLeft: 40, textAlign: 'left' }}>
              <Grid container alignContent="center" spacing={8}>
                <Grid item xs={6}>
                  <MerchantPicker
                    value={merchant}
                    onChange={(merchant) => {
                      changeMessagesReportFilters(merchant, merchantType);
                    }}
                    disabled={isLoadingMessagesReport}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MerchantTypeSelector
                    value={merchantType}
                    onChange={(merchantType) =>
                      changeMessagesReportFilters(merchant, merchantType)
                    }
                    disabled={isLoadingMessagesReport}
                  />
                </Grid>
              </Grid>
            </div>
          );
        },
      }}
      data={{
        columns: [
          {
            title: 'Preview',
            type: 'custom',
            key: 'messageId',
            width: 150,
            render: (message) => {
              if (message.messageType === 'text') {
                return (
                  <img
                    src={message.textMessageImage}
                    alt=""
                    style={{
                      width: 120,
                      display: 'block',
                      margin: '0 auto',
                    }}
                  />
                );
              }
              return (
                <VideoPreview
                  url={[`${message.videoUrl}.mp4`, message.videoUrl]}
                  overlay={message.videoOverlay}
                  thumbnailUrl={message.videoThumbnailUrl}
                  frameSource={message.videoFrameImageSource}
                />
              );
            },
          },
          {
            title: 'User',
            key: 'merchantName',
            width: 250,
          },
          {
            title: 'Company',
            key: 'officeName',
            width: 200,
          },
          {
            title: 'Title',
            key: 'title',
            width: 250,
          },
          {
            title: 'Type',
            key: 'messageType',
            width: 100,
          },
          {
            title: 'Started',
            key: 'startedAt',
            type: 'date-time',
            width: 100,
          },
          {
            title: 'Ended',
            key: 'endedAt',
            type: 'date-time',
            width: 100,
          },
          {
            title: 'Delivered',
            key: 'deliveredMessagesCount',
            width: 100,
          },
          {
            title: 'Failed',
            key: 'failedMessagesCount',
            width: 100,
          },
          {
            title: 'Rate',
            key: 'deliveryRate',
            type: 'custom',
            render: ({ deliveryRate }) => {
              return `${deliveryRate}%`;
            },
            width: 100,
          },
        ],
        rows: data.map((row) => {
          const { deliveredMessagesCount, failedMessagesCount } = row;
          const deliveryRate = (
            (deliveredMessagesCount * 100) /
            (deliveredMessagesCount + failedMessagesCount)
          ).toFixed(0);
          return {
            ...row,
            deliveryRate,
          };
        }),
        keyAttribute: 'messageId',
        showEmptyRows: true,
      }}
      pagination={{
        totalRowsCount: total,
        currentPage: page,
        handleChangePage: (e, newPage) =>
          changeMessagesReportPageSettings(newPage, pageSize),
        handleChangeRowsPerPage: (e) =>
          changeMessagesReportPageSettings(page, e.target.value),
        rowsPerPage: pageSize,
      }}
      renderExpandedRow={({ messageId }) => {
        return (
          <IndividualMessageReport
            messageId={messageId}
            startDate={startDate}
            endDate={endDate}
          />
        );
      }}
    />
  );
}

MessagesReport.propTypes = {
  AnalyticStore: PropTypes.observableObject.isRequired,
};

export default inject('AnalyticStore')(observer(MessagesReport));
