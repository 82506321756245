import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 4
  }
});

export default withStyles(styles)(function Progress({ classes }) {
  return (
    <div className={classes.root}>
      <LinearProgress />
    </div>
  );
});
