function stringKeyToList(key) {
  return (process.env[key] || '')
    .split(',')
    .map((item) => item.trim())
    .filter((val) => !!val);
}

export const BRAND = process.env.REACT_APP_BRAND_NAME || 'digitalcare100';

export const MENU = stringKeyToList('REACT_APP_MENU');

export const DASHBOARD_COUNTERS = stringKeyToList(
  'REACT_APP_DASHBOARD_COUNTERS'
);

export const DASHBOARD_REPORTS = stringKeyToList('REACT_APP_DASHBOARD_REPORTS');
