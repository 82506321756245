import React from 'react';
import ReactPropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { inject, observer, PropTypes } from 'mobx-react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { BRAND } from 'Utils/whitelabeling';
import { menuItems } from './listItems';
import Confirmation from 'Components/Dialog/Confirmation';
import SnackbarContent from 'Components/Snakbar';
import { Link } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
  },
  snackbar: {
    margin: theme.spacing.unit,
  },
  logo: {
    width: 170,
    marginLeft: 5,
    display: 'flex',
  },
});

class Layout extends React.Component {
  state = {
    open: false,
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleLogout = () => this.props.AuthStore.logout();

  dismissErrorMessage = () => this.props.CommonStore.dismissErrorMessage();

  render() {
    const { classes } = this.props;
    const { CommonStore } = this.props;
    const { title } = CommonStore;

    return (
      <React.Fragment>
        <CssBaseline />
        {CommonStore.errorMessage && (
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={true}
            autoHideDuration={6000}
            onClose={this.dismissErrorMessage}
          >
            <SnackbarContent
              variant="error"
              className={classes.snackbar}
              message={CommonStore.errorMessage}
              onClose={this.dismissErrorMessage}
            />
          </Snackbar>
        )}
        <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
        </Helmet>
        {CommonStore.confirmation.active && (
          <Confirmation
            description={CommonStore.confirmation.description}
            handleAgree={() => CommonStore.confirmation.action()}
            handleDisagree={() => CommonStore.closeConfirmationDialog()}
          />
        )}
        <div className={classes.root}>
          <AppBar
            position="absolute"
            className={classNames(
              classes.appBar,
              this.state.open && classes.appBarShift
            )}
          >
            <Toolbar
              disableGutters={!this.state.open}
              className={classes.toolbar}
            >
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  this.state.open && classes.menuButtonHidden
                )}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                {title}
              </Typography>
              <Button color="inherit" onClick={this.handleLogout}>
                Logout
              </Button>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(
                classes.drawerPaper,
                !this.state.open && classes.drawerPaperClose
              ),
            }}
            open={this.state.open}
          >
            <div className={classes.toolbarIcon}>
              <img src="/logo.png" className={classes.logo} alt={BRAND} />
              <IconButton onClick={this.handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <List>
              {menuItems().map(({ id, label, icon, link }) => (
                <ListItem key={id} button component={Link} to={link}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={label} />
                </ListItem>
              ))}
            </List>
            <Divider />
          </Drawer>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            {this.props.children}
          </main>
        </div>
      </React.Fragment>
    );
  }
}

Layout.propTypes = {
  AuthStore: PropTypes.observableObject,
  CommonStore: PropTypes.observableObject,
  classes: ReactPropTypes.object.isRequired,
  children: ReactPropTypes.any,
};

const ObserverLayout = inject('AuthStore', 'CommonStore')(observer(Layout));

const StyledObserverLayout = withStyles(styles)(ObserverLayout);

export const wrap = (Component) => {
  return () => (
    <StyledObserverLayout>
      <Component />
    </StyledObserverLayout>
  );
};
