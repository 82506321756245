import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import TextField from '@material-ui/core/TextField';
import FormDialog from 'Components/Dialog/FormDialog';

class CategoryForm extends React.Component {
  static propTypes = {
    CategoriesStore: PropTypes.observableObject
  };
  handleFormSubmit = e => {
    e.preventDefault();
    this.submit();
  };
  submit = () => {
    this.props.CategoriesStore.submitCategoryChanges();
  };
  cancel = () => {
    this.props.CategoriesStore.closeCategoryEditor();
  };
  makeChangeHandler = name => {
    return e => {
      this.props.CategoriesStore.registerCategoryChange(name, e.target.value);
    };
  };
  render() {
    const {
      scriptId,
      isBusy,
      values,
      errors = {}
    } = this.props.CategoriesStore.categoryEditor;
    const title = scriptId ? 'Update category' : 'Create new category';
    return (
      <FormDialog
        title={title}
        isBusy={isBusy}
        handleCancel={this.cancel}
        handleSubmit={this.submit}
      >
        <form onSubmit={this.handleFormSubmit}>
          <TextField
            label="Title"
            autoFocus={true}
            placeholder="Category title"
            value={values.title || ''}
            error={!!errors.title}
            helperText={errors.title}
            fullWidth
            margin="normal"
            onChange={this.makeChangeHandler('title')}
          />
          <TextField
            label="Description"
            autoFocus={true}
            placeholder="Description text"
            value={values.description || ''}
            error={!!errors.description}
            helperText={errors.description}
            fullWidth
            multiline
            margin="normal"
            onChange={this.makeChangeHandler('description')}
          />
        </form>
      </FormDialog>
    );
  }
}

export default inject('CategoriesStore')(observer(CategoryForm));
