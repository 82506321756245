import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography/Typography';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing.unit / 2
  },
  chip: {
    margin: theme.spacing.unit / 2
  },
  title: {
    margin: theme.spacing.unit / 2,
    marginRight: 'auto'
  }
});

class ChipsArray extends React.Component {
  render() {
    const { classes, title } = this.props;

    return (
      <Paper className={classes.root}>
        <Typography variant="h5" component="h3" className={classes.title}>
          {title}
        </Typography>
        {this.props.items.map(data => {
          return (
            <Chip
              key={data.id}
              label={data.title}
              onDelete={() => this.props.onDelete(data.id)}
              className={classes.chip}
            />
          );
        })}
      </Paper>
    );
  }
}

ChipsArray.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired
    })
  ).isRequired,
  onDelete: PropTypes.func
};

export default withStyles(styles)(ChipsArray);
