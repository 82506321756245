import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import Table from 'Components/Table/Table';
import Progress from './Progress';

class CarrierStats extends React.Component {
  state = { currentPage: 0, pageSize: 10 };
  render() {
    const { isLoadingCarrierStats, carrierStats } = this.props.AnalyticStore;
    if (isLoadingCarrierStats) {
      return <Progress />;
    }

    const { pageSize, currentPage } = this.state;
    return (
      <Table
        noWrap
        toolbar={{
          title: 'Carriers'
        }}
        data={{
          columns: [
            {
              title: 'Carrier',
              key: 'carrier'
            },
            {
              title: 'Delivered messages',
              key: 'deliveredMessagesCount'
            },
            {
              title: 'Failed messages',
              key: 'failedMessagesCount'
            },
            {
              title: 'Delivery rate',
              key: 'deliveryRate'
            }
          ],
          rows: carrierStats
            .slice(currentPage * pageSize, currentPage * pageSize + pageSize)
            .map(row => {
              const { deliveredMessagesCount, failedMessagesCount } = row;
              const deliveryRate = (
                deliveredMessagesCount /
                (deliveredMessagesCount + failedMessagesCount)
              ).toFixed(2);
              return { ...row, deliveryRate };
            }),
          keyAttribute: 'carrier',
          showEmptyRows: true
        }}
        pagination={{
          totalRowsCount: carrierStats.length,
          currentPage,
          handleChangePage: (e, currentPage) => this.setState({ currentPage }),
          handleChangeRowsPerPage: e =>
            this.setState({ pageSize: e.target.value }),
          rowsPerPage: pageSize
        }}
      />
    );
  }
}

CarrierStats.propTypes = {
  AnalyticStore: PropTypes.observableObject.isRequired
};

export default inject('AnalyticStore')(observer(CarrierStats));
