import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import agent from 'agent';
import { debounce } from 'Utils/Decorators';

class MerchantPicker extends React.Component {
  state = { inputValue: '' };
  handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue });
    return inputValue;
  };
  loadOptions = debounce(async () => {
    const { inputValue } = this.state;
    const { data: merchants } = await agent.Merchants.search(inputValue, 0, 10);
    return [{ value: '', label: 'All' }]
      .concat(this.props.customOptions || [])
      .concat(
        merchants.map(({ id, name }) => ({
          value: id,
          label: name || 'Unknown',
        }))
      );
  }, 500);
  render() {
    const { onChange, value, disabled } = this.props;
    return (
      <AsyncSelect
        placeholder="Select user"
        cacheOptions
        loadOptions={this.loadOptions}
        defaultOptions
        onChange={onChange}
        value={value}
        isDisabled={disabled}
        isClearable
        onInputChange={this.handleInputChange}
      />
    );
  }
}

MerchantPicker.propTypes = {
  customOptions: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  disabled: PropTypes.bool,
};

export default MerchantPicker;
