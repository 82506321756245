import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import ReactPropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

class PrivateRoute extends React.Component {
  static propTypes = {
    CommonStore: PropTypes.observableObject.isRequired,
    component: ReactPropTypes.func.isRequired
  };

  render() {
    const { CommonStore, component: Component, ...rest } = this.props;
    const { token } = CommonStore;

    return (
      <Route
        {...rest}
        render={props =>
          token ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }}
            />
          )
        }
      />
    );
  }
}

export default inject('CommonStore')(observer(PrivateRoute));
