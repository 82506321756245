import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import { Route } from 'react-router-dom';
import queryString from 'query-string';

class MagicLink extends React.Component {
  static propTypes = {
    CommonStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject
  };

  handleMagicLink = () => {
    const { CommonStore, Routing } = this.props;
    const { search } = Routing.history.location;

    const query = queryString.parse(search);
    const { jwt, to } = query;
    if (jwt) {
      CommonStore.setToken(jwt);
      delete query['jwt'];
      delete query['to'];

      queryString.stringify(query);
      setTimeout(() => Routing.push(`${to}?${queryString.stringify(query)}`));
    }
    return null;
  };

  render() {
    return <Route path="/magic-link" render={this.handleMagicLink} />;
  }
}

export default inject('Routing', 'CommonStore')(observer(MagicLink));
