import { action, decorate, flow, observable, reaction, computed } from 'mobx';
import moment from 'moment';
import agent from '../agent';
import CommonStore from './CommonStore';

export const TODAY = 'today';
export const LAST_WEEK = 'lastWeek';
export const LAST_MONTH = 'lastMonth';
export const LAST_YEAR = 'lastYear';

const DATE_FORMAT = 'MM/DD/Y';

function makeDateRange(interval) {
  if (Array.isArray(interval)) {
    return interval;
  }
  const endDate = moment();
  let startDate;
  switch (interval) {
    case TODAY:
    default:
      startDate = moment().subtract(1, 'days');
      break;
    case LAST_WEEK:
      startDate = moment().subtract(1, 'weeks');
      break;
    case LAST_MONTH:
      startDate = moment().subtract(1, 'months');
      break;
    case LAST_YEAR:
      startDate = moment().subtract(1, 'years');
      break;
  }

  return [startDate.format(DATE_FORMAT), endDate.format(DATE_FORMAT)];
}

function makePagination({ page, pageSize }) {
  return [page * pageSize, pageSize];
}

class AnalyticStore {
  interval = LAST_MONTH;

  isLoadingGeneralStats = false;
  generalStats = [];

  isLoadingCarrierStats = false;
  carrierStats = [];

  isLoadingMessagesReport = false;
  messagesReportFilters = {};
  messagesReportPagination = { page: 0, pageSize: 10 };
  messagesReport = { data: [], total: 0 };

  isLoadingVideosReport = false;
  videosReportFilters = {};
  videosReportPagination = { page: 0, pageSize: 10 };
  videosReport = { data: [], total: 0 };

  constructor() {
    reaction(
      () => this.interval,
      () => this.load(),
      { delay: 100 }
    );
    reaction(
      () => this.messagesReportFilters,
      () => this.loadMessagesReport(),
      { delay: 100 }
    );
    reaction(
      () => this.messagesReportPagination,
      () => this.loadMessagesReport(),
      { delay: 100 }
    );
    reaction(
      () => this.videosReportFilters,
      () => this.loadVideosReport(),
      { delay: 100 }
    );
    reaction(
      () => this.videosReportPagination,
      () => this.loadVideosReport(),
      { delay: 100 }
    );
    this.load();
  }

  load = () => {
    this.messagesReportPagination.page = 0;
    this.loadGeneralStats();
    this.loadCarrierStats();
    this.loadMessagesReport();
    this.loadVideosReport();
  };

  setInterval = interval => {
    if (this.isBusy) {
      return;
    }
    if (this.interval !== interval) {
      this.interval = interval;
    }
  };

  loadGeneralStats = flow(function*() {
    if (this.isLoadingGeneralStats) {
      return;
    }
    this.isLoadingGeneralStats = true;
    try {
      this.generalStats = yield agent.Analytic.performance(
        ...makeDateRange(this.interval)
      );
    } catch (e) {
      CommonStore.handleError(e);
    }
    this.isLoadingGeneralStats = false;
  });

  loadCarrierStats = flow(function*() {
    if (this.isLoadingCarrierStats) {
      return;
    }
    this.isLoadingCarrierStats = true;
    try {
      this.carrierStats = yield agent.Analytic.carrier(
        ...makeDateRange(this.interval)
      );
    } catch (e) {
      CommonStore.handleError(e);
    }
    this.isLoadingCarrierStats = false;
  });

  loadMessagesReport = flow(function*() {
    if (this.isLoadingMessagesReport) {
      return;
    }
    this.isLoadingMessagesReport = true;
    const { merchant, merchantType } = this.messagesReportFilters;
    try {
      this.messagesReport = yield agent.Analytic.messages(
        merchant && merchant.value,
        merchantType && merchantType.value,
        ...makeDateRange(this.interval),
        ...makePagination(this.messagesReportPagination)
      );
    } catch (e) {
      CommonStore.handleError(e);
    }
    this.isLoadingMessagesReport = false;
  });

  changeMessagesReportFilters = (merchant, merchantType) => {
    this.messagesReportFilters = { merchant, merchantType };
  };

  changeMessagesReportPageSettings = (page, pageSize) => {
    this.messagesReportPagination = { page, pageSize };
  };

  loadVideosReport = flow(function*() {
    if (this.isLoadingVideosReport) {
      return;
    }
    this.isLoadingVideosReport = true;
    const { merchant, merchantType } = this.videosReportFilters;
    try {
      this.videosReport = yield agent.Analytic.videos(
        merchant && merchant.value,
        merchantType && merchantType.value,
        ...makeDateRange(this.interval),
        ...makePagination(this.videosReportPagination)
      );
    } catch (e) {
      CommonStore.handleError(e);
    }
    this.isLoadingVideosReport = false;
  });

  changeVideosReportFilters = (merchant, merchantType) => {
    this.videosReportFilters = { merchant, merchantType };
  };

  changeVideosReportPageSettings = (page, pageSize) => {
    this.videosReportPagination = { page, pageSize };
  };

  get isBusy() {
    return (
      this.isLoadingGeneralStats ||
      this.isLoadingCarrierStats ||
      this.isLoadingMessagesReport ||
      this.isLoadingVideosReport
    );
  }

  get dateRange() {
    return makeDateRange(this.interval);
  }
}

const MobxAnalyticStore = decorate(AnalyticStore, {
  interval: observable,
  isLoadingGeneralStats: observable,
  generalStats: observable,
  isLoadingCarrierStats: observable,
  carrierStats: observable,
  isLoadingMessagesReport: observable,
  messagesReport: observable,
  messagesReportFilters: observable,
  messagesReportPagination: observable,
  isLoadingVideosReport: observable,
  videosReport: observable,
  videosReportFilters: observable,
  videosReportPagination: observable,

  setInterval: action,
  loadGeneralStats: action,
  loadCarrierStats: action,
  changeMessagesReportFilters: action,
  changeMessagesReportPageSettings: action,
  changeVideosReportFilters: action,
  changeVideosReportPageSettings: action,

  isBusy: computed,
  dateRange: computed
});

export default new MobxAnalyticStore();
