import React from 'react';
import PropTypes from 'prop-types';
import LinearIndeterminate from './LinearIndeterminate';
import Fade from '@material-ui/core/Fade/Fade';

function loader(props) {
  const { isBusy, children } = props;
  return (
    <React.Fragment>
      <Fade
        in={isBusy}
        style={{
          transitionDelay: isBusy ? '800ms' : '0ms'
        }}
        unmountOnExit
      >
        <LinearIndeterminate />
      </Fade>
      {!isBusy && children}
    </React.Fragment>
  );
}

loader.propTypes = {
  isBusy: PropTypes.bool,
  children: PropTypes.node
};

export default loader;
