import { observable, action, flow, decorate } from 'mobx';

import agent from 'agent';
import CommonStore from 'Stores/CommonStore';
class AuthStore {
  isBusy = false;
  errorMessage = null;

  values = {
    login: '',
    password: ''
  };

  setLogin(login) {
    this.values.login = login;
  }

  setPassword(password) {
    this.values.password = password;
  }

  login = flow(function*() {
    this.isBusy = true;
    try {
      const { jwt } = yield agent.Auth.login(
        this.values.login,
        this.values.password
      );
      CommonStore.setToken(jwt);
    } catch (e) {
      this.handleErrors(e);
    }
    this.isBusy = false;
  });

  logout() {
    CommonStore.setToken(undefined);
  }

  handleErrors(err) {
    const { message = undefined } = err.response.body;
    this.errorMessage = message;
  }
}

const MobxAuthStore = decorate(AuthStore, {
  isBusy: observable,
  errorMessage: observable,
  values: observable,
  setLogin: action,
  setPassword: action,
  login: action
});

export default new MobxAuthStore();
