import React from 'react';
import ReactPropTypes from 'prop-types';
import { inject, observer, PropTypes } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Progress from './Progress';
import { DASHBOARD_COUNTERS } from 'Utils/whitelabeling';

const COUNTERS = [
  {
    id: 'active-merchants',
    title: 'Active users',
    counter: 'activeMerchantsCount',
  },
  {
    id: 'video-messages',
    title: 'Video messages',
    counter: 'videoMessagesCount',
  },
  {
    id: 'text-messages',
    title: 'Text messages',
    counter: 'textMessagesCount',
  },
  {
    id: 'delivered-messages',
    title: 'Delivered messages',
    counter: 'deliveredMessagesCount',
  },
  {
    id: 'failed-messages',
    title: 'Failed messages',
    counter: 'failedMessagesCount',
  },
].filter((counter) => DASHBOARD_COUNTERS.includes(counter.id));

const cardStyles = () => ({
  root: {
    //minWidth: 275,
    width: 250,
    textAlign: 'center',
  },
  value: {
    fontSize: 40,
  },
});

const rootStyles = () => ({
  root: {
    marginTop: 24,
  },
});

const StatCard = withStyles(cardStyles)(function StatCard({
  classes,
  title,
  value,
}) {
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h5" component="h2">
          {title}
        </Typography>
        <Typography className={classes.value} color="textSecondary">
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
});

function GeneralStats({ classes, AnalyticStore }) {
  if (COUNTERS.length === 0) {
    return null;
  }

  const { isLoadingGeneralStats, generalStats } = AnalyticStore;
  if (isLoadingGeneralStats) {
    return <Progress />;
  }
  return (
    <Grid className={classes.root} container spacing={40} justify="center">
      {COUNTERS.map(({ id, title, counter }) => (
        <Grid key={id} item>
          <StatCard title={title} value={generalStats[counter]} />
        </Grid>
      ))}
    </Grid>
  );
}

GeneralStats.propTypes = {
  AnalyticStore: PropTypes.observableObject.isRequired,
  classes: ReactPropTypes.object.isRequired,
};

export default withStyles(rootStyles)(
  inject('AnalyticStore')(observer(GeneralStats))
);
