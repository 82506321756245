import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import ReactPropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import Loader from 'Components/Progress/Loader';
import { Add, Edit, Delete } from '@material-ui/icons';
import CategoryForm from './CategoryForm';
import ScriptsManager from './ScriptsManager';
import TaxonomyForm from './TaxonomyForm';
import List from '@material-ui/core/List/List';
import Category from './Category';
import Button from '@material-ui/core/Button/Button';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Typography from '@material-ui/core/Typography/Typography';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import { sortCategories } from './utils';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const styles = theme => ({
  toolbar: {
    padding: theme.spacing.unit * 2
  },
  spacer: {
    flex: '1 1 auto'
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: '0 0 auto'
  },
  button: {
    margin: theme.spacing.unit
  }
});

class Categories extends React.Component {
  static propTypes = {
    classes: ReactPropTypes.object.isRequired,
    CategoriesStore: PropTypes.observableObject,
    MerchantTypesStore: PropTypes.observableObject,
    CommonStore: PropTypes.observableObject
  };
  componentWillMount() {
    const { CommonStore, CategoriesStore, MerchantTypesStore } = this.props;
    CommonStore.setTitle('Script categories');
    CategoriesStore.reset();
    CategoriesStore.loadTaxonomies();
    CategoriesStore.loadTree();
    MerchantTypesStore.reset();
    MerchantTypesStore.load();
  }
  startCategoryEditor = (path, addCategory) =>
    this.props.CategoriesStore.startCategoryEditor(path, addCategory);
  handleDelete = path => {
    this.props.CommonStore.confirmAction('Delete category?', () => {
      this.props.CategoriesStore.deleteCategory(path);
    });
  };
  startScriptManager = path => {
    this.props.CategoriesStore.startScriptsManager(path);
  };
  onImageChange = (path, file) => {
    this.props.CategoriesStore.addCategoryImage(path, file);
  };
  onImageDelete = path => {
    this.props.CommonStore.confirmAction('Delete category image?', () => {
      this.props.CategoriesStore.deleteCategoryImage(path);
    });
  };
  render() {
    const { classes } = this.props;
    const {
      CategoriesStore: {
        isLoading,
        isLoadingTaxonomies,
        tree,
        taxonomies,
        categoryEditor,
        scriptsManager,
        taxonomyForm,
        taxonomyId,
        selectTaxonomy,
        editTaxonomy,
        deleteTaxonomy
      },
      MerchantTypesStore: { isLoading: isLoadingMerchantTypes, merchantTypes }
    } = this.props;
    if (categoryEditor.active) {
      return <CategoryForm />;
    }
    if (scriptsManager.path) {
      return <ScriptsManager />;
    }
    if (taxonomyForm.active) {
      return <TaxonomyForm />;
    }

    const linkedMerchantTypes = merchantTypes.filter(
      type => type.taxonomyId === taxonomyId
    );

    return (
      <Loader
        isBusy={
          isLoadingTaxonomies || isLoadingMerchantTypes || (isLoading && !tree)
        }
      >
        <Paper>
          <form style={{ padding: 20 }}>
            <FormControl fullWidth>
              <InputLabel>Category group</InputLabel>
              <Select
                fullWidth
                placeholder="Select category group"
                value={taxonomyId}
                onChange={event => selectTaxonomy(event.target.value)}
              >
                <MenuItem value="add">
                  <em>+ Create new</em>
                </MenuItem>
                <MenuItem value="clone">
                  <em>+ Clone selected</em>
                </MenuItem>
                {taxonomies.map(({ id, title }) => (
                  <MenuItem value={id} key={id}>
                    <em>{title}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
          <section style={{ padding: 20 }}>
            <h3>Available for next merchant types:</h3>
            <ul>
              {linkedMerchantTypes.map(({ id, name }) => (
                <li key={id}>{name}</li>
              ))}
            </ul>
          </section>
          <Toolbar className={classes.toolbar}>
            <div className={classes.title}>
              <Typography variant="h6">Categories</Typography>
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
              <Tooltip
                title="Rename category group"
                style={{ marginRight: 10 }}
              >
                <Button
                  variant="fab"
                  color="primary"
                  aria-label="Rename category group"
                  onClick={editTaxonomy}
                >
                  <Edit />
                </Button>
              </Tooltip>
              {linkedMerchantTypes.length === 0 && (
                <Tooltip
                  title="Delete category group"
                  style={{ marginRight: 10 }}
                >
                  <Button
                    variant="fab"
                    color="primary"
                    aria-label="Delete category group"
                    onClick={deleteTaxonomy}
                  >
                    <Delete />
                  </Button>
                </Tooltip>
              )}
              <Tooltip title="Add">
                <Button
                  variant="fab"
                  color="primary"
                  aria-label="Add"
                  onClick={() => this.startCategoryEditor(null, true)}
                >
                  <Add />
                </Button>
              </Tooltip>
            </div>
          </Toolbar>
          <List>
            {sortCategories(tree ? Object.values(tree.categories) : []).map(
              category => (
                <Category
                  key={category.title}
                  category={category}
                  parentPath=""
                  onDelete={this.handleDelete}
                  onEdit={this.startCategoryEditor}
                  onManageScripts={this.startScriptManager}
                  onImageChange={this.onImageChange}
                  onImageDelete={this.onImageDelete}
                />
              )
            )}
          </List>
        </Paper>
      </Loader>
    );
  }
}
export default inject(
  'CategoriesStore',
  'MerchantTypesStore',
  'CommonStore'
)(withStyles(styles)(observer(Categories)));
