import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import Loader from 'Components/Progress/Loader';
import GlobalSettings from './GlobalSettings';
import MerchantTypeSettings from './MerchantTypeSettings';
import MerchantSettings from './MerchantSettings';
import LevelPricingForm from './LevelPricingForm';

class Pricing extends React.Component {
  static propTypes = {
    PricingStore: PropTypes.observableObject,
    CommonStore: PropTypes.observableObject
  };
  componentWillMount() {
    this.props.CommonStore.setTitle('Pricing');
    this.props.PricingStore.loadPricing();
  }
  render() {
    const { editor, isLoading } = this.props.PricingStore;

    if (editor) {
      return <LevelPricingForm />;
    }

    return (
      <Loader isBusy={isLoading}>
        <GlobalSettings />
        <MerchantTypeSettings />
        <MerchantSettings />
      </Loader>
    );
  }
}

export default inject('PricingStore', 'CommonStore')(observer(Pricing));
