import React from 'react';
import agent from 'agent';
import { Recorder as RecorderComponent } from 'react-cam-recorder';
import 'react-cam-recorder/css/index.css';
import './index.css';

const framesSource = async (offset, limit) => {
  const { data } = await agent.VideoFrame.list(offset, limit);
  return data.map(({ id, title, imageSource }) => ({
    id,
    url: imageSource,
    title,
  }));
};

class Recorder extends React.Component {
  state = { hasFrames: null, selectedFrame: null };
  detectHasFrames = async () => {
    const { count } = await agent.VideoFrame.list(0, 0);
    this.setState({ hasFrames: count > 0 });
  };
  componentDidMount() {
    this.detectHasFrames();
  }

  onVideoReady = (video) => {
    const { onVideoReady } = this.props;
    const { selectedFrame } = this.state;
    return onVideoReady(video, selectedFrame);
  };

  render() {
    // eslint-disable-next-line
    const { onVideoReady, ...props } = this.props;
    const { hasFrames } = this.state;

    if (hasFrames === null) {
      return null;
    }

    return (
      <RecorderComponent
        {...{
          ...props,
          onVideoReady: this.onVideoReady,
          framesSource: hasFrames ? framesSource : null,
          onFrameChange: (frame) => this.setState({ selectedFrame: frame }),
          assistScriptGeneration: (instruction, history = []) =>
            agent.Scripts.generate(instruction, history),
        }}
      />
    );
  }
}

export default Recorder;
