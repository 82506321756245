import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import Dashboard from 'Components/Dashboard';
import { BRAND } from 'Utils/whitelabeling';

class Categories extends React.Component {
  static propTypes = {
    CommonStore: PropTypes.observableObject
  };
  componentDidMount() {
    this.props.CommonStore.setTitle(BRAND);
  }
  render() {
    return <Dashboard />;
  }
}
export default inject('CommonStore')(observer(Categories));
