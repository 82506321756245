import React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CategoryIcon from '@material-ui/icons/Category';
import TheatersIcon from '@material-ui/icons/Theaters';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import PhotoLibrary from '@material-ui/icons/PhotoLibrary';
import People from '@material-ui/icons/People';
import BillingPlan from '@material-ui/icons/AccountBalanceWallet';
import PricingIcon from '@material-ui/icons/MonetizationOn';
import FramesIcon from '@material-ui/icons/FilterFrames';
import ErrorIcon from '@material-ui/icons/Error';
import { MENU } from 'Utils/whitelabeling';

const MENU_ITEMS = [
  {
    id: 'dashboard',
    label: 'Dashboard',
    icon: <DashboardIcon />,
    link: '/',
  },
  {
    id: 'delivery-failures',
    label: 'Delivery failures',
    icon: <ErrorIcon />,
    link: '/delivery-failures',
  },
  {
    id: 'library',
    label: 'Library',
    icon: <TheatersIcon />,
    link: '/library',
  },
  {
    id: 'videos',
    label: 'Videos',
    icon: <VideoLibraryIcon />,
    link: '/videos',
  },
  {
    id: 'categories',
    label: 'Categories',
    icon: <CategoryIcon />,
    link: '/categories',
  },
  {
    id: 'graphic-assets',
    label: 'Graphic assets',
    icon: <PhotoLibrary />,
    link: '/graphic-assets',
  },
  {
    id: 'video-frames',
    label: 'Video frames',
    icon: <FramesIcon />,
    link: '/video-frames',
  },
  {
    id: 'merchants',
    label: 'Users',
    icon: <People />,
    link: '/merchants',
  },
  {
    id: 'merchant-types',
    label: 'User types',
    icon: <People />,
    link: '/merchant-types',
  },
  {
    id: 'billing-plans',
    label: 'Billing plans',
    icon: <BillingPlan />,
    link: '/billing-plans',
  },
  {
    id: 'pricing',
    label: 'Pricing',
    icon: <PricingIcon />,
    link: '/pricing',
  },
];

export const menuItems = () =>
  MENU.length > 0
    ? MENU_ITEMS.filter((menuItem) => MENU.includes(menuItem.id))
    : MENU_ITEMS;
