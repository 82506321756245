import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import Select from '@material-ui/core/Select';
import FormDialog from 'Components/Dialog/FormDialog';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Spacer from 'Components/Misc/Spacer';
import ImagesList from './ImagesList';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';

class MerchantDetailsForm extends React.Component {
  static propTypes = {
    GraphicAssetsStore: PropTypes.observableObject
  };
  handleFormSubmit = e => {
    e.preventDefault();
    this.submit();
  };
  submit = () => {};
  cancel = () => {
    this.props.GraphicAssetsStore.closeAssetsUploader();
  };
  fileSelectionHandler = event => {
    this.props.GraphicAssetsStore.addImageFiles([...event.target.files]);
    event.target.value = null;
  };
  render() {
    const {
      messageTemplateId,
      isBusy,
      images
    } = this.props.GraphicAssetsStore.assetUploader;
    const { templates } = this.props.GraphicAssetsStore.messageTemplates;
    const messageTemplates = Object.values(templates);

    return (
      <FormDialog
        title="Upload images"
        isBusy={isBusy}
        handleCancel={this.cancel}
      >
        <form onSubmit={this.handleFormSubmit}>
          <Spacer />
          <FormControl fullWidth>
            <InputLabel htmlFor="message-template">
              Link uploaded assets to message
            </InputLabel>
            <Select
              placeholder="Select message"
              value={messageTemplateId || ''}
              onChange={e =>
                this.props.GraphicAssetsStore.selectMessageTemplate(
                  e.target.value
                )
              }
              fullWidth
              inputProps={{
                id: 'message-template'
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {messageTemplates.map(template => (
                <MenuItem key={template.id} value={template.id}>
                  {template.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Spacer />
          <label>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              multiple
              type="file"
              disabled={isBusy}
              onChange={this.fileSelectionHandler}
            />
            <Button
              component="span"
              variant="contained"
              color="primary"
              fullWidth
            >
              Select images
            </Button>
          </label>
          <Spacer />
          <ImagesList />
          <Spacer />
          <Toolbar>
            <div style={{ flex: 1 }} />
            {Object.values(images).length > 0 && (
              <Button
                variant="contained"
                color="primary"
                disabled={isBusy}
                onClick={() =>
                  this.props.GraphicAssetsStore.uploadSelectedImages()
                }
              >
                Upload
              </Button>
            )}
          </Toolbar>
        </form>
      </FormDialog>
    );
  }
}

export default inject('GraphicAssetsStore')(observer(MerchantDetailsForm));
