import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { inject, observer, PropTypes } from 'mobx-react';
import { TYPE_OVERLAY, TYPE_TREATMENT } from 'Stores/GraphicAssetsStore';

const TYPES = [
  { position: 0, type: TYPE_OVERLAY, label: 'Overlay Graphics' },
  { position: 1, type: TYPE_TREATMENT, label: 'Treatment Graphics' }
];

function typeToPosition(type) {
  const config = TYPES.find(typeData => typeData.type === type);
  return config.position;
}

function positionToType(position) {
  const config = TYPES.find(typeData => typeData.position === position);
  return config.type;
}

function TypeSwitcher({ GraphicAssetsStore }) {
  return (
    <AppBar position="relative">
      <Tabs
        value={typeToPosition(GraphicAssetsStore.type)}
        onChange={function(e, position) {
          GraphicAssetsStore.setType(positionToType(position));
        }}
        centered
      >
        {TYPES.map(({ type, label }) => (
          <Tab
            label={label}
            key={type}
            disabled={GraphicAssetsStore.isLoading}
          />
        ))}
      </Tabs>
    </AppBar>
  );
}

TypeSwitcher.propTypes = {
  GraphicAssetsStore: PropTypes.observableObject.isRequired
};

export default inject('GraphicAssetsStore')(observer(TypeSwitcher));
