import React from 'react';
import { Redirect } from 'react-router-dom';
import ReactPropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import withStyles from '@material-ui/core/styles/withStyles';
import { inject, observer, PropTypes } from 'mobx-react';

const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative'
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1
  }
});

function Login(props) {
  const { classes, AuthStore, CommonStore } = props;
  const { login, password } = AuthStore.values;
  const errorMessage = AuthStore.errorMessage;

  const handleLogin = e => {
    e.preventDefault();
    AuthStore.login();
  };
  const { from } = this.props.location.state || { from: { pathname: '/' } };
  if (CommonStore.token) {
    return <Redirect to={from} />;
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <div className={classes.wrapper}>
            <Avatar className={classes.avatar}>
              <LockIcon />
            </Avatar>
            {AuthStore.isBusy && (
              <CircularProgress size={68} className={classes.fabProgress} />
            )}
          </div>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} onSubmit={handleLogin}>
            <TextField
              id="login"
              label="Login"
              autoComplete="login"
              margin="normal"
              autoFocus
              value={login}
              onChange={e => AuthStore.setLogin(e.target.value)}
              fullWidth
              required
              error={!!errorMessage}
              helperText={errorMessage}
            />
            <TextField
              id="password"
              label="Password"
              type="password"
              autoComplete="current-password"
              margin="normal"
              value={password}
              onChange={e => AuthStore.setPassword(e.target.value)}
              fullWidth
              required
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={AuthStore.isBusy}
            >
              Sign in
            </Button>
          </form>
        </Paper>
      </main>
    </React.Fragment>
  );
}

Login.propTypes = {
  classes: ReactPropTypes.object.isRequired,
  CommonStore: PropTypes.observableObject,
  AuthStore: PropTypes.observableObject
};

const ObserverLogin = inject('CommonStore', 'AuthStore')(observer(Login));

export default withStyles(styles)(ObserverLogin);
