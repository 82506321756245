/* eslint-disable max-params */
import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import CommonStore from 'Stores/CommonStore';
import AuthStore from 'Stores/AuthStore';

const superagent = superagentPromise(_superagent, global.Promise);

const APP_ORIGIN = window.location.origin;
const API_ORIGIN = process.env.REACT_APP_API_URL || 'http://localhost:8080';
const API_ROOT = API_ORIGIN + '/admin';

const handleErrors = (err) => {
  if (err && err.response && err.response.status === 401) {
    AuthStore.logout();
    return;
  }
  if (err) {
    err.response.body = JSON.parse(err.response.text);
  }
  return err;
};

const responseBody = (res) => res.body;

const tokenPlugin = (req) => {
  if (CommonStore.token) {
    req.set('Authorization', `Bearer ${CommonStore.token}`);
  }
};

const jsonPlugin = (req) => {
  req.set('Accept', 'application/json');
  req.set('Content-Type', 'application/json');
};

const requests = {
  del: (url, body = {}) =>
    superagent
      .del(`${API_ROOT}${url}`)
      .send(body)
      .use(tokenPlugin)
      .use(jsonPlugin)
      .end(handleErrors)
      .then(responseBody),
  get: (url) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .use(jsonPlugin)
      .end(handleErrors)
      .then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .use(jsonPlugin)
      .end(handleErrors)
      .then(responseBody),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .use(jsonPlugin)
      .end(handleErrors)
      .then(responseBody),
};

const limit = (count, p) => `limit=${count}&offset=${p ? p * count : 0}`;
const sort = (sortBy, sortDirection) => {
  const parts = [];
  if (sortBy) {
    parts.push(`sortBy=${sortBy}`);
  }
  if (sortDirection) {
    parts.push(`sortDirection=${sortDirection}`);
  }
  return parts.join('&');
};
const filters = (filters) => {
  const parts = [];
  for (let filter in filters) {
    if (filters[filter]) {
      parts.push(`${filter}=${filters[filter]}`);
    }
  }
  return parts.join('&');
};

const Auth = {
  login: (login, password) =>
    requests.post('/authenticate', { login, password }),
};

const BillingPlan = {
  list: () => requests.get('/billing-plan'),
  create: (
    title,
    description,
    price,
    interval,
    frequency,
    trialPeriodDays,
    merchantTypes,
    roles
  ) =>
    requests.post('/billing-plan', {
      title,
      description,
      price,
      interval,
      frequency,
      trialPeriodDays,
      merchantTypes,
      roles,
    }),
  update: (id, title, description, trialPeriodDays, merchantTypes, roles) =>
    requests.put(`/billing-plan/${id}`, {
      title,
      description,
      trialPeriodDays,
      merchantTypes,
      roles,
    }),
  activate: (id) =>
    requests.put(`/billing-plan/${id}/activity`, { isActive: true }),
  deactivate: (id) =>
    requests.put(`/billing-plan/${id}/activity`, { isActive: false }),
};

const Pricing = {
  load: () => requests.get('/pricing'),
  change: (
    messagePrice,
    minimumBalance,
    fullBalance,
    merchantTypeId,
    merchantId
  ) =>
    requests.post('/pricing', {
      messagePrice,
      minimumBalance,
      fullBalance,
      merchantTypeId,
      merchantId,
    }),
  deleteLevelSettings: (level, levelId) => {
    switch (level) {
      case 'merchantTypeLevel':
        return requests.del('/pricing', { merchantTypeId: levelId });
      case 'merchantLevel':
        return requests.del('/pricing', { merchantId: levelId });
      default:
        throw new Error('Unknown level');
    }
  },
};

const MerchantTypes = {
  list: () => requests.get('/merchant-type'),
  create: (name, taxonomyId, customMessagePlaceholders) =>
    requests.post('/merchant-type', {
      name,
      taxonomyId,
      customMessagePlaceholders
    }),
  update: (id, name, taxonomyId, customMessagePlaceholders) =>
    requests.put(`/merchant-type/${id}`, {
      name,
      taxonomyId,
      customMessagePlaceholders
    }),
  delete: (id) => requests.del(`/merchant-type/${id}`),
};

const Merchants = {
  all: (page, lim = 10, appliedFilters) =>
    requests.get(`/merchant?${limit(lim, page)}&${filters(appliedFilters)}`),
  search: (term, page, lim = 10) =>
    requests.get(`/merchant/search?term=${term}&${limit(lim, page)}`),
  create: (
    name,
    email,
    businessPhone,
    password,
    merchantTypeId,
    billingPlanId
  ) =>
    requests.post('/merchant', {
      name,
      email,
      businessPhone,
      password,
      merchantTypeId,
      billingPlanId,
    }),
  update: (
    id,
    name,
    password,
    businessPhone,
    merchantTypeId,
    isAutoRechargeEnabled,
    customersPerNumber
  ) =>
    requests.put(`/merchant/${id}`, {
      name,
      password,
      businessPhone,
      merchantTypeId,
      isAutoRechargeEnabled,
      customersPerNumber,
    }),
  generateJwt: (id) => requests.post(`/merchant/${id}/jwt`),
  delete: (id) => requests.del(`/merchant/${id}`),
  allowFreePortalAccess: (id) =>
    requests.put(`/merchant/${id}/free-access`, { isActive: true }),
  disallowFreePortalAccess: (id) =>
    requests.put(`/merchant/${id}/free-access`, { isActive: false }),
  releasePhone: (merchantId, phone) =>
    requests.del(`/merchant/${merchantId}/release-phone/${phone}`),
};

const GraphicAssets = {
  all: (page, lim = 10, sortBy, sortDirection, appliedFilters) =>
    requests.get(
      `/graphic-asset?${limit(lim, page)}&${sort(
        sortBy,
        sortDirection
      )}&${filters(appliedFilters)}`
    ),
  create: (title, url, type, messageTemplateId) =>
    requests.post('/graphic-asset', {
      title,
      url,
      type,
      messageTemplateId,
    }),
  update: (id, title, type, messageTemplates, merchantTypes) =>
    requests.put(`/graphic-asset/${id}`, {
      title,
      type,
      messageTemplates,
      merchantTypes,
    }),
  linkToMessageTemplateAtPosition: (id, messageTemplateId, position) =>
    requests.put(`/graphic-asset/${id}/message-template/${messageTemplateId}`, {
      position,
    }),
  delete: (id) => requests.del(`/graphic-asset/${id}`),
};

const MessageTemplates = {
  byId: (id) => requests.get(`/message-template/${id}`),
  all: (page, lim = 10, appliedFilters) =>
    requests.get(
      `/message-template?${limit(lim, page)}&${filters(appliedFilters)}`
    ),
  create: (
    title,
    description,
    scriptText,
    messageText,
    landingPageText,
    imageUrl,
    isLogoPlaceholder
  ) =>
    requests.post('/message-template', {
      title,
      description,
      scriptText,
      messageText,
      landingPageText,
      imageUrl,
      isLogoPlaceholder,
    }),
  update: (
    id,
    title,
    description,
    scriptText,
    messageText,
    landingPageText,
    imageUrl,
    isLogoPlaceholder
  ) =>
    requests.put(`/message-template/${id}`, {
      title,
      description,
      scriptText,
      messageText,
      landingPageText,
      imageUrl,
      isLogoPlaceholder,
    }),
  addExampleVideo: (id, videoId) =>
    requests.put(`/message-template/${id}/example-video`, { videoId }),
  delete: (id) => requests.del(`/message-template/${id}`),
};

const ScriptCategories = {
  list: () => requests.get('/message-template-taxonomy/list'),
  loadTree: (taxonomyId) =>
    requests.get(`/message-template-taxonomy/${taxonomyId}`),
  createTaxonomy: (title) =>
    requests.put('/message-template-taxonomy', { title }),
  renameTaxonomy: (id, title) =>
    requests.post(`/message-template-taxonomy/${id}/rename`, { title }),
  cloneTaxonomy: (taxonomyId) =>
    requests.post(`/message-template-taxonomy/${taxonomyId}/clone`),
  deleteTaxonomy: (taxonomyId) =>
    requests.del(`/message-template-taxonomy/${taxonomyId}`),
  add: (taxonomyId, title, addTo = null, description) =>
    requests.post(`/message-template-taxonomy/${taxonomyId}/category`, {
      title,
      addTo: addTo ? normalizePath(addTo) : [],
      description,
    }),
  update: (taxonomyId, categoryPath, newTitle, newDescription, newPosition) =>
    requests.put(`/message-template-taxonomy/${taxonomyId}/category`, {
      categoryPath: normalizePath(categoryPath),
      newTitle,
      newDescription,
      newPosition,
    }),
  addImage: (taxonomyId, categoryPath, imageUrl) =>
    requests.put(`/message-template-taxonomy/${taxonomyId}/category/image`, {
      categoryPath: normalizePath(categoryPath),
      imageUrl,
    }),
  addScript: (taxonomyId, targetPath, item) =>
    requests.post(`/message-template-taxonomy/${taxonomyId}/category/item`, {
      targetPath: normalizePath(targetPath),
      item,
    }),
  deleteScript: (taxonomyId, targetPath, item) =>
    requests.del(`/message-template-taxonomy/${taxonomyId}/category/item`, {
      targetPath: normalizePath(targetPath),
      item,
    }),
  delete: (taxonomyId, categoryPath) =>
    requests.del(`/message-template-taxonomy/${taxonomyId}/category`, {
      categoryPath:
        typeof categoryPath === 'string' || categoryPath instanceof String
          ? normalizePath(categoryPath)
          : categoryPath,
    }),
};

const normalizePath = (path) => {
  return typeof path === 'string' || path instanceof String
    ? JSON.parse(path)
    : path;
};

const Video = {
  list: (page, lim = 10, sortBy, sortDirection, appliedFilters) =>
    requests.get(
      `/video?${limit(lim, page)}&${sort(sortBy, sortDirection)}&${filters(
        appliedFilters
      )}`
    ),
  record: (title, sourceUrl, sourceIdentity, metadata, videoFrameId) =>
    requests.post('/video', {
      title,
      sourceUrl,
      sourceIdentity,
      metadata,
      videoFrameId,
    }),
  changeSettings: (id, title, caption, thumbnailUrl) =>
    requests.put(`/video/${id}`, { title, caption, thumbnailUrl }),
  customize: (id, overlay, videoFrameId) =>
    requests.put(`/video/${id}/customize`, { overlay, videoFrameId }),
  changeCuratedListPresence: (id, isInCuratedList) =>
    requests.put(`/video/${id}/curated`, { isInCuratedList }),
};

const VideoFrame = {
  list: (page, lim = 10, sortBy, sortDirection, appliedFilters) =>
    requests.get(
      `/video-frame?${limit(lim, page)}&${sort(
        sortBy,
        sortDirection
      )}&${filters(appliedFilters)}`
    ),
  create: (title, sourceUrl, merchantTypes) =>
    requests.post('/video-frame', {
      title,
      sourceUrl,
      merchantTypes,
    }),
  update: (id, title, sourceUrl, merchantTypes) =>
    requests.put(`/video-frame/${id}`, { title, sourceUrl, merchantTypes }),
  changeAvailability: (id, availability) =>
    requests.put(`/video-frame/${id}/availability`, { availability }),
  delete: (id) => requests.del(`/video-frame/${id}`),
};

const S3 = {
  generatePreSignedUrl: (directory) =>
    requests.post('/upload-storage/generate-upload-url', { directory }),
  uploadUsingPreSignedUrl: (url, data) => superagent.put(url).send(data),
  uploadToDirectory: async (directory, data) => {
    const { key, url, cdnUrl } = await S3.generatePreSignedUrl(directory);
    await S3.uploadUsingPreSignedUrl(url, data);
    return { key, cdnUrl };
  },
  deleteFile: async (path) =>
    requests.del(`/upload-storage/delete-file?path=${path}`),
};

const VideoRecorder = {
  checkVideoProcessed: async (path) => {
    const { result } = await superagent
      .post(`${API_ORIGIN}/video-recorder/check-processed?path=${path}.mp4`)
      .send()
      .then(responseBody);
    return result;
  },
  processOffer: async (offer, iceCandidates) => {
    return superagent
      .post(`${API_ORIGIN}/video-recorder/process-rtc-offer`)
      .use(tokenPlugin)
      .send({ offer, iceCandidates })
      .then(responseBody);
  },
  releaseSession: async (sessionId, pipelineId) => {
    await superagent
      .del(`${API_ORIGIN}/video-recorder/release-media-pipeline`)
      .use(tokenPlugin)
      .send({ sessionId, pipelineId });
  },
};

const MagicLink = {
  send: (to, message, parameters) =>
    requests.post('/magic-link/send', {
      origin: APP_ORIGIN,
      to,
      message,
      parameters,
    }),
  get: (parameters) =>
    requests.post('/magic-link', {
      origin: APP_ORIGIN,
      parameters,
    }),
};

const Analytic = {
  performance: (startDate, endDate) =>
    requests.get(`/analytic/performance?${filters({ startDate, endDate })}`),
  carrier: (startDate, endDate) =>
    requests.get(`/analytic/carrier?${filters({ startDate, endDate })}`),
  messages: (
    merchantId,
    merchantTypeId,
    startDate,
    endDate,
    offset = 0,
    limit = 10
  ) =>
    requests.get(
      `/analytic/messages?${filters({
        merchantId,
        merchantTypeId,
        startDate,
        endDate,
        offset,
        limit,
      })}`
    ),
  message: (messageId, offset = 0, limit = 10, startDate, endDate) =>
    requests.get(
      `/analytic/messages/${messageId}?${filters({
        offset,
        limit,
        startDate,
        endDate,
      })}`
    ),
  videos: (
    merchantId,
    merchantTypeId,
    startDate,
    endDate,
    offset = 0,
    limit = 10
  ) =>
    requests.get(
      `/analytic/videos?${filters({
        merchantId,
        merchantTypeId,
        startDate,
        endDate,
        offset,
        limit,
      })}`
    ),
};

const DeliveryFailures = {
  list: (page, lim = 10) =>
    requests.get(`/delivery-failures?${limit(lim, page)}`),
};

const Scripts = {
  generate: (instruction, history) => {
    const config = Object.fromEntries(
      Object.entries({
        model: process.env['REACT_APP_CHAT_GPT_MODEL'] || null,
        maxTokens: process.env['REACT_APP_CHAT_GPT_MAX_TOKENS'] || null,
        temperature: process.env['REACT_APP_CHAT_GPT_TEMPERATURE'] || null,
        frequencyPenalty:
          process.env['REACT_APP_CHAT_GPT_FREQUENCY_PENALTY'] || null,
        presencePenalty:
          process.env['REACT_APP_CHAT_GPT_PRESENCE_PENALTY'] || null,
      }).filter(([_, v]) => v != null)
    );

    return superagent
      .post(`${API_ORIGIN}/scripts/generate`, {
        instruction,
        history,
        config,
      })
      .use(tokenPlugin)
      .use(jsonPlugin)
      .end(handleErrors)
      .then(responseBody);
  },
};

export default {
  Auth,
  BillingPlan,
  Pricing,
  Merchants,
  MerchantTypes,
  MessageTemplates,
  ScriptCategories,
  Video,
  VideoFrame,
  GraphicAssets,
  S3,
  VideoRecorder,
  MagicLink,
  Analytic,
  DeliveryFailures,
  Scripts,
};
