import { observable, action, computed, flow, decorate } from 'mobx';
import CommonStore from 'Stores/CommonStore';
import agent from 'agent';
import { observableMapToObject } from './utils';

const isBusyKey = 'isBusy';

const editableAttributes = ['name', 'taxonomyId', 'customMessagePlaceholders'];

class MerchantTypesStore {
  isLoading = false;
  merchantTypesRegistry = observable.map();
  editorRegistry = observable.map({
    [isBusyKey]: false,
    active: false,
    typeId: null,
    values: observable.map(),
    errors: observable.map()
  });

  reset() {
    this.isLoading = false;
    this.merchantTypesRegistry.clear();
    this.closeEditor();
  }

  load = flow(function*() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    try {
      const data = yield agent.MerchantTypes.list();
      this.merchantTypesRegistry.clear();
      data.forEach(merchantType =>
        this.merchantTypesRegistry.set(merchantType.id, merchantType)
      );
    } catch (e) {
      CommonStore.handleError(e);
    }
    this.isLoading = false;
  });

  submitChanges = flow(function*() {
    if (this.editorRegistry.get(isBusyKey)) {
      return;
    }
    this.editorRegistry.set(isBusyKey, true);
    try {
      const { merchantTypeId, values } = this.merchantTypeEditor;
      const { name, taxonomyId } = values;
      const customMessagePlaceholders = (values.customMessagePlaceholders || []).filter(
        value => !!value.placeholder && !!value.title
      ).reduce(
        (result, value) => ({...result, [value.placeholder]: value.title}),
        {}
      )
      if (merchantTypeId) {
        yield agent.MerchantTypes.update(merchantTypeId, name, taxonomyId, customMessagePlaceholders);
      } else {
        yield agent.MerchantTypes.create(name, taxonomyId, customMessagePlaceholders);
      }
      this.load();
      this.closeEditor();
    } catch (err) {
      const { validationErrors } = err.response.body;
      this.setEditorErrors(validationErrors);
      if (!validationErrors) {
        CommonStore.handleError(err);
      }
    }
    this.editorRegistry.set(isBusyKey, false);
  });

  deleteMerchantType = flow(function*(id) {
    try {
      this.merchantTypesRegistry.delete(id);
      yield agent.MerchantTypes.delete(id);
    } catch (err) {
      CommonStore.handleError(err);
    }
  });

  get merchantTypes() {
    return Object.values(observableMapToObject(this.merchantTypesRegistry));
  }

  getMerchantType(id) {
    return this.merchantTypesRegistry.get(id);
  }

  get merchantTypeEditor() {
    return {
      [isBusyKey]: this.editorRegistry.get(isBusyKey),
      active: this.editorRegistry.get('active'),
      merchantTypeId: this.editorRegistry.get('typeId'),
      values: observableMapToObject(this.editorRegistry.get('values')),
      errors: observableMapToObject(this.editorRegistry.get('errors'))
    };
  }

  setEditorErrors(errors = {}) {
    const editorErrors = this.editorRegistry.get('errors');
    editorErrors.clear();
    Object.keys(errors).map(attribute =>
      editorErrors.set(attribute, errors[attribute])
    );
  }

  startEditor(id) {
    this.editorRegistry.set('active', true);
    this.editorRegistry.set('typeId', id);
    const plan = id ? this.getMerchantType(id) : {};
    editableAttributes.forEach(attribute =>
      this.registerChange(attribute, plan[attribute])
    );
    this.setEditorErrors();
  }

  registerChange(attribute, value) {
    if (!this.editorRegistry.get('active')) {
      return;
    }
    this.editorRegistry.get('values').set(attribute, value);
  }

  closeEditor() {
    this.editorRegistry.set('active', false);
  }
}

const MobxMerchantTypesStore = decorate(MerchantTypesStore, {
  isLoading: observable,

  merchantTypes: computed,
  merchantTypeEditor: computed,

  load: action,
  reset: action,
  setEditorErrors: action,
  startEditor: action,
  registerChange: action,
  closeEditor: action,
  deleteMerchantType: action
});

export default new MobxMerchantTypesStore();
