import { action, computed, decorate, flow, observable } from 'mobx';
import agent from 'agent';
import CommonStore from 'Stores/CommonStore';
import { observableMapToObject, readUploadedFileAsDataUrl } from './utils';

const isBusyKey = 'isBusy';

const MAX_TITLE_LENGTH = 128;

const editableAttributes = ['title', 'messageTemplates', 'merchantTypes'];

export const TYPE_OVERLAY = 'overlay';
export const TYPE_TREATMENT = 'treatment';

class GraphicAssetsStore {
  type = TYPE_OVERLAY;
  isLoading = false;
  page = 0;
  rowsPerPage = 10;
  order = 'desc';
  orderBy = 'createdAt';
  totalAssetsCount = 0;
  assetsRegistry = observable.map();
  filtersRegistry = observable.map();
  messageTemplateRegistry = observable.map({
    [isBusyKey]: false,
    messageTemplates: observable.map()
  });
  merchantTypesRegistry = observable.map({
    [isBusyKey]: false,
    merchantTypes: observable.map()
  });
  assetEditorRegistry = observable.map({
    [isBusyKey]: false,
    active: false,
    assetId: null,
    values: observable.map(),
    errors: observable.map()
  });
  assetUploaderRegistry = observable.map({
    [isBusyKey]: false,
    active: false,
    messageTemplateId: null,
    images: observable.map()
  });

  setType(type) {
    if (this.type === type) {
      return;
    }
    this.type = type;
    this.reset();
    this.loadAssets();
  }

  setPage(page) {
    this.page = page;
  }

  setRowsPerPage(rowsPerPage) {
    this.rowsPerPage = rowsPerPage;
  }

  setSort(attribute) {
    if (this.orderBy !== attribute) {
      this.orderBy = attribute;
      this.order = 'asc';
    }

    this.order = this.order === 'asc' ? 'desc' : 'asc';
  }

  applyFilter = (attribute, value) => {
    value !== '' && value !== undefined
      ? this.filtersRegistry.set(attribute, value)
      : this.filtersRegistry.delete(attribute);
  };

  reset() {
    this.isLoading = false;
    this.page = 0;
    this.order = 'desc';
    this.orderBy = 'createdAt';
    this.rowsPerPage = 10;
    this.totalAssetsCount = 0;
    this.assetsRegistry.clear();
    this.filtersRegistry.clear();
  }

  loadAssets = flow(function*() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    try {
      const { data, count } = yield agent.GraphicAssets.all(
        this.page,
        this.rowsPerPage,
        this.orderBy,
        this.order,
        { type: this.type, ...this.filters }
      );
      this.assetsRegistry.clear();
      data.forEach(asset =>
        this.assetsRegistry.set(asset.id, {
          ...asset,
          merchantTypes: (asset.merchantTypes || []).map(({ id, name }) => ({
            value: id,
            label: name
          }))
        })
      );
      this.totalAssetsCount = count;
    } catch (e) {
      CommonStore.handleError(e);
    }
    this.isLoading = false;
  });

  /** @todo quick and dirty solution to search scripts by id and slug */
  loadMessageTemplates = flow(function*() {
    if (this.messageTemplateRegistry.get(isBusyKey)) {
      return;
    }
    this.messageTemplateRegistry.set(isBusyKey, true);
    try {
      const { data } = yield agent.MessageTemplates.all(0, 1000);
      const messageTemplates = this.messageTemplateRegistry.get(
        'messageTemplates'
      );
      messageTemplates.clear();
      data.forEach(messageTemplate =>
        messageTemplates.set(messageTemplate.id, messageTemplate)
      );
    } catch (e) {
      CommonStore.handleError(e);
    }
    this.messageTemplateRegistry.set(isBusyKey, false);
  });

  /** @todo quick and dirty solution to search merhant types by id and slug */
  loadMerchantTypes = flow(function*() {
    if (this.merchantTypesRegistry.get(isBusyKey)) {
      return;
    }
    this.merchantTypesRegistry.set(isBusyKey, true);
    try {
      const data = yield agent.MerchantTypes.list();
      const merchantTypes = this.merchantTypesRegistry.get('merchantTypes');
      merchantTypes.clear();
      data.forEach(merchantType =>
        merchantTypes.set(merchantType.id, merchantType)
      );
    } catch (e) {
      CommonStore.handleError(e);
    }
    this.merchantTypesRegistry.set(isBusyKey, false);
  });

  get assets() {
    return Object.values(observableMapToObject(this.assetsRegistry));
  }

  get assetEditor() {
    return {
      [isBusyKey]: this.assetEditorRegistry.get(isBusyKey),
      active: this.assetEditorRegistry.get('active'),
      assetId: this.assetEditorRegistry.get('assetId'),
      values: observableMapToObject(this.assetEditorRegistry.get('values')),
      errors: observableMapToObject(this.assetEditorRegistry.get('errors'))
    };
  }

  get assetUploader() {
    let isBusy = this.assetUploaderRegistry.get(isBusyKey);
    const images = observableMapToObject(
      this.assetUploaderRegistry.get('images')
    );
    if (!isBusy) {
      isBusy = Object.values(images).reduce((result, image) => {
        return image.uploading ? true : result;
      }, false);
    }
    return {
      [isBusyKey]: isBusy,
      active: this.assetUploaderRegistry.get('active'),
      messageTemplateId: this.assetUploaderRegistry.get('messageTemplateId'),
      images
    };
  }

  get messageTemplates() {
    return {
      [isBusyKey]: this.messageTemplateRegistry.get(isBusyKey),
      templates: observableMapToObject(
        this.messageTemplateRegistry.get('messageTemplates')
      )
    };
  }

  get merchantTypes() {
    return {
      [isBusyKey]: this.merchantTypesRegistry.get(isBusyKey),
      types: observableMapToObject(
        this.merchantTypesRegistry.get('merchantTypes')
      )
    };
  }

  get filters() {
    return observableMapToObject(this.filtersRegistry);
  }

  getAsset(id) {
    return this.assetsRegistry.get(id);
  }

  startAssetEditor(id) {
    this.assetEditorRegistry.set('active', true);
    this.assetEditorRegistry.set('assetId', id);
    const asset = this.getAsset(id) || {};

    editableAttributes.forEach(attribute => {
      if (attribute !== 'messageTemplates') {
        this.registerAssetChange(attribute, asset[attribute]);
        return;
      }
      this.registerAssetChange(
        attribute,
        asset[attribute].map(({ id, title }) => ({
          value: id,
          label: title
        }))
      );
    });
  }

  registerAssetChange(attribute, value) {
    if (!this.assetEditorRegistry.get('active')) {
      return;
    }
    this.assetEditorRegistry.get('values').set(attribute, value);
  }

  submitAssetChanges = flow(function*() {
    if (this.assetEditorRegistry.get(isBusyKey)) {
      return;
    }
    this.assetEditorRegistry.set(isBusyKey, true);
    try {
      const { assetId, values } = this.assetEditor;
      const { title } = values;
      const messageTemplates = values.messageTemplates.map(val => val.value);
      const merchantTypes = values.merchantTypes.map(val => val.value);
      yield agent.GraphicAssets.update(
        assetId,
        title,
        this.type,
        messageTemplates,
        merchantTypes
      );
      setTimeout(() => {
        this.loadAssets();
        this.closeAssetEditor();
      }, 2000);
    } catch (err) {
      const { validationErrors } = err.response.body;
      this.setEditorErrors(validationErrors);
      if (!validationErrors) {
        CommonStore.handleError(err);
      }
      this.assetEditorRegistry.set(isBusyKey, false);
    }
  });

  closeAssetEditor() {
    this.assetEditorRegistry.set(isBusyKey, false);
    this.assetEditorRegistry.set('active', false);
  }

  startAssetsUploader() {
    this.assetUploaderRegistry.set('active', true);
    this.assetUploaderRegistry.set('messageTemplateId', null);
    this.assetUploaderRegistry.get('images').clear();
  }

  closeAssetsUploader() {
    this.assetUploaderRegistry.set('active', false);
  }

  selectMessageTemplate = id => {
    this.assetUploaderRegistry.set('messageTemplateId', id);
  };

  addImageFiles = files => {
    for (let i in files) {
      this.addImageToSelected(files[i]);
    }
  };

  addImageToSelected = flow(function*(file) {
    let fileData = yield readUploadedFileAsDataUrl(file);
    const images = this.assetUploaderRegistry.get('images');
    images.set(file.name, {
      id: file.name,
      file: file,
      title: file.name.substring(0, MAX_TITLE_LENGTH),
      url: fileData
    });
  });

  changeSelectedImageTitle = (id, title) => {
    const images = this.assetUploaderRegistry.get('images');
    const image = images.get(id);
    if (image && !image.uploading) {
      image.title = title.substring(0, MAX_TITLE_LENGTH);
      images.set(id, image);
    }
  };

  deleteSelectedImage = id => {
    const images = this.assetUploaderRegistry.get('images');
    if (!images.get(id).uploading) {
      images.delete(id);
    }
  };

  uploadSelectedImages = () => {
    const images = [...this.assetUploaderRegistry.get('images').values()];
    for (let i in images) {
      this.uploadImage(images[i]);
    }
  };

  uploadImage = flow(function*(image) {
    const images = this.assetUploaderRegistry.get('images');
    images.set(image.id, { ...image, uploading: true });
    const { cdnUrl } = yield agent.S3.uploadToDirectory(
      'graphic-assets',
      image.file
    );
    yield agent.GraphicAssets.create(
      image.title,
      cdnUrl,
      this.type,
      this.assetUploaderRegistry.get('messageTemplateId')
    );
    images.delete(image.id);
    if (images.size === 0) {
      this.loadAssets();
    }
  });

  deleteAsset = flow(function*(id) {
    yield agent.GraphicAssets.delete(id);
    this.loadAssets();
  });
}

const MobxMerchantsStore = decorate(GraphicAssetsStore, {
  isLoading: observable,
  type: observable,
  page: observable,
  rowsPerPage: observable,
  order: observable,
  orderBy: observable,
  assetsRegistry: observable,
  totalAssetsCount: observable,

  assets: computed,
  assetEditor: computed,
  assetUploader: computed,
  filters: computed,
  messageTemplates: computed,
  merchantTypes: computed,

  addImageFiles: action,
  applyFilter: action,
  changeSelectedImageTitle: action,
  closeAssetEditor: action,
  closeAssetsUploader: action,
  deleteSelectedImage: action,
  loadAssets: action,
  loadMessageTemplates: action,
  loadMerchantTypes: action,
  registerAssetChange: action,
  reset: action,
  selectMessageTemplate: action,
  setType: action,
  setPage: action,
  setRowsPerPage: action,
  setSort: action,
  submitAssetChanges: action,
  startAssetEditor: action,
  startAssetsUploader: action,
  uploadSelectedImages: action
});

export default new MobxMerchantsStore();
