import { observable, action, computed, flow, decorate } from 'mobx';
import CommonStore from 'Stores/CommonStore';
import agent from 'agent';
import { observableMapToObject } from './utils';

const isBusyKey = 'isBusy';

const editableAttributes = [
  'trialPeriodDays',
  'title',
  'description',
  'price',
  'interval',
  'frequency',
  'merchantTypes',
  'roles'
];

class BillingPlanStore {
  isLoading = false;
  billingPlanRegistry = observable.map();
  billingPlanEditorRegistry = observable.map({
    [isBusyKey]: false,
    active: false,
    planId: null,
    values: observable.map(),
    errors: observable.map()
  });

  reset() {
    this.isLoading = false;
    this.billingPlanRegistry.clear();
    this.closeBillingPlanEditor();
  }

  load = flow(function*() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    try {
      const data = yield agent.BillingPlan.list();
      this.billingPlanRegistry.clear();
      data.forEach(plan => this.billingPlanRegistry.set(plan.id, plan));
    } catch (e) {
      CommonStore.handleError(e);
    }
    this.isLoading = false;
  });

  submitBillingPlanChanges = flow(function*() {
    if (this.billingPlanEditorRegistry.get(isBusyKey)) {
      return;
    }
    this.billingPlanEditorRegistry.set(isBusyKey, true);
    try {
      const { planId, values } = this.billingPlanEditor;
      const {
        trialPeriodDays,
        title,
        description,
        price,
        interval,
        frequency,
        roles
      } = values;
      const merchantTypes = values.merchantTypes.map(({ value }) => value);
      if (planId) {
        yield agent.BillingPlan.update(
          planId,
          title,
          description,
          trialPeriodDays,
          merchantTypes,
          roles
        );
      } else {
        yield agent.BillingPlan.create(
          title,
          description,
          price,
          interval,
          frequency,
          trialPeriodDays,
          merchantTypes,
          roles
        );
      }
      this.load();
      this.closeBillingPlanEditor();
    } catch (err) {
      const { validationErrors } = err.response.body;
      this.setEditorErrors(validationErrors);
      if (!validationErrors) {
        CommonStore.handleError(err);
      }
    }
    this.billingPlanEditorRegistry.set(isBusyKey, false);
  });

  activate = flow(function*(id) {
    try {
      const plan = this.getBillingPlan(id);
      if (!plan) {
        return;
      }
      yield agent.BillingPlan.activate(id);
      this.billingPlanRegistry.set(plan.id, { ...plan, isActive: true });
    } catch (e) {
      CommonStore.handleError(e);
    }
  });

  deactivate = flow(function*(id) {
    try {
      const plan = this.getBillingPlan(id);
      if (!plan) {
        return;
      }
      yield agent.BillingPlan.deactivate(id);
      this.billingPlanRegistry.set(plan.id, { ...plan, isActive: false });
    } catch (e) {
      CommonStore.handleError(e);
    }
  });

  get plans() {
    return Object.values(observableMapToObject(this.billingPlanRegistry));
  }

  getBillingPlan(id) {
    return this.billingPlanRegistry.get(id);
  }

  get billingPlanEditor() {
    return {
      [isBusyKey]: this.billingPlanEditorRegistry.get(isBusyKey),
      active: this.billingPlanEditorRegistry.get('active'),
      planId: this.billingPlanEditorRegistry.get('planId'),
      values: observableMapToObject(
        this.billingPlanEditorRegistry.get('values')
      ),
      errors: observableMapToObject(
        this.billingPlanEditorRegistry.get('errors')
      )
    };
  }

  setEditorErrors(errors = {}) {
    const editorErrors = this.billingPlanEditorRegistry.get('errors');
    editorErrors.clear();
    Object.keys(errors).map(attribute =>
      editorErrors.set(attribute, errors[attribute])
    );
  }

  startBillingPlanEditor(id) {
    this.billingPlanEditorRegistry.set('active', true);
    this.billingPlanEditorRegistry.set('planId', id);
    const plan = id ? this.getBillingPlan(id) : { roles: [] };
    editableAttributes.forEach(attribute =>
      this.registerBillingPlanChange(attribute, plan[attribute])
    );
    this.registerBillingPlanChange(
      'merchantTypes',
      (plan.availableForMerchantTypes || []).map(id => ({ value: id }))
    );
    this.setEditorErrors();
  }

  registerBillingPlanChange(attribute, value) {
    if (!this.billingPlanEditorRegistry.get('active')) {
      return;
    }
    this.billingPlanEditorRegistry.get('values').set(attribute, value);
  }

  closeBillingPlanEditor() {
    this.billingPlanEditorRegistry.set('active', false);
  }
}

const MobxBillingPlanStore = decorate(BillingPlanStore, {
  isLoading: observable,

  plans: computed,
  billingPlanEditor: computed,

  activate: action,
  deactivate: action,
  load: action,
  reset: action,
  setEditorErrors: action,
  startBillingPlanEditor: action,
  registerBillingPlanChange: action,
  closeBillingPlanEditor: action
});

export default new MobxBillingPlanStore();
