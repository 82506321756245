import React from 'react';
import RangeSelector from './RangeSelector';
import GeneralStats from './GeneralStats';
import CarrierStats from './CarrierStats';
import MessagesReport from './MessagesReport';
import VideosReport from './VideosReport';
import { DASHBOARD_REPORTS } from 'Utils/whitelabeling';

const AVAILABLE_REPORTS = [
  {
    id: 'messages',
    Component: MessagesReport,
  },
  {
    id: 'videos',
    Component: VideosReport,
  },
  {
    id: 'carrier-stats',
    Component: CarrierStats,
  },
];

const REPORTS = DASHBOARD_REPORTS.map((reportId) =>
  AVAILABLE_REPORTS.find((report) => report.id === reportId)
).filter((report) => !!report);

function Dashboard() {
  return (
    <div>
      <RangeSelector />
      <GeneralStats />
      <>
        {REPORTS.map(({ id, Component }) => (
          <Component key={id} />
        ))}
      </>
    </div>
  );
}

export default Dashboard;
