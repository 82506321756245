import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import Table from 'Components/Table/Table';
import Loader from 'Components/Progress/Loader';
import {
  Add,
  Edit,
  Cancel,
  Lock,
  ToggleOff,
  ToggleOn,
  Delete,
} from '@material-ui/icons';
import MerchantForm from './MerchantDetailsForm';
import agent from 'agent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

class Merchants extends React.Component {
  static propTypes = {
    MerchantsStore: PropTypes.observableObject,
    CommonStore: PropTypes.observableObject,
  };
  componentWillMount() {
    this.props.CommonStore.setTitle('Users');
    this.props.MerchantsStore.reset();
    this.props.MerchantsStore.loadMerchants();
    this.loadMerchantTypes();
  }

  loadMerchantTypes = async () => {
    const merchantTypes = await agent.MerchantTypes.list();
    this.setState({ merchantTypes });
  };

  handleChangePage = (event, page) => {
    this.props.MerchantsStore.setPage(page);
    this.props.MerchantsStore.loadMerchants();
  };

  handleChangeRowsPerPage = (event) => {
    this.props.MerchantsStore.setRowsPerPage(event.target.value);
    this.props.MerchantsStore.loadMerchants();
  };
  handleDelete = (merchant) => {
    this.props.CommonStore.confirmAction('Delete user?', () => {
      const { MerchantsStore } = this.props;
      MerchantsStore.deleteMerchant(merchant.id);
    });
  };
  applyFilter = (attribute, value) => {
    const { MerchantsStore } = this.props;
    MerchantsStore.applyFilter(attribute, value);
    MerchantsStore.loadMerchants();
  };
  renderFilters = () => {
    const {
      MerchantsStore: {
        isLoading,
        filters: { portalAccess = '' },
      },
    } = this.props;

    return (
      <FormControl>
        <InputLabel>Access to portal:</InputLabel>
        <Select
          fullWidth
          style={{
            minWidth: 150,
          }}
          value={portalAccess}
          onChange={(e) => this.applyFilter('portalAccess', e.target.value)}
          disabled={isLoading}
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          <MenuItem value={1}>
            <em>With portal access</em>
          </MenuItem>
          <MenuItem value={-1}>
            <em>Without portal access</em>
          </MenuItem>
        </Select>
      </FormControl>
    );
  };
  render() {
    const {
      merchants,
      filters,
      merchantEditor,
      isLoading,
      rowsPerPage,
      totalMerchantsCount,
      page,
    } = this.props.MerchantsStore;
    const { merchantTypes } = this.state || {};

    if (merchantEditor.active) {
      return <MerchantForm />;
    }

    return (
      <Loader isBusy={(isLoading && merchants.length === 0) || !merchantTypes}>
        <Table
          noWrap
          data={{
            columns: this.getColumns(),
            rows: merchants.map((merchant) => ({
              ...merchant,
              isTrialActive: merchant.serviceSubscriptionId
                ? merchant.isTrialActive
                : false,
            })),
            emptyDataText: 'There are no users yet',
            showEmptyRows: true,
          }}
          toolbar={{
            title: 'Users',
            render: this.renderFilters,
            buttons: [
              {
                title: 'Register new user',
                icon: <Add />,
                onClick: () => this.props.MerchantsStore.startMerchantEditor(),
              },
            ],
          }}
          rowToolbar={[
            {
              title: 'Allow free usage',
              icon: <ToggleOff />,
              isVisible: (merchant) => !merchant.freePortalAccess,
              onClick: ({ id }) =>
                this.props.MerchantsStore.allowFreePortalAccess(id),
            },
            {
              title: 'Disallow free usage',
              icon: <ToggleOn />,
              isVisible: (merchant) => merchant.freePortalAccess,
              onClick: ({ id }) =>
                this.props.MerchantsStore.disallowFreePortalAccess(id),
            },
            {
              title: 'Edit user details',
              icon: <Edit />,
              onClick: (merchant) =>
                this.props.MerchantsStore.startMerchantEditor(merchant.id),
            },
            {
              title: 'Sign in as user',
              icon: <Lock />,
              onClick: (merchant) =>
                this.props.MerchantsStore.signInAsMerchant(merchant.id),
            },
            {
              title: 'Cancel subscription',
              icon: <Cancel />,
              isVisible: (merchant) => !!merchant.serviceSubscriptionId,
              onClick: (merchant) => {
                const win = window.open(
                  `https://dashboard.stripe.com/subscriptions/${merchant.serviceSubscriptionId}`,
                  '_blank'
                );
                win.focus();
              },
            },
            {
              title: 'Delete user',
              icon: <Delete />,
              isVisible: (merchant) => !merchant.serviceSubscriptionId,
              onClick: this.handleDelete,
            },
          ]}
          pagination={{
            totalRowsCount: totalMerchantsCount,
            currentPage: page,
            handleChangePage: this.handleChangePage,
            handleChangeRowsPerPage: this.handleChangeRowsPerPage,
            rowsPerPage,
          }}
          applyFilter={this.applyFilter}
          filters={filters}
        />
      </Loader>
    );
  }
  getColumns() {
    const { merchantTypes = [] } = this.state || {};

    return [
      {
        title: 'Type',
        key: 'merchantType',
        filterData: merchantTypes.map((type) => ({
          value: type.id,
          label: type.name,
        })),
        width: '200px',
        filterKey: 'merchantTypeId',
      },
      {
        title: 'Name',
        key: 'name',
        width: '350px',
      },
      {
        title: 'Company',
        key: 'office_name',
        width: '250px',
      },
      {
        title: 'Registered at',
        key: 'createdAt',
        type: 'date-time',
        width: '200px',
      },
      {
        title: 'Subscription',
        type: 'custom',
        key: 'media',
        width: '120px',
        render: (merchant) => {
          const { serviceSubscriptionId, serviceSubscriptionState } = merchant;

          return serviceSubscriptionId ? serviceSubscriptionState : '-';
        },
      },
      {
        title: 'Balance',
        type: 'custom',
        key: 'balance',
        width: '200px',
        render: ({ balance }) => {
          const balanceInDollars = (balance / 100).toFixed(2);

          return `$${balanceInDollars}`;
        },
      },
    ];
  }
}
export default inject('MerchantsStore', 'CommonStore')(observer(Merchants));
