import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import Spacer from 'Components/Misc/Spacer';
import TextField from '@material-ui/core/TextField';
import FormDialog from 'Components/Dialog/FormDialog';
import DataAutosuggest from 'Components/Autosuggest/DataAutosuggest';

class ScriptForm extends React.Component {
  static propTypes = {
    GraphicAssetsStore: PropTypes.observableObject,
  };
  handleFormSubmit = (e) => {
    e.preventDefault();
    this.submit();
  };
  submit = () => {
    this.props.GraphicAssetsStore.submitAssetChanges();
  };
  cancel = () => {
    this.props.GraphicAssetsStore.closeAssetEditor();
  };
  makeChangeHandler = (name) => {
    return (e) => {
      this.props.GraphicAssetsStore.registerAssetChange(name, e.target.value);
    };
  };
  render() {
    const title = 'Update asset';
    const { assetEditor, messageTemplates, merchantTypes } =
      this.props.GraphicAssetsStore;
    const { isBusy, values, errors } = assetEditor;
    const messageTemplateSuggestions = Object.values(
      messageTemplates.templates
    ).map((template) => ({
      value: template.id,
      label: template.title,
    }));
    const merchantTypeSuggestion = [
      { value: 'all', label: 'All types' },
    ].concat(
      Object.values(merchantTypes.types).map((type) => ({
        value: type.id,
        label: type.name,
      }))
    );
    return (
      <FormDialog
        title={title}
        isBusy={isBusy}
        handleCancel={this.cancel}
        handleSubmit={this.submit}
      >
        <form onSubmit={this.handleFormSubmit}>
          <Spacer />
          <TextField
            label="Asset title"
            placeholder="Asset title"
            value={values.title || ''}
            error={!!errors.title}
            helperText={errors.title}
            fullWidth
            margin="normal"
            onChange={this.makeChangeHandler('title')}
          />
          <Spacer />
          <DataAutosuggest
            suggestions={messageTemplateSuggestions}
            placeholder="Select linked messages"
            onChange={(values) =>
              this.props.GraphicAssetsStore.registerAssetChange(
                'messageTemplates',
                values
              )
            }
            value={values.messageTemplates || []}
            isMulti
          />
          <Spacer />
          <DataAutosuggest
            suggestions={merchantTypeSuggestion}
            placeholder="Select user types"
            onChange={(values) => {
              const vals = values || [];
              const allTypesSelected = vals.find(
                (value) => value.value === 'all'
              );
              this.props.GraphicAssetsStore.registerAssetChange(
                'merchantTypes',
                (allTypesSelected ? merchantTypeSuggestion : vals).filter(
                  (value) => value.value !== 'all'
                )
              );
            }}
            value={values.merchantTypes || []}
            isMulti
          />
        </form>
      </FormDialog>
    );
  }
}

export default inject('GraphicAssetsStore')(observer(ScriptForm));
