import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  spacer: {
    flex: '1 1 auto',
    margin: theme.spacing.unit
  }
});

function Spacer({ classes }) {
  return <div className={classes.spacer} />;
}

Spacer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Spacer);
