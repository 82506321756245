import { action, computed, decorate, flow, observable } from 'mobx';
import agent from 'agent';
import CommonStore from 'Stores/CommonStore';
import { observableMapToObject } from './utils';

const editableAttributes = [
  'name',
  'email',
  'businessPhone',
  'password',
  'merchantTypeId',
  'isAutoRechargeEnabled',
  'customersPerNumber',
  'billingPlanId'
];

const isBusyKey = 'isBusy';

class MerchantsStore {
  isLoading = false;
  page = 0;
  rowsPerPage = 10;
  totalMerchantsCount = 0;
  merchantsRegistry = observable.map();
  filtersRegistry = observable.map();
  merchantEditorRegistry = observable.map({
    [isBusyKey]: false,
    active: false,
    merchantId: null,
    values: observable.map(),
    errors: observable.map()
  });

  reset() {
    this.isLoading = false;
    this.page = 0;
    this.rowsPerPage = 10;
    this.totalMerchantsCount = 0;
    this.merchantsRegistry.clear();
    this.filtersRegistry.clear();
    this.closeMerchantEditor();
  }

  loadMerchants = flow(function*() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    try {
      const { data, count } = yield agent.Merchants.all(
        this.page,
        this.rowsPerPage,
        this.filters
      );
      this.merchantsRegistry.clear();
      data.forEach(merchant =>
        this.merchantsRegistry.set(merchant.id, merchant)
      );
      this.totalMerchantsCount = count;
    } catch (e) {
      CommonStore.handleError(e);
    }
    this.isLoading = false;
  });

  submitMerchantChanges = flow(function*() {
    if (this.merchantEditorRegistry.get(isBusyKey)) {
      return;
    }
    this.merchantEditorRegistry.set(isBusyKey, true);
    try {
      const { merchantId, values } = this.merchantEditor;
      const {
        name,
        email,
        businessPhone,
        password,
        merchantTypeId,
        isAutoRechargeEnabled,
        billingPlanId,
        customersPerNumber
      } = values;
      if (merchantId) {
        yield agent.Merchants.update(
          merchantId,
          name,
          password,
          businessPhone,
          merchantTypeId,
          isAutoRechargeEnabled,
          customersPerNumber ? customersPerNumber : 0
        );
      } else {
        yield agent.Merchants.create(
          name,
          email,
          businessPhone,
          password,
          merchantTypeId,
          billingPlanId
        );
      }
      this.loadMerchants();
      this.closeMerchantEditor();
    } catch (err) {
      const { validationErrors } = err.response.body;
      this.setEditorErrors(validationErrors);
      if (!validationErrors) {
        CommonStore.handleError(err);
      }
    }
    this.merchantEditorRegistry.set(isBusyKey, false);
  });

  allowFreePortalAccess = flow(function*(merchantId) {
    try {
      yield agent.Merchants.allowFreePortalAccess(merchantId);
      this.merchantsRegistry.set(merchantId, {
        ...this.merchantsRegistry.get(merchantId),
        freePortalAccess: true
      });
    } catch (e) {
      CommonStore.handleError(e);
    }
  });

  disallowFreePortalAccess = flow(function*(merchantId) {
    try {
      yield agent.Merchants.disallowFreePortalAccess(merchantId);
      this.merchantsRegistry.set(merchantId, {
        ...this.merchantsRegistry.get(merchantId),
        freePortalAccess: false
      });
    } catch (e) {
      CommonStore.handleError(e);
    }
  });

  signInAsMerchant = flow(function*(id) {
    try {
      const { url } = yield agent.Merchants.generateJwt(id);
      const win = window.open(url, '_blank');
      win.focus();
    } catch (e) {
      CommonStore.handleError(e);
    }
  });

  deleteMerchant = flow(function*(id) {
    try {
      this.merchantsRegistry.delete(id);
      yield agent.Merchants.delete(id);
      this.loadMerchants();
    } catch (e) {
      CommonStore.handleError(e);
    }
  });

  get merchants() {
    return Object.values(observableMapToObject(this.merchantsRegistry));
  }

  get filters() {
    return observableMapToObject(this.filtersRegistry);
  }

  get merchantEditor() {
    return {
      [isBusyKey]: this.merchantEditorRegistry.get(isBusyKey),
      active: this.merchantEditorRegistry.get('active'),
      merchantId: this.merchantEditorRegistry.get('merchantId'),
      values: observableMapToObject(this.merchantEditorRegistry.get('values')),
      errors: observableMapToObject(this.merchantEditorRegistry.get('errors'))
    };
  }

  getMerchant(id) {
    return this.merchantsRegistry.get(id);
  }

  applyFilter = (attribute, value) => {
    value !== '' && value !== undefined
      ? this.filtersRegistry.set(attribute, value)
      : this.filtersRegistry.delete(attribute);
  };

  setPage(page) {
    this.page = page;
  }

  setRowsPerPage(rowsPerPage) {
    this.rowsPerPage = rowsPerPage;
  }

  setEditorErrors(errors = {}) {
    const editorErrors = this.merchantEditorRegistry.get('errors');
    editorErrors.clear();
    Object.keys(errors).map(attribute =>
      editorErrors.set(attribute, errors[attribute])
    );
  }

  startMerchantEditor(id) {
    this.merchantEditorRegistry.set('active', true);
    this.merchantEditorRegistry.set('merchantId', id);
    const merchant = id ? this.getMerchant(id) : {};
    editableAttributes.forEach(attribute =>
      this.registerMerchantChange(attribute, merchant[attribute])
    );
    this.setEditorErrors();
  }

  registerMerchantChange(attribute, value) {
    if (!this.merchantEditorRegistry.get('active')) {
      return;
    }
    this.merchantEditorRegistry.get('values').set(attribute, value);
  }

  closeMerchantEditor() {
    this.merchantEditorRegistry.set('active', false);
  }
}

const MobxMerchantsStore = decorate(MerchantsStore, {
  isLoading: observable,
  page: observable,
  rowsPerPage: observable,
  merchantsRegistry: observable,
  totalMerchantsCount: observable,

  merchants: computed,
  filters: computed,
  merchantEditor: computed,

  applyFilter: action,
  closeMerchantEditor: action,
  deleteMerchant: action,
  loadMerchants: action,
  registerMerchantChange: action,
  reset: action,
  setPage: action,
  setRowsPerPage: action,
  signInAsMerchant: action,
  startMerchantEditor: action,
  submitMerchantChanges: action
});

export default new MobxMerchantsStore();
