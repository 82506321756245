import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import Spacer from 'Components/Misc/Spacer';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormDialog from 'Components/Dialog/FormDialog';
import EditableImage from 'Components/Image/EditableImage';
import FormHelperText from '@material-ui/core/FormHelperText';
import DataAutosuggest from 'Components/Autosuggest/DataAutosuggest';

class FrameForm extends React.Component {
  static propTypes = {
    VideoFramesStore: PropTypes.observableObject,
  };
  handleFormSubmit = (e) => {
    e.preventDefault();
    this.submit();
  };
  submit = () => {
    this.props.VideoFramesStore.submitFrameChanges();
  };
  cancel = () => {
    this.props.VideoFramesStore.closeEditor();
  };
  makeChangeHandler = (name) => {
    return (e) => {
      this.props.VideoFramesStore.registerFrameChange(name, e.target.value);
    };
  };
  render() {
    const {
      VideoFramesStore: {
        frameEditor: { isBusy, values, errors, frameId },
        merchantTypes,
      },
    } = this.props;
    const title = frameId ? 'Update frame' : 'Add frame';
    const merchantTypeSuggestion = [
      { value: 'all', label: 'All types' },
    ].concat(
      Object.values(merchantTypes.types).map((type) => ({
        value: type.id,
        label: type.name,
      }))
    );

    return (
      <FormDialog
        title={title}
        isBusy={isBusy}
        handleCancel={this.cancel}
        handleSubmit={this.submit}
      >
        <form onSubmit={this.handleFormSubmit}>
          <Spacer />
          <FormGroup row>
            <EditableImage
              imageUrl={values.sourceUrl}
              accept="image/png"
              onChange={(file) =>
                this.props.VideoFramesStore.registerFrameChange('file', file)
              }
            />
          </FormGroup>
          {errors.sourceUrl && (
            <FormHelperText error>{errors.sourceUrl}</FormHelperText>
          )}
          <Spacer />
          <TextField
            label="Frame title"
            placeholder="Frame title"
            value={values.title || ''}
            error={!!errors.title}
            helperText={errors.title}
            fullWidth
            margin="normal"
            onChange={this.makeChangeHandler('title')}
          />
          <Spacer />
          <DataAutosuggest
            suggestions={merchantTypeSuggestion}
            placeholder="Select user types"
            onChange={(values) => {
              const vals = values || [];
              const allTypesSelected = vals.find(
                (value) => value.value === 'all'
              );
              this.props.VideoFramesStore.registerFrameChange(
                'merchantTypes',
                (allTypesSelected ? merchantTypeSuggestion : vals).filter(
                  (value) => value.value !== 'all'
                )
              );
            }}
            value={values.merchantTypes || []}
            isMulti
          />
        </form>
      </FormDialog>
    );
  }
}

export default inject('VideoFramesStore')(observer(FrameForm));
