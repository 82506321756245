import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import agent from '../../agent';

function MerchantTypeSelector({ isDisabled, value, onChange }) {
  const [options, setOptions] = useState([]);
  async function loadMerchantTypes() {
    const types = await agent.MerchantTypes.list();
    setOptions(types.map(({ id, name }) => ({ value: id, label: name })));
  }

  useEffect(function () {
    loadMerchantTypes();
  }, []);
  return (
    <Select
      fullWidth
      placeholder="Select user type"
      options={options}
      isClearable
      {...{ value, isDisabled, onChange }}
    />
  );
}

export default MerchantTypeSelector;
