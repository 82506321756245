import React from 'react';
import ReactPropTypes from 'prop-types';
import { inject, observer, PropTypes } from 'mobx-react';
import Table from 'Components/Table/Table';
import Loader from 'Components/Progress/Loader';
import { Add, Edit, ToggleOff, ToggleOn } from '@material-ui/icons';
import BillingPlanDetailsForm from './BillingPlanDetailsForm';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  merchantTypes: { overflow: 'hidden', textOverflow: 'ellipsis' },
});

class BillingPlans extends React.Component {
  static propTypes = {
    classes: ReactPropTypes.object.isRequired,
    BillingPlanStore: PropTypes.observableObject,
    MerchantTypesStore: PropTypes.observableObject,
    CommonStore: PropTypes.observableObject,
  };
  componentWillMount() {
    this.props.CommonStore.setTitle('Billing plans');
    this.props.BillingPlanStore.reset();
    this.props.BillingPlanStore.load();
    this.props.MerchantTypesStore.load();
  }
  render() {
    const {
      BillingPlanStore: { plans, billingPlanEditor, isLoading },
      MerchantTypesStore: { isLoading: isLoadingMerchantTypes },
    } = this.props;

    if (billingPlanEditor.active) {
      return <BillingPlanDetailsForm />;
    }
    return (
      <Loader isBusy={isLoading || isLoadingMerchantTypes}>
        <Table
          data={{
            columns: this.getColumns(),
            rows: plans,
            emptyDataText: 'There are no billing plans yet',
            showEmptyRows: true,
          }}
          toolbar={{
            title: 'Billing plans',
            buttons: [
              {
                title: 'Add new billing plan',
                icon: <Add />,
                onClick: () =>
                  this.props.BillingPlanStore.startBillingPlanEditor(),
              },
            ],
          }}
          rowToolbar={[
            {
              title: 'Edit billing plan details',
              icon: <Edit />,
              onClick: (billingPlan) =>
                this.props.BillingPlanStore.startBillingPlanEditor(
                  billingPlan.id
                ),
            },
            {
              title: 'Activate',
              icon: <ToggleOff />,
              isVisible: (billingPlan) => !billingPlan.isActive,
              onClick: ({ id }) => this.props.BillingPlanStore.activate(id),
            },
            {
              title: 'Deactivate',
              icon: <ToggleOn />,
              isVisible: (billingPlan) => billingPlan.isActive,
              onClick: ({ id }) => this.props.BillingPlanStore.deactivate(id),
            },
          ]}
        />
      </Loader>
    );
  }
  getColumns() {
    return [
      {
        title: 'Billing plan title',
        key: 'title',
      },
      {
        title: 'User types',
        type: 'custom',
        key: 'merchantTypes',
        render: ({ availableForMerchantTypes }) => {
          const stringPresentation = availableForMerchantTypes
            .map((typeId) => {
              const type = this.props.MerchantTypesStore.merchantTypes.find(
                (type) => type.id === typeId
              );
              return type ? type.name : null;
            })
            .filter((val) => !!val)
            .join(', ');
          return (
            <p
              title={stringPresentation}
              className={this.props.classes.merchantTypes}
            >
              {stringPresentation}
            </p>
          );
        },
      },
      {
        title: 'Trial period, days',
        key: 'trialPeriodDays',
      },
      {
        title: 'Price, $',
        key: 'price',
        type: 'custom',
        render: ({ price }) => price,
      },
      {
        title: 'Interval',
        type: 'custom',
        key: 'interval',
        render: ({ period, frequency }) => [frequency, period].join(' '),
      },
      {
        title: 'Created at',
        key: 'createdAt',
        type: 'date-time',
      },
      {
        title: 'Active',
        key: 'isActive',
        type: 'flag',
      },
    ];
  }
}
export default withStyles(styles)(
  inject(
    'BillingPlanStore',
    'MerchantTypesStore',
    'CommonStore'
  )(observer(BillingPlans))
);
