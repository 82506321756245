import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import Table from 'Components/Table/Table';
import Loader from 'Components/Progress/Loader';
import { Add, Edit, Delete, FileCopy } from '@material-ui/icons';
import MerchantTypeForm from './MerchantTypeForm';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from 'Components/Snakbar';

class MerchantTypes extends React.Component {
  static propTypes = {
    MerchantTypesStore: PropTypes.observableObject,
    CommonStore: PropTypes.observableObject,
  };
  componentWillMount() {
    this.props.CommonStore.setTitle('User types');
    this.props.MerchantTypesStore.reset();
    this.props.MerchantTypesStore.load();
  }
  handleDelete = ({ id }) => {
    this.props.CommonStore.confirmAction('Delete user type?', () => {
      const { MerchantTypesStore } = this.props;
      MerchantTypesStore.deleteMerchantType(id);
    });
  };
  render() {
    const { merchantTypes, merchantTypeEditor, isLoading } =
      this.props.MerchantTypesStore;
    const { copied } = this.state || {};

    if (merchantTypeEditor.active) {
      return <MerchantTypeForm />;
    }
    return (
      <Loader isBusy={isLoading}>
        <textarea
          ref={(node) => (this.textarea = node)}
          style={{ width: 0, height: 0, opacity: 0 }}
        />
        {copied && (
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            style={{ marginBottom: 8 }}
            open={true}
            autoHideDuration={6000}
            onClose={() => this.setState({ copied: false })}
          >
            <SnackbarContent
              variant="info"
              message="Copied"
              onClose={() => this.setState({ copied: false })}
            />
          </Snackbar>
        )}
        <Table
          data={{
            columns: MerchantTypes.getColumns(),
            rows: merchantTypes,
            emptyDataText: 'There are no user types yet',
            showEmptyRows: true,
          }}
          toolbar={{
            title: 'User types',
            buttons: [
              {
                title: 'Add new user type',
                icon: <Add />,
                onClick: () => this.props.MerchantTypesStore.startEditor(),
              },
            ],
          }}
          rowToolbar={[
            {
              title: 'Copy ID',
              icon: <FileCopy />,
              onClick: (type) => {
                this.textarea.value = type.id;
                this.textarea.select();
                window.document.execCommand('copy');
                this.setState({ copied: true });
              },
            },
            {
              title: 'Edit user type',
              icon: <Edit />,
              onClick: ({ id }) =>
                this.props.MerchantTypesStore.startEditor(id),
            },
            {
              title: 'Delete',
              icon: <Delete />,
              onClick: this.handleDelete,
            },
          ]}
        />
      </Loader>
    );
  }
  static getColumns() {
    return [
      {
        title: 'User type name',
        key: 'name',
      },
      {
        title: 'Number of users',
        key: 'merchantsCount',
      },
      {
        title: 'Category group',
        key: 'taxonomyTitle',
        defaultValue: 'Default',
      },
      {
        title: 'Created at',
        key: 'createdAt',
        type: 'date-time',
      },
    ];
  }
}
export default inject(
  'MerchantTypesStore',
  'CommonStore'
)(observer(MerchantTypes));
