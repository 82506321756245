import React from 'react';
import PropTypes from 'prop-types';
import { Player } from 'react-cam-recorder';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';
import { Clear } from '@material-ui/icons';
import ThumbnailPlaceholder from './ThumbnailPlaceholder.jpeg';

const styles = theme => ({
  card: {
    maxWidth: 120,
    margin: theme.spacing.unit,
    position: 'relative'
  },
  media: {
    maxHeight: '100%',
    maxWidth: '100%'
  },
  modalContent: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    width: '50%',
    height: '50%',
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`
  },
  video: {
    width: '100%    !important',
    height: 'auto   !important'
  }
});

class Preview extends React.Component {
  state = {
    open: false
  };
  handleOpen = () => this.setState({ open: true });
  handleClose = () => this.setState({ open: false });
  handleClear = e => {
    e.preventDefault();
    const { onClear } = this.props;
    onClear && onClear();
  };
  render() {
    const {
      url,
      thumbnailUrl,
      overlay,
      frameSource,
      classes,
      onClear
    } = this.props;
    return (
      <React.Fragment>
        <ButtonBase component="span" className={classes.card}>
          {!thumbnailUrl && (
            <img
              src={ThumbnailPlaceholder}
              alt=""
              className={classes.media}
              onClick={this.handleOpen}
            />
          )}
          {thumbnailUrl && (
            <img
              src={`${thumbnailUrl}-decorated`}
              onError={e => (e.target.src = thumbnailUrl)}
              alt=""
              className={classes.media}
              onClick={this.handleOpen}
            />
          )}
        </ButtonBase>
        <Modal open={this.state.open && !!url} onClose={this.handleClose}>
          <div className={classes.modalContent}>
            <Player
              videoSrc={url}
              overlay={overlay}
              frameSource={frameSource}
            />
          </div>
        </Modal>
        {onClear && (
          <IconButton onClick={this.handleClear}>
            <Clear />
          </IconButton>
        )}
      </React.Fragment>
    );
  }
}

Preview.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  overlay: PropTypes.object,
  thumbnailUrl: PropTypes.string,
  frameSource: PropTypes.string,
  onClear: PropTypes.func
};

export default withStyles(styles)(Preview);
