import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import ReactPropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ImageCard from './ImageCard';

const styles = theme => ({
  root: {
    justifyContent: 'space-around',
    backgroundColor: theme.palette.background.paper
  }
});

const ImageList = ({ classes, GraphicAssetsStore }) => {
  const { assetUploader } = GraphicAssetsStore;

  return (
    <div className={classes.root}>
      <GridList cellHeight={'auto'} cols={5}>
        {Object.values(assetUploader.images).map(image => (
          <GridListTile key={image.url}>
            <ImageCard
              image={image}
              onDelete={() => GraphicAssetsStore.deleteSelectedImage(image.id)}
              onTitleChange={title =>
                GraphicAssetsStore.changeSelectedImageTitle(image.id, title)
              }
            />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
};

ImageList.propTypes = {
  GraphicAssetsStore: PropTypes.observableObject,
  classes: ReactPropTypes.object.isRequired
};

export default withStyles(styles)(
  inject('GraphicAssetsStore')(observer(ImageList))
);
