import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Thumbnail from './EditableImageThumbnail';

const styles = theme => ({
  wrapper: {
    width: 100
  },
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    padding: theme.spacing.unit
  },
  image: {
    maxWidth: 400,
    maxHeight: 400
  }
});

class EditableImage extends React.Component {
  state = {
    anchorEl: null
  };

  handlePopoverOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, accept, imageUrl, onChange, onDelete } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <React.Fragment>
        <div
          className={classes.wrapper}
          onMouseEnter={this.handlePopoverOpen}
          onMouseLeave={this.handlePopoverClose}
        >
          <Thumbnail
            accept={accept}
            imageUrl={imageUrl}
            onChange={onChange}
            onDelete={onDelete}
          />
        </div>
        {imageUrl && (
          <Popover
            className={classes.popover}
            classes={{
              paper: classes.paper
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            onClose={this.handlePopoverClose}
            disableRestoreFocus
          >
            <img src={imageUrl} className={classes.image} alt="" />
          </Popover>
        )}
      </React.Fragment>
    );
  }
}

EditableImage.propTypes = {
  classes: PropTypes.object.isRequired,
  accept: PropTypes.string,
  imageUrl: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func
};

export default withStyles(styles)(EditableImage);
