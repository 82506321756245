import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import EditableImage from 'Components/Image/EditableImage';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import ListItem from '@material-ui/core/ListItem/ListItem';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import {
  Add,
  Edit,
  Delete,
  Theaters,
  ExpandLess,
  ExpandMore,
  Remove
} from '@material-ui/icons';
import Button from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List/List';
import Collapse from '@material-ui/core/Collapse/Collapse';
import TextField from '@material-ui/core/TextField';
import { sortCategories } from './utils';

const styles = theme => ({
  toolbar: {
    paddingRight: theme.spacing.unit
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4
  }
});

function calculateItemsCount(category) {
  const { items, categories } = category;

  return Object.values(categories || {}).reduce(
    (result, nestedCategory) => result + calculateItemsCount(nestedCategory),
    items.length
  );
}

class Category extends React.Component {
  static propTypes = {
    CategoriesStore: MobxPropTypes.observableObject,
    classes: PropTypes.object.isRequired,
    category: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onManageScripts: PropTypes.func.isRequired,
    onImageChange: PropTypes.func.isRequired,
    onImageDelete: PropTypes.func.isRequired
  };
  state = {
    showToolbar: false
  };
  categoryPath() {
    const { category } = this.props;
    return category.path;
  }
  buttonsConfig = () => {
    const { onDelete, onEdit, onManageScripts } = this.props;
    const path = this.categoryPath();
    return [
      {
        title: 'Add category',
        icon: <Add />,
        onClick: () => onEdit(path, true)
      },
      {
        title: 'Edit category',
        icon: <Edit />,
        onClick: () => onEdit(path)
      },
      {
        title: 'Manage scripts',
        icon: <Theaters />,
        onClick: () => onManageScripts(path)
      },
      {
        title: 'Delete category',
        icon: <Delete />,
        onClick: () => onDelete(path)
      }
    ];
  };
  toggleOpen = () => {
    this.props.CategoriesStore.toggleCategory(this.categoryPath());
  };
  renderExpandButton() {
    if (this.getNestedCategories().length === 0) {
      return <Remove />;
    }
    return this.isOpen() ? (
      <ExpandLess onClick={this.toggleOpen} />
    ) : (
      <ExpandMore onClick={this.toggleOpen} />
    );
  }
  isOpen = () =>
    this.props.CategoriesStore.openedCategories[this.categoryPath()];
  getNestedCategories = () => {
    return Object.values(this.props.category.categories || {});
  };
  render() {
    const {
      classes,
      onDelete,
      onManageScripts,
      onEdit,
      onImageDelete,
      onImageChange
    } = this.props;
    const { path, title, imageUrl, position = null } = this.props.category;
    const categories = this.getNestedCategories();

    const updatePosition = newPosition => {
      if (position !== newPosition) {
        this.props.CategoriesStore.setCategoryPosition(path, newPosition);
      }
    };

    return (
      <React.Fragment>
        <ListItem
          onMouseEnter={() => this.setState({ showToolbar: true })}
          onMouseLeave={() => this.setState({ showToolbar: false })}
        >
          {this.renderExpandButton()}
          <EditableImage
            imageUrl={imageUrl}
            onChange={file => onImageChange(this.categoryPath(), file)}
            onDelete={() => onImageDelete(this.categoryPath())}
          />
          <ListItemText
            inset
            primary={title}
            secondary={`Scripts: ${calculateItemsCount(this.props.category)}`}
          />
          <TextField
            label="Position"
            placeholder="Position"
            defaultValue={position}
            type="number"
            onBlur={e => {
              updatePosition(e.target.value ? parseInt(e.target.value) : null);
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                updatePosition(
                  e.target.value ? parseInt(e.target.value) : null
                );
              }
            }}
          />
          <Fade in={this.state.showToolbar}>
            <Toolbar className={classes.toolbar}>
              {this.buttonsConfig().map(({ title, icon, onClick }) => (
                <Tooltip key={title} title={title}>
                  <Button
                    variant="fab"
                    color="primary"
                    size="small"
                    aria-label={title}
                    onClick={onClick}
                  >
                    {icon}
                  </Button>
                </Tooltip>
              ))}
            </Toolbar>
          </Fade>
        </ListItem>
        {categories.length > 0 && (
          <Collapse in={this.isOpen()} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {sortCategories(categories).map(nestedCategory => (
                <div key={nestedCategory.title} className={classes.nested}>
                  <ObservableCategory
                    category={nestedCategory}
                    classes={classes}
                    onEdit={onEdit}
                    onManageScripts={onManageScripts}
                    onDelete={onDelete}
                    onImageChange={onImageChange}
                    onImageDelete={onImageDelete}
                  />
                </div>
              ))}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    );
  }
}

const ObservableCategory = inject('CategoriesStore')(
  withStyles(styles)(observer(Category))
);

export default ObservableCategory;
