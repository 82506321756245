const moment = require('moment');

export const formatDate = dateTime => {
  const time = initializeMoment(dateTime).local();
  return time.format('MM/DD/Y');
};

export const formatDateTime = (dateTime, offsetMode = true) => {
  const time = initializeMoment(dateTime).local();
  return time.format('MM/DD/Y HH:mm');
};

const initializeMoment = dateTime => {
  return typeof dateTime === 'number'
    ? moment.unix(dateTime)
    : moment.utc(dateTime);
};
