import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  spacer: {
    flex: '1 1 auto'
  },
  customContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    textAlign: 'center'
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: '0 0 auto'
  },
  button: {
    margin: theme.spacing.unit
  }
});

const EnhancedTableToolbar = props => {
  const { classes, title, buttons, render } = props;

  const renderButtons = buttons => (
    <div>
      {buttons.map(({ title, icon, onClick }) => (
        <Tooltip
          key={title}
          title={title}
          className={classes.button}
          onClick={onClick}
        >
          <Fab color="primary" aria-label={title}>
            {icon}
          </Fab>
        </Tooltip>
      ))}
    </div>
  );

  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        {title && (
          <Typography variant="h6" id="tableTitle">
            {title}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      {render && <div className={classes.customContent}>{render()}</div>}
      <div className={classes.actions}>{renderButtons(buttons)}</div>
    </Toolbar>
  );
};

const ButtonProps = PropTypes.shape({
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
});

EnhancedTableToolbar.defaultProps = {
  buttons: []
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  render: PropTypes.func,
  buttons: PropTypes.arrayOf(ButtonProps)
};

export default withStyles(toolbarStyles)(EnhancedTableToolbar);
