import { action, computed, decorate, flow, observable, toJS } from 'mobx';
import agent from 'agent';
import CommonStore from 'Stores/CommonStore';
import { observableMapToObject } from './utils';

const editableAttributes = ['title', 'description'];

const isBusyKey = 'isBusy';

const DEFAULT_TAXONOMY_ID = '668cb8f4-cdaf-4c4c-8346-89f3121f7029';

class CategoriesStore {
  isLoading = false;
  isLoadingTaxonomies = false;
  tree;
  taxonomies = [];
  taxonomyId = DEFAULT_TAXONOMY_ID;
  categoryEditorRegistry = observable.map({
    [isBusyKey]: false,
    active: false,
    path: null,
    values: observable.map(),
    errors: observable.map()
  });
  scriptsManagerRegistry = observable.map({
    scripts: observable.map(),
    [isBusyKey]: false,
    path: null
  });
  openedCategoriesRegistry = observable.map();
  taxonomyFormRegistry = observable.map({
    [isBusyKey]: false,
    active: false
  });

  reset() {
    this.isLoading = false;
    this.isLoadingTaxonomies = false;
    this.tree = null;
    this.taxonomies = [];
    this.closeCategoryEditor();
  }

  loadTaxonomies = flow(function*() {
    if (this.isLoadingTaxonomies) {
      return;
    }
    this.isLoadingTaxonomies = true;
    try {
      this.taxonomies = yield agent.ScriptCategories.list();
    } catch (e) {
      CommonStore.handleError(e);
    }
    this.isLoadingTaxonomies = false;
  });

  cloneTaxonomy = flow(function*(taxonomyId) {
    try {
      const { id } = yield agent.ScriptCategories.cloneTaxonomy(taxonomyId);
      setTimeout(async () => {
        await this.loadTaxonomies();
        this.selectTaxonomy(id);
      }, 3000);
    } catch (e) {
      CommonStore.handleError(e);
    }
  });

  loadTree = flow(function*() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    try {
      const { tree } = yield agent.ScriptCategories.loadTree(this.taxonomyId);
      this.tree = tree;
    } catch (e) {
      CommonStore.handleError(e);
    }
    this.isLoading = false;
  });

  selectTaxonomy = taxonomyId => {
    if (taxonomyId === 'add') {
      this.taxonomyFormRegistry.set('active', true);
      return;
    }
    if (taxonomyId === 'clone') {
      CommonStore.confirmAction('Clone selected taxonomy?', () => {
        this.cloneTaxonomy(this.taxonomyId);
      });
      return;
    }
    if (taxonomyId !== this.taxonomyId) {
      this.taxonomyId = taxonomyId;
      this.tree = null;
      this.loadTree();
    }
  };

  closeTaxonomyForm = () => {
    this.taxonomyFormRegistry.clear();
    this.taxonomyFormRegistry.set('active', false);
  };

  editTaxonomy = () => {
    const taxonomy = this.taxonomies.find(({ id }) => id === this.taxonomyId);
    if (!taxonomy) {
      return;
    }
    this.taxonomyFormRegistry.clear();
    this.taxonomyFormRegistry.set('taxonomy', taxonomy);
    this.taxonomyFormRegistry.set('active', true);
  };

  deleteTaxonomy = () => {
    CommonStore.confirmAction('Delete selected taxonomy?', () =>
      this.deleteFromBackend()
    );
  };

  deleteFromBackend = flow(function*() {
    yield agent.ScriptCategories.deleteTaxonomy(this.taxonomyId);
    this.taxonomies = this.taxonomies.filter(
      taxonomy => taxonomy.id !== this.taxonomyId
    );
    const defaultTaxonomy = this.taxonomies[0];
    if (defaultTaxonomy) {
      this.selectTaxonomy(defaultTaxonomy.id);
    }
  });

  createTaxonomy = flow(function*(title) {
    if (this.taxonomyFormRegistry.get(isBusyKey)) {
      return;
    }
    this.taxonomyFormRegistry.set(isBusyKey, true);
    try {
      const { id: taxonomyId } = yield agent.ScriptCategories.createTaxonomy(
        title
      );
      this.taxonomyId = taxonomyId;
      this.loadTaxonomies();
      this.loadTree();
    } catch (e) {
      CommonStore.handleError(e);
    }
    this.closeTaxonomyForm();
  });

  renameTaxonomy = flow(function*(id, title) {
    if (this.taxonomyFormRegistry.get(isBusyKey)) {
      return;
    }
    this.taxonomyFormRegistry.set(isBusyKey, true);
    try {
      yield agent.ScriptCategories.renameTaxonomy(id, title);
      this.taxonomies = this.taxonomies.map(taxonomy =>
        taxonomy.id === id ? { ...taxonomy, title } : taxonomy
      );
    } catch (e) {
      CommonStore.handleError(e);
    }
    this.closeTaxonomyForm();
  });

  submitCategoryChanges = flow(function*() {
    if (this.categoryEditorRegistry.get(isBusyKey)) {
      return;
    }
    this.categoryEditorRegistry.set(isBusyKey, true);
    try {
      const addCategory = this.categoryEditorRegistry.get('addCategory');
      const { path, values } = this.categoryEditor;
      const { title, description } = values;
      if (path && !addCategory) {
        yield agent.ScriptCategories.update(
          this.taxonomyId,
          path,
          title,
          description
        );
      } else {
        yield agent.ScriptCategories.add(
          this.taxonomyId,
          title,
          path,
          description
        );
      }
      this.loadTree();
      if (addCategory) {
        this.openedCategoriesRegistry.set(path, true);
      }
      this.closeCategoryEditor();
    } catch (err) {
      const { validationErrors = {} } = err.response.body;
      this.setEditorErrors(validationErrors);
      if (Object.keys(validationErrors).length === 0) {
        CommonStore.handleError(err);
      }
    }
    this.categoryEditorRegistry.set(isBusyKey, false);
  });

  addCategoryImage = flow(function*(path, image) {
    try {
      const { cdnUrl } = yield agent.S3.uploadToDirectory('image', image);
      yield agent.ScriptCategories.addImage(this.taxonomyId, path, cdnUrl);
      this.loadTree();
    } catch (err) {
      CommonStore.handleError(err);
    }
  });

  deleteCategoryImage = flow(function*(path) {
    try {
      yield agent.ScriptCategories.addImage(this.taxonomyId, path, null);
      this.loadTree();
    } catch (err) {
      CommonStore.handleError(err);
    }
  });

  deleteCategory = flow(function*(path) {
    try {
      yield agent.ScriptCategories.delete(this.taxonomyId, path);
      this.loadTree();
    } catch (err) {
      CommonStore.handleError(err);
    }
  });

  setCategoryPosition = flow(function*(path, position) {
    try {
      yield agent.ScriptCategories.update(
        this.taxonomyId,
        path,
        null,
        null,
        position
      );
      this.loadTree();
    } catch (err) {
      CommonStore.handleError(err);
    }
  });

  addScriptToCategory = flow(function*(scriptId) {
    try {
      const { path } = this.scriptsManager;
      if (!path) {
        return;
      }
      yield agent.ScriptCategories.addScript(this.taxonomyId, path, scriptId);
      this.getCategory(path).items.push(scriptId);
    } catch (err) {
      CommonStore.handleError(err);
    }
  });

  deleteScriptFromCategory = flow(function*(scriptId) {
    try {
      const { path } = this.scriptsManager;
      if (!path) {
        return;
      }
      yield agent.ScriptCategories.deleteScript(
        this.taxonomyId,
        path,
        scriptId
      );
      const category = this.getCategory(path);
      category.items = category.items.filter(id => scriptId !== id);
    } catch (err) {
      CommonStore.handleError(err);
    }
  });

  get categoryEditor() {
    return {
      [isBusyKey]: this.categoryEditorRegistry.get(isBusyKey),
      active: this.categoryEditorRegistry.get('active'),
      path: this.categoryEditorRegistry.get('path'),
      values: observableMapToObject(this.categoryEditorRegistry.get('values')),
      errors: observableMapToObject(this.categoryEditorRegistry.get('errors'))
    };
  }

  get taxonomyForm() {
    return {
      [isBusyKey]: this.taxonomyFormRegistry.get(isBusyKey),
      active: this.taxonomyFormRegistry.get('active'),
      taxonomy: this.taxonomyFormRegistry.get('taxonomy')
    };
  }

  get scriptsManager() {
    const path = this.scriptsManagerRegistry.get('path');
    return {
      [isBusyKey]: this.scriptsManagerRegistry.get(isBusyKey),
      category: toJS(this.getCategory(path)),
      scripts: observableMapToObject(
        this.scriptsManagerRegistry.get('scripts')
      ),
      path
    };
  }

  get openedCategories() {
    return observableMapToObject(this.openedCategoriesRegistry);
  }

  getCategory(path) {
    if (!path) {
      return null;
    }

    const pathParts = JSON.parse(path);
    let categories = this.tree ? this.tree.categories : {};
    let category = null;
    pathParts.forEach(part => {
      category = categories[part];
      categories = category ? category['categories'] : {};
    });

    return category;
  }

  setEditorErrors(errors = {}) {
    const editorErrors = this.categoryEditorRegistry.get('errors');
    editorErrors.clear();
    Object.keys(errors).map(attribute =>
      editorErrors.set(attribute, errors[attribute])
    );
  }

  startCategoryEditor(path, addCategory) {
    this.categoryEditorRegistry.set('active', true);
    this.categoryEditorRegistry.set('path', path);
    this.categoryEditorRegistry.set('addCategory', !!addCategory);
    const category = path && !addCategory ? this.getCategory(path) : {};
    editableAttributes.forEach(attribute =>
      this.registerCategoryChange(attribute, category[attribute])
    );
    this.setEditorErrors();
  }

  registerCategoryChange(attribute, value) {
    if (!this.categoryEditorRegistry.get('active')) {
      return;
    }
    this.categoryEditorRegistry.get('values').set(attribute, value);
  }

  closeCategoryEditor() {
    this.categoryEditorRegistry.set('active', false);
  }

  startScriptsManager(path) {
    this.scriptsManagerRegistry.set('path', path);
    this.loadScripts();
  }

  toggleCategory(path) {
    if (this.openedCategoriesRegistry.get(path)) {
      this.openedCategoriesRegistry.delete(path);
      return;
    }

    this.openedCategoriesRegistry.set(path, true);
  }

  /** @todo quick and dirty solution to search scripts by id and slug */
  loadScripts = flow(function*() {
    if (this.scriptsManagerRegistry.get(isBusyKey)) {
      return;
    }
    this.scriptsManagerRegistry.set(isBusyKey, true);
    try {
      const { data } = yield agent.MessageTemplates.all(0, 1000);
      const scripts = this.scriptsManagerRegistry.get('scripts');
      scripts.clear();
      data.forEach(script => scripts.set(script.id, script));
    } catch (e) {
      CommonStore.handleError(e);
    }
    this.scriptsManagerRegistry.set(isBusyKey, false);
  });

  closeScriptsManager() {
    this.scriptsManagerRegistry.delete('path');
  }
}

const MobxCategoriesStore = decorate(CategoriesStore, {
  isLoading: observable,
  isLoadingTaxonomies: observable,
  taxonomies: observable,
  tree: observable,
  taxonomyId: observable,

  categoryEditor: computed,
  openedCategories: computed,
  scriptsManager: computed,
  taxonomyForm: computed,

  deleteCategory: action,
  deleteCategoryImage: action,
  closeCategoryEditor: action,
  closeScriptsManager: action,
  loadTree: action,
  registerCategoryChange: action,
  reset: action,
  startCategoryEditor: action,
  startScriptsManager: action,
  toggleCategory: action,
  submitCategoryChanges: action,
  selectTaxonomy: action,
  closeTaxonomyForm: action,
  editTaxonomy: action
});

export default new MobxCategoriesStore();
