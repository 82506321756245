import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import green from '@material-ui/core/colors/green';
import ProgressButton from 'Components/Progress/ProgressButton';

const styles = theme => ({
  appBar: {
    position: 'relative'
  },
  flex: {
    flex: 1
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class FormDialog extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func,
    handleCancel: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    isBusy: PropTypes.bool,
    children: PropTypes.node.isRequired
  };
  render() {
    const {
      title,
      isBusy,
      classes,
      children,
      handleSubmit,
      handleCancel
    } = this.props;
    return (
      <Dialog
        open={true}
        fullScreen
        onClose={this.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={handleCancel}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              {title}
            </Typography>
            {handleSubmit && (
              <ProgressButton
                color="inherit"
                onClick={handleSubmit}
                isBusy={isBusy}
              >
                save
              </ProgressButton>
            )}
          </Toolbar>
        </AppBar>
        <DialogContent>
          {children}
          <Toolbar className={classes.appBar}>
            <div className={classes.flex} />
            {handleSubmit && (
              <ProgressButton
                color="inherit"
                onClick={handleSubmit}
                isBusy={isBusy}
              >
                save
              </ProgressButton>
            )}
          </Toolbar>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(FormDialog);
