// @flow

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from 'Components/PrivateRoute';
import MagicLink from 'Components/MagicLink';
import { wrap } from 'Components/Layout';
import Login from 'Pages/Login';
import Dashboard from 'Pages/Dashboard';
import Categories from 'Pages/Categories';
import Library from 'Pages/Library';
import GraphicAssets from 'Pages/GraphicAssets';
import BillingPlans from 'Pages/BillingPlans';
import Merchants from 'Pages/Merchants';
import MerchantTypes from 'Pages/MerchantTypes';
import Reports from 'Pages/Reports';
import RecordVideo from 'Pages/RecordVideo';
import Pricing from 'Pages/Pricing';
import VideoFrames from './Pages/VideoFrames';
import Videos from './Pages/Videos';
import DeliveryFailures from './Pages/DeliveryFailures';

export default class App extends React.Component {
  render() {
    return (
      <Switch>
        <PrivateRoute path="/" exact={true} component={wrap(Dashboard)} />
        <PrivateRoute path="/categories" component={wrap(Categories)} />
        <PrivateRoute path="/library" component={wrap(Library)} />
        <PrivateRoute path="/record-video" component={wrap(RecordVideo)} />
        <PrivateRoute path="/graphic-assets" component={wrap(GraphicAssets)} />
        <PrivateRoute path="/billing-plans" component={wrap(BillingPlans)} />
        <PrivateRoute path="/merchants" component={wrap(Merchants)} />
        <PrivateRoute path="/merchant-types" component={wrap(MerchantTypes)} />
        <PrivateRoute path="/videos" component={wrap(Videos)} />
        <PrivateRoute path="/video-frames" component={wrap(VideoFrames)} />
        <PrivateRoute path="/reports" component={wrap(Reports)} />
        <PrivateRoute path="/pricing" component={wrap(Pricing)} />
        <PrivateRoute
          path="/delivery-failures"
          component={wrap(DeliveryFailures)}
        />
        <Route path="/login" component={Login} />
        <MagicLink path="/magic-link" />
      </Switch>
    );
  }
}
