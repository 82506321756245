import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import Table from 'Components/Table/Table';
import Loader from 'Components/Progress/Loader';
import { Add, Edit, Delete, ToggleOff, ToggleOn } from '@material-ui/icons';
import FrameForm from './FrameForm';
import Typography from '@material-ui/core/Typography';

class VideoFrames extends React.Component {
  static propTypes = {
    CommonStore: PropTypes.observableObject,
    VideoFramesStore: PropTypes.observableObject,
  };
  componentDidMount() {
    const { CommonStore, VideoFramesStore } = this.props;
    CommonStore.setTitle('Video frames');
    VideoFramesStore.reset();
    VideoFramesStore.loadFrames();
    VideoFramesStore.loadMerchantTypes();
  }
  handleDelete = (frame) => {
    this.props.CommonStore.confirmAction('Delete frame?', () => {
      const { VideoFramesStore } = this.props;
      VideoFramesStore.deleteFrame(frame.id);
    });
  };
  handleChangePage = (event, page) => {
    this.props.VideoFramesStore.setPage(page);
  };

  handleChangeRowsPerPage = (event) => {
    this.props.VideoFramesStore.setRowsPerPage(event.target.value);
  };

  setSort = (attribute) => {
    this.props.VideoFramesStore.setSort(attribute);
  };

  applyFilter = (attribute, value) => {
    this.props.VideoFramesStore.applyFilter(attribute, value);
  };
  render() {
    const {
      frames,
      isLoading,
      rowsPerPage,
      totalFramesCount,
      page,
      order,
      orderBy,
      filters,
      frameEditor,
    } = this.props.VideoFramesStore;

    if (frameEditor.active) {
      return <FrameForm />;
    }

    return (
      <Loader isBusy={isLoading && frames.length === 0}>
        <Table
          data={{
            columns: this.getColumns(),
            rows: frames,
            emptyDataText: 'There are no video frames yet',
            showEmptyRows: true,
          }}
          toolbar={{
            title: 'Video frames',
            buttons: [
              {
                title: 'Add',
                icon: <Add />,
                onClick: () => this.props.VideoFramesStore.addFrame(),
              },
            ],
          }}
          rowToolbar={[
            {
              title: 'Activate',
              icon: <ToggleOff />,
              isVisible: (frame) => !frame.available,
              onClick: ({ id }) =>
                this.props.VideoFramesStore.activateFrame(id),
            },
            {
              title: 'Deactivate',
              icon: <ToggleOn />,
              isVisible: (frame) => frame.available,
              onClick: ({ id }) =>
                this.props.VideoFramesStore.deactivateFrame(id),
            },
            {
              title: 'Update frame',
              icon: <Edit />,
              onClick: (frame) =>
                this.props.VideoFramesStore.editFrame(frame.id),
            },
            {
              title: 'Delete frame',
              icon: <Delete />,
              onClick: this.handleDelete,
            },
          ]}
          pagination={{
            totalRowsCount: totalFramesCount,
            currentPage: page,
            handleChangePage: this.handleChangePage,
            handleChangeRowsPerPage: this.handleChangeRowsPerPage,
            rowsPerPage,
          }}
          order={order}
          orderBy={orderBy}
          sortHandler={this.setSort}
          applyFilter={this.applyFilter}
          filters={filters}
        />
      </Loader>
    );
  }
  getColumns() {
    const { merchantTypes } = this.props.VideoFramesStore;

    return [
      {
        title: '',
        key: 'imageSource',
        type: 'image',
        width: '200px',
      },
      {
        title: 'Title',
        key: 'title',
        sortable: true,
        filterType: 'text',
        filterKey: 'search',
        width: '400px',
      },
      {
        title: 'For user type',
        type: 'custom',
        key: 'merchantTypes',
        filterData: Object.values(merchantTypes.types).map((type) => ({
          value: type.id,
          label: type.name,
        })),
        width: '200px',
        filterKey: 'merchantTypeId',
        render: (frame) => {
          const merchantTypes = frame.merchantTypes || [];
          return (
            <section>
              {merchantTypes.map((merchantType) => {
                return (
                  <Typography component="p" key={merchantType.id}>
                    {merchantType.name}
                  </Typography>
                );
              })}
            </section>
          );
        },
      },
      {
        title: 'Available',
        key: 'available',
        type: 'flag',
        filterType: 'flag',
        filterKey: 'available',
        width: '100px',
      },
      {
        title: 'Created at',
        key: 'createdAt',
        type: 'date-time',
        sortable: true,
        width: '200px',
      },
    ].filter((val) => !!val);
  }
}
export default inject('CommonStore', 'VideoFramesStore')(observer(VideoFrames));
