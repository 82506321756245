import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import TextField from '@material-ui/core/TextField';
import FormDialog from 'Components/Dialog/FormDialog';
import DataAutosuggest from 'Components/Autosuggest/DataAutosuggest';
import EditableImage from 'Components/Image/EditableImage';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Loader from 'Components/Progress/Loader';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

class ScriptForm extends React.Component {
  static propTypes = {
    ScriptsStore: PropTypes.observableObject
  };

  state = { selectedTaxonomy: '_' };

  handleFormSubmit = e => {
    e.preventDefault();
    this.submit();
  };
  submit = () => {
    this.props.ScriptsStore.submitScriptChanges();
  };
  cancel = () => {
    this.props.ScriptsStore.closeScriptEditor();
  };
  makeChangeHandler = name => {
    return e => {
      this.props.ScriptsStore.registerScriptChange(name, e.target.value);
    };
  };
  render() {
    const {
      scriptId,
      isBusy,
      values,
      suggestedCategories,
      linkedCategories,
      errors = {}
    } = this.props.ScriptsStore.scriptEditor;
    const { selectedTaxonomy } = this.state;

    const title = scriptId ? 'Update message' : 'Create new message';
    return (
      <Loader isBusy={!linkedCategories || !suggestedCategories}>
        <FormDialog
          title={title}
          isBusy={isBusy}
          handleCancel={this.cancel}
          handleSubmit={this.submit}
        >
          <form onSubmit={this.handleFormSubmit}>
            <TextField
              label="Title"
              placeholder="Script title"
              value={values.title || ''}
              error={!!errors.title}
              helperText={errors.title}
              fullWidth
              margin="normal"
              onChange={this.makeChangeHandler('title')}
            />
            <TextField
              label="Description"
              placeholder="Description"
              value={values.description || ''}
              error={!!errors.description}
              helperText={errors.description}
              multiline
              fullWidth
              margin="normal"
              onChange={this.makeChangeHandler('description')}
            />
            <TextField
              label="Script text"
              placeholder="Script text"
              value={values.scriptText || ''}
              error={!!errors.scriptText}
              helperText={errors.scriptText}
              multiline
              fullWidth
              margin="normal"
              onChange={this.makeChangeHandler('scriptText')}
            />
            <TextField
              label="Message text"
              placeholder="Message text"
              value={values.messageText || ''}
              error={!!errors.messageText}
              helperText={errors.messageText}
              multiline
              fullWidth
              margin="normal"
              onChange={this.makeChangeHandler('messageText')}
            />
            <TextField
              label="Landing page text"
              placeholder="Landing page text"
              value={values.landingPageText || ''}
              error={!!errors.landingPageText}
              helperText={errors.landingPageText}
              multiline
              fullWidth
              margin="normal"
              onChange={this.makeChangeHandler('landingPageText')}
            />
            <div>
              <InputLabel component="legend">Giphy</InputLabel>
              <EditableImage
                imageUrl={
                  values.imageUrl instanceof Object ? null : values.imageUrl
                }
                onChange={file =>
                  this.props.ScriptsStore.registerScriptChange('imageUrl', file)
                }
                onDelete={() =>
                  this.props.ScriptsStore.registerScriptChange('imageUrl', '')
                }
              />
              {values.imageUrl && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.isLogoPlaceholder}
                      onChange={() =>
                        this.props.ScriptsStore.registerScriptChange(
                          'isLogoPlaceholder',
                          !values.isLogoPlaceholder
                        )
                      }
                    />
                  }
                  label="Is logo placeholder"
                />
              )}
            </div>
            <FormControl fullWidth style={{ marginBottom: 10 }}>
              <InputLabel>Category group:</InputLabel>
              <Select
                fullWidth
                value={selectedTaxonomy}
                onChange={e =>
                  this.setState({ selectedTaxonomy: e.target.value })
                }
              >
                <MenuItem value="_">
                  <em>All</em>
                </MenuItem>
                {suggestedCategories.map(({ id, label }) => (
                  <MenuItem value={id} key={id}>
                    <em>{label}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <DataAutosuggest
              suggestions={
                selectedTaxonomy !== '_'
                  ? suggestedCategories.filter(
                      ({ id }) => id === selectedTaxonomy
                    )
                  : suggestedCategories
              }
              placeholder="Select categories"
              onChange={values =>
                this.props.ScriptsStore.changeLinkedCategoriesSet(values)
              }
              value={linkedCategories}
              isMulti
            />
          </form>
        </FormDialog>
      </Loader>
    );
  }
}

export default inject('ScriptsStore')(observer(ScriptForm));
