import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import SimpleLineChart from 'Components/Chart/SimpleLineChart';
import Typography from '@material-ui/core/Typography/Typography';

class Categories extends React.Component {
  static propTypes = {
    CommonStore: PropTypes.observableObject
  };
  componentDidMount() {
    this.props.CommonStore.setTitle('Reports');
  }
  render() {
    return (
      <React.Fragment>
        <Typography variant="h4" gutterBottom>
          Reports
        </Typography>
        <div>
          <SimpleLineChart />
        </div>
      </React.Fragment>
    );
  }
}
export default inject('CommonStore')(observer(Categories));
