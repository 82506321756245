import { action, computed, decorate, flow, observable, reaction } from 'mobx';
import agent from 'agent';
import CommonStore from 'Stores/CommonStore';
import { observableMapToObject } from './utils';

const isBusyKey = 'isBusy';

class VideoFramesStore {
  isLoading = false;
  page = 0;
  rowsPerPage = 10;
  order = 'desc';
  orderBy = 'createdAt';
  totalFramesCount = 0;
  framesRegistry = observable.map();
  filtersRegistry = observable.map();
  editorRegistry = observable.map({
    [isBusyKey]: false,
    active: false,
    frameId: null,
    values: observable.map(),
    errors: observable.map()
  });
  merchantTypesRegistry = observable.map({
    [isBusyKey]: false,
    merchantTypes: observable.map()
  });

  constructor() {
    reaction(
      () => this.page,
      () => this.loadFrames(),
      { delay: 500 }
    );
    reaction(
      () => this.rowsPerPage,
      () => this.loadFrames(),
      { delay: 500 }
    );
    reaction(
      () => this.orderBy,
      () => this.loadFrames(),
      { delay: 500 }
    );
    reaction(
      () => this.order,
      () => this.loadFrames(),
      { delay: 500 }
    );
    reaction(
      () => this.filtersRegistry.toJSON(),
      () => this.loadFrames(),
      {
        delay: 500
      }
    );
  }

  setPage(page) {
    this.page = page;
  }

  setRowsPerPage(rowsPerPage) {
    this.rowsPerPage = rowsPerPage;
  }

  setSort(attribute) {
    if (this.orderBy !== attribute) {
      this.orderBy = attribute;
      this.order = 'asc';
    }

    this.order = this.order === 'asc' ? 'desc' : 'asc';
  }

  applyFilter = (attribute, value) => {
    value !== '' && value !== undefined
      ? this.filtersRegistry.set(attribute, value)
      : this.filtersRegistry.delete(attribute);
  };

  reset() {
    this.isLoading = false;
    this.page = 0;
    this.order = 'desc';
    this.orderBy = 'createdAt';
    this.rowsPerPage = 10;
    this.totalFramesCount = 0;
    this.framesRegistry.clear();
    this.filtersRegistry.clear();
  }

  /** @todo quick and dirty solution to search merhant types by id and slug */
  loadMerchantTypes = flow(function*() {
    if (this.merchantTypesRegistry.get(isBusyKey)) {
      return;
    }
    this.merchantTypesRegistry.set(isBusyKey, true);
    try {
      const data = yield agent.MerchantTypes.list();
      const merchantTypes = this.merchantTypesRegistry.get('merchantTypes');
      merchantTypes.clear();
      data.forEach(merchantType =>
        merchantTypes.set(merchantType.id, merchantType)
      );
    } catch (e) {
      CommonStore.handleError(e);
    }
    this.merchantTypesRegistry.set(isBusyKey, false);
  });

  loadFrames = flow(function*() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    try {
      const { data, count } = yield agent.VideoFrame.list(
        this.page,
        this.rowsPerPage,
        this.orderBy,
        this.order,
        this.filters
      );
      this.framesRegistry.clear();
      data.forEach(frame => this.framesRegistry.set(frame.id, frame));
      this.totalFramesCount = count;
    } catch (e) {
      CommonStore.handleError(e);
    }
    this.isLoading = false;
  });

  get frames() {
    return Object.values(observableMapToObject(this.framesRegistry));
  }

  get merchantTypes() {
    return {
      [isBusyKey]: this.merchantTypesRegistry.get(isBusyKey),
      types: observableMapToObject(
        this.merchantTypesRegistry.get('merchantTypes')
      )
    };
  }

  get filters() {
    return observableMapToObject(this.filtersRegistry);
  }

  get frameEditor() {
    return {
      [isBusyKey]: this.editorRegistry.get(isBusyKey),
      active: this.editorRegistry.get('active'),
      frameId: this.editorRegistry.get('frameId'),
      values: observableMapToObject(this.editorRegistry.get('values')),
      errors: observableMapToObject(this.editorRegistry.get('errors'))
    };
  }

  addFrame = () => this.startEditor();

  editFrame = id => this.startEditor(id);

  startEditor(id) {
    this.editorRegistry.set('active', true);
    this.editorRegistry.set('frameId', id || null);
    const frame = id ? this.framesRegistry.get(id) : null;
    if (frame) {
      this.registerFrameChange('title', frame.title);
      this.registerFrameChange('sourceUrl', frame.imageSource);
      this.registerFrameChange(
        'merchantTypes',
        (frame.merchantTypes || []).map(({ id, name }) => ({
          value: id,
          label: name
        }))
      );
    }
  }

  registerFrameChange = (attribute, value) => {
    if (!this.editorRegistry.get('active')) {
      return;
    }
    this.editorRegistry.get('values').set(attribute, value);
  };

  submitFrameChanges = flow(function*() {
    if (this.editorRegistry.get(isBusyKey)) {
      return;
    }
    this.editorRegistry.set(isBusyKey, true);
    try {
      const { frameId, values } = this.frameEditor;
      const { title, sourceUrl, file } = values;
      const merchantTypes = values.merchantTypes.map(({ value }) => value);
      let newSourceUrl = sourceUrl;
      if (file) {
        const { cdnUrl } = yield agent.S3.uploadToDirectory('frame', file);
        newSourceUrl = cdnUrl;
      }
      if (frameId) {
        yield agent.VideoFrame.update(
          frameId,
          title,
          newSourceUrl,
          merchantTypes
        );
      } else {
        yield agent.VideoFrame.create(title, newSourceUrl, merchantTypes);
      }

      setTimeout(() => {
        this.loadFrames();
        this.closeEditor();
      }, 2000);
    } catch (err) {
      const { validationErrors } = err.response.body;
      this.setEditorErrors(validationErrors);
      if (!validationErrors) {
        CommonStore.handleError(err);
      }
      this.editorRegistry.set(isBusyKey, false);
    }
  });

  setEditorErrors(errors = {}) {
    const editorErrors = this.editorRegistry.get('errors');
    editorErrors.clear();
    Object.keys(errors).map(attribute =>
      editorErrors.set(attribute, errors[attribute])
    );
  }

  closeEditor = () => {
    this.editorRegistry.set(isBusyKey, false);
    this.editorRegistry.set('active', false);
  };

  deleteFrame = flow(function*(id) {
    yield agent.VideoFrame.delete(id);
    this.loadFrames();
  });

  activateFrame = flow(function*(id) {
    yield agent.VideoFrame.changeAvailability(id, true);
    this.loadFrames();
  });

  deactivateFrame = flow(function*(id) {
    yield agent.VideoFrame.changeAvailability(id, false);
    this.loadFrames();
  });
}

const MobxVideoFramesStore = decorate(VideoFramesStore, {
  isLoading: observable,
  page: observable,
  rowsPerPage: observable,
  order: observable,
  orderBy: observable,
  framesRegistry: observable,
  totalFramesCount: observable,

  frames: computed,
  merchantTypes: computed,
  filters: computed,
  frameEditor: computed,

  loadFrames: action,
  loadMerchantTypes: action,
  setPage: action,
  setRowsPerPage: action,
  setSort: action,
  applyFilter: action,
  reset: action,
  addFrame: action,
  editFrame: action,
  registerFrameChange: action,
  submitFrameChanges: action,
  closeEditor: action,
  deleteFrame: action,
  activateFrame: action,
  deactivateFrame: action
});

export default new MobxVideoFramesStore();
