import { action, computed, decorate, flow, observable, reaction } from 'mobx';
import agent from '../agent';
import CommonStore from './CommonStore';
import { observableMapToObject } from './utils';

class DeliveryFailuresStore {
  isLoading = false;
  page = 0;
  rowsPerPage = 10;
  totalFailuresCount = 0;
  failuresRegistry = observable.map();

  constructor() {
    reaction(
      () => this.page,
      () => this.load(),
      { delay: 100 }
    );
    reaction(
      () => this.rowsPerPage,
      () => this.load(),
      { delay: 100 }
    );
  }

  reset() {
    this.isLoading = false;
    this.failuresRegistry.clear();
  }

  setPage(page) {
    if (!this.isLoading && this.page !== page) {
      this.page = page;
    }
  }

  setRowsPerPage(rowsPerPage) {
    if (!this.isLoading && this.rowsPerPage !== rowsPerPage) {
      this.rowsPerPage = rowsPerPage;
    }
  }

  load = flow(function*() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    try {
      const { data, count } = yield agent.DeliveryFailures.list(
        this.page,
        this.rowsPerPage
      );
      this.totalFailuresCount = count;
      this.failuresRegistry.clear();
      data.forEach(failure =>
        this.failuresRegistry.set(failure.phone, failure)
      );
    } catch (e) {
      CommonStore.handleError(e);
    }
    this.isLoading = false;
  });

  get failures() {
    return Object.values(observableMapToObject(this.failuresRegistry));
  }
}

const MobxDeliveryFailuresStore = decorate(DeliveryFailuresStore, {
  isLoading: observable,
  page: observable,
  rowsPerPage: observable,
  totalFailuresCount: observable,

  failures: computed,

  load: action,
  reset: action,
  setPage: action,
  setRowsPerPage: action
});

export default new MobxDeliveryFailuresStore();
