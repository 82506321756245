import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

export default class FormDialog extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  };
  render() {
    const { children } = this.props;
    return (
      <Dialog
        open={true}
        fullScreen
        onClose={this.handleClose}
        TransitionComponent={Transition}
      >
        <DialogContent>{children}</DialogContent>
      </Dialog>
    );
  }
}
