import React from 'react';
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from './TablePaginationClasses';

class Pagination extends React.Component {
  static propTypes = {
    totalRowsCount: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    handleChangePage: PropTypes.func.isRequired,
    handleChangeRowsPerPage: PropTypes.func.isRequired
  };
  static defaultProps = {
    rowsPerPage: 10,
    page: 1
  };
  render() {
    const { totalRowsCount, rowsPerPage, currentPage } = this.props;
    return (
      <TablePagination
        count={totalRowsCount}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onChangePage={this.props.handleChangePage}
        onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    );
  }
}

export default Pagination;
