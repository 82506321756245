import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = theme => ({
  card: {
    maxWidth: 80,
    margin: theme.spacing.unit,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  media: {
    maxHeight: 80,
    maxWidth: 80
  },
  deleteIcon: {
    height: 19,
    width: 19,
    position: 'absolute',
    top: theme.spacing.unit / 2,
    right: theme.spacing.unit / 2
  }
});

const IMAGE_PLACEHOLDER = 'https://via.placeholder.com/80x80?text=Click me';

class Thumbnail extends React.Component {
  constructor(props) {
    super(props);
    this.state = { imageUrl: this.props.imageUrl };
  }
  onDeleteHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onDelete();
    this.setState({ imageUrl: null });
  };
  fileSelectionHandler = e => {
    if (e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = event => {
        this.setState({ imageUrl: event.target.result });
        const { onChange } = this.props;
        onChange && onChange(file);
      };
      reader.readAsDataURL(file);
    }
  };
  render() {
    const { classes, accept, onDelete, onChange } = this.props;
    const { imageUrl } = this.state;

    if (!onChange) {
      return (
        <ButtonBase component="span" className={classes.card}>
          <img
            className={classes.media}
            src={imageUrl || IMAGE_PLACEHOLDER}
            alt=""
          />
        </ButtonBase>
      );
    }

    return (
      <React.Fragment>
        <label>
          <input
            accept={accept || 'image/*'}
            className={classes.input}
            style={{ display: 'none' }}
            multiple
            type="file"
            onChange={this.fileSelectionHandler}
          />
          <ButtonBase component="span" className={classes.card}>
            <img
              className={classes.media}
              src={imageUrl || IMAGE_PLACEHOLDER}
              alt=""
            />
            {onDelete && this.props.imageUrl && (
              <DeleteIcon
                className={classes.deleteIcon}
                onClick={this.onDeleteHandler}
              />
            )}
          </ButtonBase>
        </label>
      </React.Fragment>
    );
  }
}

Thumbnail.propTypes = {
  classes: PropTypes.object.isRequired,
  accept: PropTypes.string,
  imageUrl: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func
};

export default withStyles(styles)(Thumbnail);
