import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import Button from '@material-ui/core/Button';
import FormDialog from 'Components/Dialog/FormDialog';
import ChipsArray from 'Components/Chips/Array';
import Loader from 'Components/Progress/Loader';
import Spacer from 'Components/Misc/Spacer';
import DataAutosuggest from 'Components/Autosuggest/DataAutosuggest';

class ScriptsManager extends React.Component {
  static propTypes = {
    CategoriesStore: PropTypes.observableObject,
    CommonStore: PropTypes.observableObject
  };

  state = {};

  handleScriptDelete = id => {
    this.props.CommonStore.confirmAction('Delete script from category?', () => {
      const { CategoriesStore } = this.props;
      CategoriesStore.deleteScriptFromCategory(id);
    });
  };

  handleScriptSelection = value => this.setState({ selectedScript: value });

  handleAddScript = () => {
    const { selectedScript } = this.state;
    if (!selectedScript) {
      return;
    }

    this.props.CategoriesStore.addScriptToCategory(selectedScript.value);
    this.setState({ selectedScript: null });
  };

  render() {
    const { CategoriesStore } = this.props;
    const { isBusy, scripts, category } = CategoriesStore.scriptsManager;
    const title = 'Manage category scripts';
    const items =
      scripts && category
        ? category.items.map(id => ({
            id,
            title: (scripts[id] || {}).title || ''
          }))
        : [];
    const autosuggestOptions = scripts
      ? Object.values(scripts)
          .map(script => {
            if (category.items.indexOf(script.id) !== -1) {
              return null;
            }
            return {
              value: script.id,
              label: script.title
            };
          })
          .filter(val => !!val)
      : [];
    const { selectedScript } = this.state;
    return (
      <FormDialog
        title={title}
        isBusy={isBusy}
        handleCancel={() => CategoriesStore.closeScriptsManager()}
        handleSubmit={this.submit}
      >
        <Spacer />
        <Loader isBusy={isBusy}>
          {items.length > 0 && (
            <React.Fragment>
              <ChipsArray
                title="Scripts in category"
                items={items}
                onDelete={this.handleScriptDelete}
              />
              <Spacer />
            </React.Fragment>
          )}
          <DataAutosuggest
            suggestions={autosuggestOptions}
            placeholder="Select script to add"
            onChange={this.handleScriptSelection}
            value={selectedScript}
          />
          <Spacer />
          {selectedScript && (
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleAddScript}
            >
              Add script
            </Button>
          )}
        </Loader>
      </FormDialog>
    );
  }
}

export default inject(
  'CategoriesStore',
  'CommonStore'
)(observer(ScriptsManager));
