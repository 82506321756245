import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import FullScreenDialog from 'Components/Dialog/FullScreenDialog';
import { VideoSettings } from 'react-cam-recorder';

class VideoSettingsForm extends React.Component {
  static propTypes = {
    ScriptsStore: PropTypes.observableObject
  };
  submit = async ({ videoTitle, videoCaption, thumbnail }) => {
    await this.props.ScriptsStore.updateScriptVideoSettings(
      videoTitle,
      videoCaption,
      thumbnail
    );
    setTimeout(this.close, 100);
  };
  close = () => {
    this.props.ScriptsStore.closeScriptVideoSettings();
  };
  makeChangeHandler = name => {
    return e => {
      this.props.ScriptsStore.registerScriptChange(name, e.target.value);
    };
  };
  render() {
    const { script } = this.props.ScriptsStore.scriptVideoSettings;
    const timestamp = new Date().getTime();
    return (
      <FullScreenDialog style={{ padding: 0 }}>
        <VideoSettings
          videoTitle={script.exampleVideoTitle || ''}
          videoCaption={script.exampleVideoCaption || ''}
          videoSrc={[
            `${script.exampleVideoUrl}.mp4?${timestamp}`,
            `script.exampleVideoUrl?${timestamp}`
          ]}
          videoThumbnail={script.exampleVideoThumbnailUrl}
          decoration={{
            customFrameSource: script.videoFrameImageSource
          }}
          thumbnailMaxSize={600}
          thumbnailQuality={0.7}
          onClose={this.close}
          onSave={this.submit}
        />
      </FullScreenDialog>
    );
  }
}

export default inject('ScriptsStore')(observer(VideoSettingsForm));
