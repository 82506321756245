import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import TextField from '@material-ui/core/TextField';
import MaskedInput from 'react-text-mask';
import FormDialog from 'Components/Dialog/FormDialog';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import agent from 'agent';
import Loader from 'Components/Progress/Loader';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '+',
        '1',
        ' ',
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

class MerchantDetailsForm extends React.Component {
  static propTypes = {
    MerchantsStore: PropTypes.observableObject,
  };
  componentWillMount = async () => {
    const merchantTypes = await agent.MerchantTypes.list();
    const billingPlans = await agent.BillingPlan.list();
    this.setState({ merchantTypes, billingPlans });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.submit();
  };
  submit = () => {
    this.props.MerchantsStore.submitMerchantChanges();
  };
  cancel = () => {
    this.props.MerchantsStore.closeMerchantEditor();
  };
  makeChangeHandler = (name) => {
    return (e) => {
      this.props.MerchantsStore.registerMerchantChange(name, e.target.value);
    };
  };
  render() {
    const {
      merchantId,
      isBusy,
      values,
      errors = {},
    } = this.props.MerchantsStore.merchantEditor;
    const { merchantTypes, billingPlans } = this.state || {};
    const title = merchantId ? 'Update user details' : 'Register new user';
    return (
      <Loader isBusy={!merchantTypes || !billingPlans}>
        <FormDialog
          title={title}
          isBusy={isBusy}
          handleCancel={this.cancel}
          handleSubmit={this.submit}
        >
          <form onSubmit={this.handleFormSubmit}>
            <br />
            <FormControl fullWidth>
              <InputLabel>Type</InputLabel>
              <Select
                fullWidth
                style={{ marginTop: 20 }}
                placeholder="Select user type"
                value={values.merchantTypeId || ''}
                onChange={this.makeChangeHandler('merchantTypeId')}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {(merchantTypes || []).map((type) => (
                  <MenuItem value={type.id} key={type.id}>
                    <em>{type.name}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {!merchantId && (
              <FormControl fullWidth>
                <InputLabel>Subscribe to billing plan</InputLabel>
                <Select
                  fullWidth
                  style={{ marginTop: 20 }}
                  placeholder="Select billing plan"
                  value={values.billingPlanId || ''}
                  onChange={this.makeChangeHandler('billingPlanId')}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {(billingPlans || [])
                    .filter(({ isActive }) => isActive)
                    .map((plan) => (
                      <MenuItem value={plan.id} key={plan.id}>
                        <em>{plan.title}</em>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            <TextField
              label="Name"
              placeholder="User name"
              value={values.name || ''}
              error={!!errors.name}
              helperText={errors.name}
              fullWidth
              margin="normal"
              onChange={this.makeChangeHandler('name')}
            />
            <FormControl fullWidth placeholder={''}>
              <InputLabel htmlFor="businessPhone">Business phone</InputLabel>
              <Input
                value={values.businessPhone || ''}
                error={!!errors.businessPhone}
                fullWidth
                onChange={this.makeChangeHandler('businessPhone')}
                id="businessPhone"
                inputComponent={TextMaskCustom}
              />
              {errors.businessPhone && (
                <FormHelperText>{errors.businessPhone}</FormHelperText>
              )}
            </FormControl>
            {!merchantId && (
              <React.Fragment>
                <TextField
                  type="email"
                  label="Email"
                  placeholder="Email address"
                  value={values.email || ''}
                  error={!!errors.email}
                  helperText={errors.email}
                  fullWidth
                  margin="normal"
                  onChange={this.makeChangeHandler('email')}
                />
              </React.Fragment>
            )}
            <TextField
              type="password"
              label="Password"
              placeholder="Password"
              value={values.password || ''}
              error={!!errors.password}
              helperText={errors.password}
              fullWidth
              margin="normal"
              onChange={this.makeChangeHandler('password')}
            />
            {merchantId && (
              <React.Fragment>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.isAutoRechargeEnabled}
                      onChange={() =>
                        this.props.MerchantsStore.registerMerchantChange(
                          'isAutoRechargeEnabled',
                          !values.isAutoRechargeEnabled
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Auto recharge"
                />
                <TextField
                  label="Customers per phone number"
                  placeholder="Customers per phone number"
                  value={values.customersPerNumber || ''}
                  error={!!errors.customersPerNumber}
                  helperText={errors.customersPerNumber}
                  fullWidth
                  margin="normal"
                  onChange={this.makeChangeHandler('customersPerNumber')}
                />
              </React.Fragment>
            )}
          </form>
        </FormDialog>
      </Loader>
    );
  }
}

export default inject('MerchantsStore')(observer(MerchantDetailsForm));
