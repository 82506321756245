import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormDialog from 'Components/Dialog/FormDialog';
import Loader from 'Components/Progress/Loader';
import DataAutosuggest from 'Components/Autosuggest/DataAutosuggest';
import { ROLES } from '../../roles';

const INTERVALS = [
  { value: 'day', label: 'Day' },
  { value: 'week', label: 'Week' },
  { value: 'month', label: 'Month' },
  { value: 'year', label: 'Year' },
];

class BillingPlanDetailsForm extends React.Component {
  static propTypes = {
    BillingPlanStore: PropTypes.observableObject,
    MerchantTypesStore: PropTypes.observableObject,
  };
  componentWillMount() {
    this.props.MerchantTypesStore.load();
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.submit();
  };
  submit = () => {
    this.props.BillingPlanStore.submitBillingPlanChanges();
  };
  cancel = () => {
    this.props.BillingPlanStore.closeBillingPlanEditor();
  };
  makeChangeHandler = (name) => {
    return (e) => {
      this.props.BillingPlanStore.registerBillingPlanChange(
        name,
        e.target.value
      );
    };
  };
  toggleRoleHandler = (e) => {
    const {
      target: { value: role },
    } = e;
    let {
      BillingPlanStore: {
        billingPlanEditor: {
          values: { roles },
        },
      },
    } = this.props;
    if (roles.includes(role)) {
      roles = roles.filter((val) => val !== role);
    } else {
      roles.push(role);
    }

    this.props.BillingPlanStore.registerBillingPlanChange('roles', roles);
  };
  render() {
    const {
      BillingPlanStore: {
        billingPlanEditor: { planId, isBusy, values, errors = {} },
      },
      MerchantTypesStore: { merchantTypes, isLoading: isLoadingMerchantTypes },
    } = this.props;
    const title = planId
      ? 'Update billing plan details'
      : 'Add new billing plan';

    return (
      <Loader isBusy={isLoadingMerchantTypes}>
        <FormDialog
          title={title}
          isBusy={isBusy}
          handleCancel={this.cancel}
          handleSubmit={this.submit}
        >
          <form onSubmit={this.handleFormSubmit}>
            <TextField
              label="Title"
              placeholder="Billing plan title"
              value={values.title || ''}
              error={!!errors.title}
              helperText={errors.title}
              fullWidth
              margin="normal"
              onChange={this.makeChangeHandler('title')}
            />
            <TextField
              label="Description"
              placeholder="Billing plan description"
              value={values.description || ''}
              error={!!errors.description}
              helperText={errors.description}
              fullWidth
              multiline
              margin="normal"
              onChange={this.makeChangeHandler('description')}
            />
            <TextField
              label="Trial period"
              placeholder="Trial period, days"
              value={values.trialPeriodDays >= 0 ? values.trialPeriodDays : 30}
              error={!!errors.trialPeriodDays}
              helperText={errors.trialPeriodDays}
              fullWidth
              margin="normal"
              onChange={this.makeChangeHandler('trialPeriodDays')}
            />
            <DataAutosuggest
              suggestions={merchantTypes.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
              placeholder="Available for user types"
              onChange={(values) =>
                this.props.BillingPlanStore.registerBillingPlanChange(
                  'merchantTypes',
                  values || []
                )
              }
              value={values.merchantTypes
                .map((option) => {
                  if (option.label) {
                    return option;
                  }

                  const merchantType = merchantTypes.find(
                    (merchantType) => merchantType.id === option.value
                  );
                  if (!merchantType) {
                    return null;
                  }

                  return { ...option, label: merchantType.name };
                })
                .filter((val) => !!val)}
              isMulti
            />
            <FormControl component="fieldset" style={{ marginTop: 20 }}>
              <FormLabel component="legend">Active functions</FormLabel>
              <FormGroup>
                {ROLES.map(({ role, label }) => (
                  <FormControlLabel
                    key={role}
                    control={
                      <Checkbox
                        checked={values.roles.includes(role)}
                        onChange={this.toggleRoleHandler}
                        value={role}
                      />
                    }
                    label={label}
                  />
                ))}
              </FormGroup>
            </FormControl>
            {!planId && (
              <React.Fragment>
                <FormControl fullWidth margin="normal">
                  <legend>
                    These parameters can not be changed once you submit form
                  </legend>
                </FormControl>
                <TextField
                  type="number"
                  label="Price"
                  placeholder="Billing plan price, $"
                  value={values.price || ''}
                  error={!!errors.price}
                  helperText={errors.price}
                  fullWidth
                  onChange={this.makeChangeHandler('price')}
                />
                <FormControl error={errors.interval} fullWidth margin="normal">
                  <InputLabel>Interval</InputLabel>
                  <Select
                    placeholder="Select interval"
                    value={values.interval || ''}
                    onChange={this.makeChangeHandler('interval')}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {INTERVALS.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.interval && (
                    <FormHelperText>{errors.interval}</FormHelperText>
                  )}
                </FormControl>
                <TextField
                  type="number"
                  label="Frequency"
                  value={values.frequency || ''}
                  error={!!errors.frequency}
                  helperText={errors.frequency}
                  fullWidth
                  margin="normal"
                  onChange={this.makeChangeHandler('frequency')}
                />
              </React.Fragment>
            )}
          </form>
        </FormDialog>
      </Loader>
    );
  }
}

export default inject(
  'BillingPlanStore',
  'MerchantTypesStore'
)(observer(BillingPlanDetailsForm));
