import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import TablePagination from './TablePagination';
import Paper from '@material-ui/core/Paper';
import EditableImage from 'Components/Image/EditableImage';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import VideoPreview from 'Components/Video/Preview';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import TextField from '@material-ui/core/TextField';
import green from '@material-ui/core/colors/green';
import CircularProgress from '@material-ui/core/CircularProgress';
import { formatDateTime, formatDate } from '../../Utils/date';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  buttons: {
    display: 'flex',
    align: 'center'
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative'
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: 7,
    left: 7,
    zIndex: 1
  }
});

const getValue = (record, columnConfig) => {
  if (
    record[columnConfig.key] !== undefined &&
    record[columnConfig.key] !== null
  ) {
    return record[columnConfig.key];
  }

  return columnConfig.defaultValue || null;
};

const renderCellContent = (id, record, columnConfig) => {
  const value = getValue(record, columnConfig);
  let content;

  switch (columnConfig.type) {
    case 'date':
      content = formatDate(value);
      return <span title={content}>{content}</span>;
    case 'date-time':
      content = formatDateTime(value);
      return <span title={content}>{content}</span>;
    case 'video':
      return (
        <VideoPreview
          url={value}
          thumbnailUrl={record[columnConfig.thumbnailKey]}
          frameSource={record.videoFrameImageSource}
        />
      );
    case 'flag':
      return value ? 'Yes' : 'No';
    case 'image':
      return (
        <EditableImage
          imageUrl={value}
          onChange={
            columnConfig.onChange
              ? file => columnConfig.onChange(id, file)
              : null
          }
          onDelete={
            columnConfig.onDelete ? () => columnConfig.onDelete(id) : null
          }
        />
      );
    case 'custom':
      return columnConfig.render(record);
    default:
      return <span title={value}>{value}</span>;
  }
};

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    fontSize: 14,
    padding: '4px 24px'
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '40px',
    padding: '4px 24px'
  }
}))(TableCell);

const WrappedTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    fontSize: 14,
    padding: '4px 24px'
  },
  body: {
    fontSize: 14,
    overflow: 'hidden',
    maxWidth: '40px',
    padding: '4px 24px'
  }
}))(TableCell);

class ConfiguredTable extends React.Component {
  state = {};
  renderRowToolbar = dataRecord => {
    const { rowToolbar, classes } = this.props;
    if (!rowToolbar || rowToolbar.length === 0) {
      return null;
    }

    return (
      <TableCell>
        <div className={classes.buttons}>
          {rowToolbar.map(
            ({ title, icon, onClick, isVisible, isBusy, isDisabled }) => {
              if (isVisible && !isVisible(dataRecord)) {
                return null;
              }
              return (
                <div key={title} className={classes.wrapper}>
                  <Tooltip title={title} onClick={() => onClick(dataRecord)}>
                    <IconButton
                      size="small"
                      color="primary"
                      aria-label={title}
                      disabled={isDisabled && isDisabled(dataRecord)}
                    >
                      {icon}
                    </IconButton>
                  </Tooltip>
                  {isBusy && isBusy(dataRecord) && (
                    <CircularProgress
                      size={34}
                      className={classes.fabProgress}
                    />
                  )}
                </div>
              );
            }
          )}
        </div>
      </TableCell>
    );
  };
  toggleRow = rowId => {
    this.setState({
      selectedRow: this.state.selectedRow === rowId ? null : rowId
    });
  };
  render() {
    const {
      classes,
      data,
      toolbar,
      pagination,
      minWidth,
      renderExpandedRow,
      noWrap
    } = this.props;
    const { selectedRow } = this.state;
    const { keyAttribute = 'id' } = data;
    const CellComponent = noWrap === true ? WrappedTableCell : CustomTableCell;

    const emptyRows = pagination
      ? pagination.rowsPerPage -
        Math.min(
          pagination.rowsPerPage,
          pagination.totalRowsCount -
            pagination.currentPage * pagination.rowsPerPage
        )
      : 0;

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          {toolbar && <EnhancedTableToolbar {...{ ...toolbar }} />}
          <Table className={classes.table} style={minWidth ? { minWidth } : {}}>
            {this.renderHead()}
            <TableBody>
              {data.rows.map(record => {
                return (
                  <React.Fragment key={record[keyAttribute]}>
                    <TableRow
                      role="checkbox"
                      hover
                      style={renderExpandedRow ? { cursor: 'pointer' } : {}}
                      onClick={() => this.toggleRow(record[keyAttribute])}
                    >
                      {data.columns.map(column => {
                        const { key, type } = column;
                        return (
                          <CellComponent
                            key={key}
                            numeric={type === 'numeric'}
                            style={column.width ? { width: column.width } : {}}
                          >
                            {renderCellContent(
                              record[keyAttribute],
                              record,
                              column
                            )}
                          </CellComponent>
                        );
                      })}
                      {this.renderRowToolbar(record)}
                    </TableRow>
                    {renderExpandedRow && selectedRow === record[keyAttribute] && (
                      <TableRow>
                        <CellComponent colSpan={data.columns.length}>
                          {renderExpandedRow(record)}
                        </CellComponent>
                      </TableRow>
                    )}
                  </React.Fragment>
                );
              })}
              {data.rows.length === 0 && (
                <TableRow>
                  <CellComponent colSpan={data.columns.length}>
                    {data.emptyDataText || 'No data'}
                  </CellComponent>
                </TableRow>
              )}
              {emptyRows > 0 &&
                data.showEmptyRows &&
                pagination.totalRowsCount > 0 && (
                  <TableRow style={{ height: 48 * emptyRows }}>
                    <CellComponent colSpan={data.columns.length} />
                  </TableRow>
                )}
            </TableBody>
            {pagination && (
              <TableFooter>
                <TableRow>
                  <TablePagination {...pagination} />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </div>
      </Paper>
    );
  }
  renderHead() {
    const {
      data,
      rowToolbar,
      order,
      orderBy,
      sortHandler,
      applyFilter,
      noWrap
    } = this.props;
    const CellComponent = noWrap === true ? WrappedTableCell : CustomTableCell;
    return (
      <TableHead>
        <TableRow>
          {data.columns.map(({ title, key, sortable, type, width }) => {
            if (!sortable || !sortHandler) {
              return (
                <CellComponent key={key} style={width ? { width } : {}}>
                  {title}
                </CellComponent>
              );
            }
            return (
              <CellComponent
                key={key}
                sortDirection={orderBy === key ? order : false}
                style={width ? { width } : {}}
              >
                <Tooltip
                  title="Sort"
                  placement={type === 'numeric' ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === key}
                    direction={order}
                    onClick={() => sortHandler(key)}
                  >
                    {title}
                  </TableSortLabel>
                </Tooltip>
              </CellComponent>
            );
          })}
          {rowToolbar && rowToolbar.length > 0 && (
            <CellComponent>Actions</CellComponent>
          )}
        </TableRow>
        {applyFilter && (
          <TableRow>
            {data.columns.map(columnConfig =>
              this.renderFilterCell(columnConfig)
            )}
            {rowToolbar && rowToolbar.length > 0 && <TableCell />}
          </TableRow>
        )}
      </TableHead>
    );
  }
  renderFilterCell(columnConfig) {
    const { applyFilter, filters } = this.props;
    const { key, filterData, filterKey } = columnConfig;
    const filterType =
      columnConfig.filterType || (filterData ? 'select' : null);
    const value = filters[filterKey || key];

    let timeout;
    const debounceApplyFilter = (key, value) => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      timeout = setTimeout(() => {
        applyFilter(key, value);
        timeout = null;
      }, 500);
    };

    switch (filterType) {
      case 'text':
        return (
          <TableCell key={key}>
            <TextField
              defaultValue={value || ''}
              onChange={e =>
                debounceApplyFilter(filterKey || key, e.target.value)
              }
              margin="normal"
            />
          </TableCell>
        );
      case 'flag':
        return (
          <TableCell key={key}>
            {
              <Select
                value={value === undefined ? '' : value}
                onChange={e => applyFilter(filterKey || key, e.target.value)}
                fullWidth
              >
                <MenuItem value="" />
                {[
                  { value: true, label: 'Yes' },
                  { value: false, label: 'No' }
                ].map(record => (
                  <MenuItem key={record.value} value={record.value}>
                    {record.label}
                  </MenuItem>
                ))}
              </Select>
            }
          </TableCell>
        );
      case 'select':
        return (
          <TableCell key={key}>
            {
              <Select
                value={value || ''}
                onChange={e => applyFilter(filterKey || key, e.target.value)}
                fullWidth
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {filterData.map(record => (
                  <MenuItem key={record.value} value={record.value}>
                    {record.label}
                  </MenuItem>
                ))}
              </Select>
            }
          </TableCell>
        );
      default:
        return <TableCell key={key} />;
    }
  }
}

ConfiguredTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
        defaultValue: PropTypes.string,
        sortable: PropTypes.bool,
        filterData: PropTypes.array,
        filterKey: PropTypes.string,
        filterType: PropTypes.string,
        type: PropTypes.oneOf([
          'numeric',
          'date',
          'date-time',
          'image',
          'video',
          'custom',
          'flag'
        ]),
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    ).isRequired,
    keyAttribute: PropTypes.string,
    rows: PropTypes.array.isRequired,
    emptyDataText: PropTypes.string
  }).isRequired,
  pagination: PropTypes.shape({
    ...TablePagination.propTypes,
    showEmptyRows: PropTypes.bool
  }),
  rowToolbar: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
      onClick: PropTypes.func.isRequired,
      isVisible: PropTypes.func,
      isBusy: PropTypes.func,
      isDisabled: PropTypes.func
    })
  ),
  toolbar: PropTypes.shape({
    ...EnhancedTableToolbar.propTypes
  }),
  order: PropTypes.string,
  orderBy: PropTypes.string,
  sortHandler: PropTypes.func,
  applyFilter: PropTypes.func,
  filters: PropTypes.object,
  minWidth: PropTypes.number,
  renderExpandedRow: PropTypes.func,
  noWrap: PropTypes.bool
};

export default withStyles(styles)(ConfiguredTable);
