import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import { ToggleOff, ToggleOn } from '@material-ui/icons';
import agent from 'agent';
import Table from 'Components/Table/Table';
import Loader from 'Components/Progress/Loader';
import { debounce } from 'Utils/Decorators';
import VideoPreview from 'Components/Video/Preview';
import MerchantPicker from 'Components/MerchantPicker';

class Videos extends React.Component {
  state = {
    isLoading: false,
    rowsPerPage: 10,
    page: 0,
    order: 'desc',
    orderBy: null,
    filters: {},
    selectedMerchant: { value: '', label: 'All' },
    videos: [],
    totalVideosCount: 0
  };
  componentDidMount() {
    this.props.CommonStore.setTitle('Videos');
    this.loadVideos();
  }
  loadVideos = debounce(async () => {
    const {
      isLoading,
      page,
      rowsPerPage,
      orderBy,
      order,
      filters,
      selectedMerchant
    } = this.state;

    if (isLoading) {
      return;
    }
    this.setState({ isLoading: true });

    try {
      const { data: videos, count: totalVideosCount } = await agent.Video.list(
        page,
        rowsPerPage,
        orderBy,
        order,
        {
          ...filters,
          merchantId: selectedMerchant ? selectedMerchant.value : ''
        }
      );
      this.setState({ isLoading: false, videos, totalVideosCount });
    } catch (e) {
      this.props.CommonStore.handleError(e);
      this.setState({ isLoading: false });
    }
  }, 500);
  handleChangePage = (event, page) => {
    if (this.state.isLoading) {
      return;
    }
    this.setState({ page });
    this.loadVideos();
  };

  handleChangeRowsPerPage = event => {
    if (this.state.isLoading) {
      return;
    }
    this.setState({ rowsPerPage: event.target.value });
    this.loadVideos();
  };

  setSort = attribute => {
    const { isLoading, order, orderBy } = this.state;
    if (isLoading) {
      return;
    }
    if (orderBy !== attribute) {
      this.setState({ order: 'asc', orderBy: attribute });
      this.loadVideos();
      return;
    }

    const newOrder = order === 'asc' ? 'desc' : 'asc';
    this.setState({ order: newOrder });
    this.loadVideos();
  };

  applyFilter = (attribute, value) => {
    const { filters, isLoading } = this.state;
    if (isLoading) {
      return;
    }
    if (value !== '' && value !== undefined) {
      filters[attribute] = value;
    } else {
      delete filters[attribute];
    }
    this.setState({ filters: { ...filters } });
    this.loadVideos();
  };

  selectManager = selectedMerchant => {
    if (this.state.isLoading) {
      return;
    }
    this.setState({ selectedMerchant });
    this.loadVideos();
  };
  render() {
    const {
      isLoading,
      rowsPerPage,
      page,
      order,
      orderBy,
      filters,
      selectedMerchant,
      videos,
      totalVideosCount
    } = this.state;
    return (
      <Loader isBusy={isLoading && videos.length === 0}>
        <Table
          data={{
            columns: this.getColumns(),
            rows: videos,
            emptyDataText: 'There are no videos',
            showEmptyRows: true
          }}
          toolbar={{
            title: 'Videos',
            render: () => {
              return (
                <div
                  style={{ width: '100%', paddingLeft: 50, textAlign: 'left' }}
                >
                  <MerchantPicker
                    customOptions={[{ value: 'demo', label: 'Demo videos' }]}
                    value={selectedMerchant}
                    onChange={this.selectManager}
                    disabled={isLoading}
                  />
                </div>
              );
            }
          }}
          rowToolbar={[
            {
              title: 'Add to curated list',
              icon: <ToggleOff />,
              isVisible: video => !video.isInCuratedList,
              onClick: async ({ id }) => {
                if (this.state.isLoading) {
                  return;
                }
                await agent.Video.changeCuratedListPresence(id, true);
                this.loadVideos();
              }
            },
            {
              title: 'Delete from curated list',
              icon: <ToggleOn />,
              isVisible: video => video.isInCuratedList,
              onClick: async ({ id }) => {
                if (this.state.isLoading) {
                  return;
                }
                await agent.Video.changeCuratedListPresence(id, false);
                this.loadVideos();
              }
            }
          ]}
          pagination={{
            totalRowsCount: totalVideosCount,
            currentPage: page,
            handleChangePage: this.handleChangePage,
            handleChangeRowsPerPage: this.handleChangeRowsPerPage,
            rowsPerPage
          }}
          order={order}
          orderBy={orderBy}
          sortHandler={this.setSort}
          applyFilter={this.applyFilter}
          filters={filters}
        />
      </Loader>
    );
  }
  getColumns() {
    return [
      {
        title: 'Preview',
        type: 'custom',
        key: 'url',
        width: '200px',
        render: video => {
          return (
            <VideoPreview
              url={[`${video.url}.mp4`, video.url]}
              overlay={video.overlay}
              thumbnailUrl={video.thumbnailUrl}
              frameSource={video.frameImageSource}
            />
          );
        }
      },
      {
        title: 'Title',
        key: 'title',
        sortable: true,
        filterType: 'text',
        filterKey: 'search',
        width: '400px'
      },
      {
        title: 'Recorded by',
        key: 'merchantName',
        sortable: false
      },
      {
        title: 'Is in curated list',
        key: 'isInCuratedList',
        type: 'flag',
        filterType: 'flag',
        filterKey: 'isInCuratedList',
        width: '150px'
      },
      {
        title: 'Recorded at',
        key: 'recordedAt',
        type: 'date-time',
        sortable: true,
        width: '200px'
      }
    ].filter(val => !!val);
  }
}

Videos.propTypes = {
  CommonStore: PropTypes.observableObject
};

export default inject('CommonStore')(observer(Videos));
