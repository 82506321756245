import React from 'react';
import DatePicker from 'react-datepicker';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { TODAY, LAST_WEEK, LAST_MONTH, LAST_YEAR } from 'Stores/AnalyticStore';
import { inject, observer, PropTypes } from 'mobx-react';
import ReactPropTypes from 'prop-types';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import './RangeSelector.css';

const styles = theme => ({
  root: {
    '& > div': {
      paddingLeft: theme.spacing.unit,
      paddingRight: theme.spacing.unit
    }
  },
  dateRangePicker: {
    border: 'none',
    padding: theme.spacing.unit,
    boxShadow: theme.shadows[2],
    width: '100%'
  }
});

const BUTTONS = [
  { label: 'Today', interval: TODAY },
  { label: 'Last week', interval: LAST_WEEK },
  { label: 'Last month', interval: LAST_MONTH },
  { label: 'Last year', interval: LAST_YEAR }
];

function RangeSelector({ classes, AnalyticStore }) {
  const { isBusy, dateRange } = AnalyticStore;
  const startDate = new Date(dateRange[0]);
  const endDate = new Date(dateRange[1]);

  return (
    <>
      <Grid
        container
        className={classes.root}
        justify="center"
        alignItems="center"
      >
        {BUTTONS.map(({ interval, label }) => (
          <Grid item xs={3} key={interval}>
            <Button
              fullWidth
              variant={
                AnalyticStore.interval === interval ? 'outlined' : 'contained'
              }
              color="primary"
              onClick={() => AnalyticStore.setInterval(interval)}
              disabled={isBusy}
            >
              {label}
            </Button>
          </Grid>
        ))}
      </Grid>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={6}>
          <DatePicker
            className={classes.dateRangePicker}
            selected={startDate}
            onChange={date =>
              AnalyticStore.setInterval([
                moment(date).format('MM/DD/Y'),
                dateRange[1]
              ])
            }
            selectsStart
            startDate={startDate}
            endDate={endDate}
            maxDate={new Date()}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            className={classes.dateRangePicker}
            selected={endDate}
            onChange={date =>
              AnalyticStore.setInterval([
                dateRange[0],
                moment(date).format('MM/DD/Y')
              ])
            }
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={new Date()}
          />
        </Grid>
      </Grid>
    </>
  );
}

RangeSelector.propTypes = {
  AnalyticStore: PropTypes.observableObject.isRequired,
  classes: ReactPropTypes.object.isRequired
};

export default withStyles(styles)(
  inject('AnalyticStore')(observer(RangeSelector))
);
