import { action, decorate, computed, flow, observable } from 'mobx';
import agent from 'agent';
import CommonStore from 'Stores/CommonStore';

const EDITOR_SETTINGS = 'settings';
const EDITOR_LEVEL = 'level';
const EDITOR_LEVEL_DATA = 'levelData';
const EDITOR_ERRORS = 'errors';

class PricingStore {
  isLoading = false;
  isSubmittingChanges = false;

  pricing = observable.map({});
  editorRegistry = observable.map({});

  loadPricing = flow(function*() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    try {
      const pricing = yield agent.Pricing.load();
      const levels = [GLOBAL_LEVEL, MERCHANT_TYPE_LEVEL, MERCHANT_LEVEL];
      for (let i in levels) {
        this.pricing.set(levels[i], pricing[levels[i]]);
      }
    } catch (e) {
      CommonStore.handleError(e);
    }
    this.isLoading = false;
  });

  get globalSettings() {
    return this.pricing.get('global');
  }
  get merchantTypeSettings() {
    return this.pricing.get(MERCHANT_TYPE_LEVEL);
  }
  get merchantSettings() {
    return this.pricing.get(MERCHANT_LEVEL);
  }

  startEditor = (level, levelId) => {
    this.editorRegistry.set(EDITOR_LEVEL, level);
    this.editorRegistry.set(EDITOR_LEVEL_DATA, {
      value: levelId,
      label: 'label'
    });

    const levelData = this.pricing.get(level);

    const settings =
      level === GLOBAL_LEVEL
        ? levelData
        : levelData.find(entry => entry.levelId === levelId);

    this.editorRegistry.set(
      EDITOR_SETTINGS,
      settings
        ? {
            ...settings,
            [EDITOR_LEVEL_DATA]: {
              value: settings.levelId,
              label: settings.levelName
            }
          }
        : { messagePrice: 0.1, minimumBalance: 10 }
    );
    this.editorRegistry.set('isUpdate', !!levelId);
    this.editorRegistry.set(EDITOR_ERRORS, {});
  };
  setPricingAttribute = (attribute, value) => {
    const settings = this.editorRegistry.get(EDITOR_SETTINGS);
    this.editorRegistry.set(EDITOR_SETTINGS, {
      ...settings,
      [attribute]: value
    });
  };
  submitPricingChanges = flow(function*() {
    if (this.isSubmittingChanges) {
      return;
    }
    this.isSubmittingChanges = true;
    try {
      const {
        messagePrice,
        minimumBalance,
        fullBalance,
        levelData
      } = this.editorRegistry.get(EDITOR_SETTINGS);
      const level = this.editorRegistry.get(EDITOR_LEVEL);

      if (level && !levelData) {
        return;
      }

      yield agent.Pricing.change(
        messagePrice,
        minimumBalance,
        fullBalance,
        level === MERCHANT_TYPE_LEVEL ? levelData.value : null,
        level === MERCHANT_LEVEL ? levelData.value : null
      );

      yield this.loadPricing();
      this.closeEditor();
    } catch (err) {
      const { validationErrors } = err.response.body;
      this.editorRegistry.set(EDITOR_ERRORS, validationErrors);
    }
    this.isSubmittingChanges = false;
  });
  closeEditor = () => this.editorRegistry.clear();

  deleteLevelSettings = flow(function*(level, levelId) {
    try {
      this.isLoading = true;
      yield agent.Pricing.deleteLevelSettings(level, levelId);
      this.isLoading = false;
      yield this.loadPricing();
    } catch (err) {
      CommonStore.handleError(err);
    }
  });

  get editor() {
    const level = this.editorRegistry.get('level');
    if (!level) {
      return null;
    }

    return {
      levelId: this.editorRegistry.get('levelId'),
      settings: this.editorRegistry.get('settings'),
      errors: this.editorRegistry.get('errors'),
      isUpdate: this.editorRegistry.get('isUpdate'),
      level
    };
  }
}

const MobxPricingStore = decorate(PricingStore, {
  isLoading: observable,
  isSubmittingChanges: observable,

  globalSettings: computed,
  merchantTypeSettings: computed,
  merchantSettings: computed,
  editor: computed,

  loadPricing: action,
  startEditor: action,
  setPricingAttribute: action,
  closeEditor: action
});

export const GLOBAL_LEVEL = 'global';
export const MERCHANT_TYPE_LEVEL = 'merchantTypeLevel';
export const MERCHANT_LEVEL = 'merchantLevel';

export default new MobxPricingStore();
