import { observable, action, reaction, decorate } from 'mobx';
import { BRAND } from 'Utils/whitelabeling';

const keyName = 'admin-jwt';

class CommonStore {
  title = BRAND;
  token = window.localStorage.getItem(keyName);
  confirmation = {
    active: false,
    description: null,
    action: null
  };
  errorMessage;

  constructor() {
    reaction(
      () => this.token,
      token => {
        if (token) {
          window.localStorage.setItem(keyName, token);
        } else {
          window.localStorage.removeItem(keyName);
        }
      }
    );
  }

  setTitle(title) {
    this.title = title;
  }

  setToken(token) {
    this.token = token;
  }

  confirmAction(description, action) {
    this.confirmation.active = true;
    this.confirmation.description = description;
    this.confirmation.action = () => {
      action();
      this.closeConfirmationDialog();
    };
  }

  closeConfirmationDialog() {
    this.confirmation.active = false;
    this.confirmation.description = null;
    this.confirmation.action = null;
  }

  handleError(err) {
    if (err.response) {
      const { message = null } = err.response.body || {};
      this.errorMessage = message;
      return;
    }
  }

  dismissErrorMessage() {
    this.errorMessage = null;
  }
}

const MobxCommonStore = decorate(CommonStore, {
  confirmation: observable,
  errorMessage: observable,
  title: observable,
  token: observable,
  closeConfirmationDialog: action,
  confirmAction: action,
  dismissErrorMessage: action,
  handleError: action,
  setTitle: action,
  setToken: action
});

export default new MobxCommonStore();
