import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';

const styles = () => ({
  card: {
    maxWidth: 400
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
    objectFit: 'contain'
  },
  actions: {
    display: 'flex'
  },
  progress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -40,
    marginLeft: -40
  }
});

class ImageCard extends React.Component {
  render() {
    const { classes, image, onDelete, onTitleChange } = this.props;

    return (
      <Card className={classes.card}>
        {image.uploading && (
          <CircularProgress size={80} className={classes.progress} />
        )}
        <CardMedia
          className={classes.media}
          image={image.url}
          title={image.title}
        />
        <CardContent>
          <TextField
            value={image.title}
            fullWidth
            onChange={e => onTitleChange(e.target.value)}
          />
        </CardContent>
        <CardActions className={classes.actions} disableActionSpacing>
          <Button size="small" variant="contained" onClick={onDelete}>
            Delete
          </Button>
        </CardActions>
      </Card>
    );
  }
}

ImageCard.propTypes = {
  classes: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onTitleChange: PropTypes.func.isRequired
};

export default withStyles(styles)(ImageCard);
