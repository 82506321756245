import React from 'react';
import agent from 'agent';
import { inject, observer, PropTypes } from 'mobx-react';
import { Add, Edit, Delete } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Table from 'Components/Table/Table';
import Loader from 'Components/Progress/Loader';
import Uploader from './Uploader';
import AssetForm from './AssetForm';
import TypeSwitcher from './TypeSwitcher';

class GraphicAssets extends React.Component {
  static propTypes = {
    CommonStore: PropTypes.observableObject,
    GraphicAssetsStore: PropTypes.observableObject,
  };
  componentDidMount() {
    this.props.CommonStore.setTitle('Graphic assets');
    this.props.GraphicAssetsStore.reset();
    this.props.GraphicAssetsStore.loadAssets();
    this.props.GraphicAssetsStore.loadMessageTemplates();
    this.props.GraphicAssetsStore.loadMerchantTypes();
  }
  handleDelete = (asset) => {
    this.props.CommonStore.confirmAction('Delete asset?', () => {
      const { GraphicAssetsStore } = this.props;
      GraphicAssetsStore.deleteAsset(asset.id);
    });
  };
  handleChangePage = (event, page) => {
    this.props.GraphicAssetsStore.setPage(page);
    this.props.GraphicAssetsStore.loadAssets();
  };

  handleChangeRowsPerPage = (event) => {
    this.props.GraphicAssetsStore.setRowsPerPage(event.target.value);
    this.props.GraphicAssetsStore.loadAssets();
  };

  setSort = (attribute) => {
    this.props.GraphicAssetsStore.setSort(attribute);
    this.props.GraphicAssetsStore.loadAssets();
  };

  applyFilter = (attribute, value) => {
    this.props.GraphicAssetsStore.applyFilter(attribute, value);
    this.props.GraphicAssetsStore.loadAssets();
  };
  render() {
    const {
      assets,
      assetUploader,
      assetEditor,
      messageTemplates,
      isLoading,
      rowsPerPage,
      totalAssetsCount,
      page,
      order,
      orderBy,
      filters,
    } = this.props.GraphicAssetsStore;

    if (assetUploader.active) {
      return <Uploader />;
    }

    if (assetEditor.active) {
      return <AssetForm />;
    }

    return (
      <Loader
        isBusy={(isLoading && assets.length === 0) || messageTemplates.isBusy}
      >
        <TypeSwitcher />
        <Table
          data={{
            columns: this.getColumns(),
            rows: assets,
            emptyDataText: 'There are no assets yet',
            showEmptyRows: true,
          }}
          toolbar={{
            buttons: [
              {
                title: 'Upload',
                icon: <Add />,
                onClick: () =>
                  this.props.GraphicAssetsStore.startAssetsUploader(),
              },
            ],
          }}
          rowToolbar={[
            {
              title: 'Update asset',
              icon: <Edit />,
              onClick: (asset) =>
                this.props.GraphicAssetsStore.startAssetEditor(asset.id),
            },
            {
              title: 'Delete asset',
              icon: <Delete />,
              onClick: this.handleDelete,
            },
          ]}
          pagination={{
            totalRowsCount: totalAssetsCount,
            currentPage: page,
            handleChangePage: this.handleChangePage,
            handleChangeRowsPerPage: this.handleChangeRowsPerPage,
            rowsPerPage,
          }}
          order={order}
          orderBy={orderBy}
          sortHandler={this.setSort}
          applyFilter={this.applyFilter}
          filters={filters}
        />
      </Loader>
    );
  }
  getColumns() {
    const { messageTemplates, merchantTypes, filters } =
      this.props.GraphicAssetsStore;
    return [
      {
        title: '',
        key: 'url',
        type: 'image',
        width: '200px',
      },
      {
        title: 'Title',
        key: 'title',
        sortable: true,
        filterType: 'text',
        filterKey: 'searchKey',
        width: '400px',
      },
      {
        title: 'Favorite for',
        type: 'custom',
        key: 'messageTemplates',
        filterData: Object.values(messageTemplates.templates).map(
          (template) => ({
            value: template.id,
            label: template.title,
          })
        ),
        width: '200px',
        filterKey: 'messageTemplateId',
        render: (asset) => {
          const messageTemplates = asset.messageTemplates || [];

          return (
            <section>
              {messageTemplates.map((template) => {
                return (
                  <Typography component="p" key={template.id}>
                    {template.title}
                  </Typography>
                );
              })}
            </section>
          );
        },
      },
      filters.messageTemplateId
        ? {
            title: 'Position',
            key: 'position',
            type: 'custom',
            render: (asset) => {
              const messageTemplate = asset.messageTemplates.find(
                (val) => val.id === filters.messageTemplateId
              );
              if (!messageTemplate) {
                return null;
              }
              let isBusy = false;
              const updatePosition = async (newPosition) => {
                if (isBusy) {
                  return;
                }
                if (messageTemplate.assetPosition === newPosition) {
                  return;
                }
                isBusy = true;
                console.log(asset.id, messageTemplate.id, newPosition);
                messageTemplate.assetPosition = newPosition;
                await agent.GraphicAssets.linkToMessageTemplateAtPosition(
                  asset.id,
                  messageTemplate.id,
                  newPosition
                );
                isBusy = false;
              };
              return (
                <TextField
                  defaultValue={messageTemplate.assetPosition || ''}
                  onBlur={(e) => {
                    updatePosition(
                      e.target.value ? parseInt(e.target.value) : null
                    );
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      updatePosition(
                        e.target.value ? parseInt(e.target.value) : null
                      );
                    }
                  }}
                />
              );
            },
          }
        : null,
      {
        title: 'For user type',
        type: 'custom',
        key: 'merchantTypes',
        filterData: Object.values(merchantTypes.types).map((type) => ({
          value: type.id,
          label: type.name,
        })),
        width: '200px',
        filterKey: 'merchantTypeId',
        render: (asset) => {
          const merchantTypes = asset.merchantTypes || [];
          return (
            <section>
              {merchantTypes.map((merchantType) => {
                return (
                  <Typography component="p" key={merchantType.value}>
                    {merchantType.label}
                  </Typography>
                );
              })}
            </section>
          );
        },
      },
      {
        title: 'Created at',
        key: 'createdAt',
        type: 'date-time',
        sortable: true,
        width: '200px',
      },
    ].filter((val) => !!val);
  }
}
export default inject(
  'CommonStore',
  'GraphicAssetsStore'
)(observer(GraphicAssets));
