import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import { Editor } from 'react-cam-recorder';
import 'react-cam-recorder/css/index.css';
import Paper from '@material-ui/core/Paper';
import agent from 'agent';
import { TYPE_OVERLAY, TYPE_TREATMENT } from 'Stores/GraphicAssetsStore';

import './ScriptVideoEditor.css';

const framesSource = async (offset, limit) => {
  const { data } = await agent.VideoFrame.list(offset, limit);
  return data.map(({ id, title, imageSource }) => ({
    id,
    url: imageSource,
    title
  }));
};

class ScriptVideoEditor extends React.Component {
  state = { hasFrames: null };
  static propTypes = {
    ScriptsStore: PropTypes.observableObject
  };
  detectHasFrames = async () => {
    const { count } = await agent.VideoFrame.list(0, 0);
    this.setState({ hasFrames: count > 0 });
  };
  componentDidMount() {
    this.detectHasFrames();
  }
  close = () => this.props.ScriptsStore.closeScriptVideoEditor();
  saveOverlay = async (overlay, selectedFrame) => {
    await this.props.ScriptsStore.saveVideoOverlay(overlay, selectedFrame);
    setTimeout(this.close, 100);
  };
  loadAssets = async (offset, limit, filters) => {
    const appliedFilters = (filters || []).reduce(
      (result, filter) => {
        return { ...result, [filter.filter]: filter.value };
      },
      { type: TYPE_OVERLAY }
    );
    const assets = await agent.GraphicAssets.all(
      offset / limit,
      limit,
      null,
      null,
      appliedFilters
    );
    return assets.data.map(({ url }) => ({ title: '', url }));
  };
  render() {
    const { hasFrames } = this.state;

    if (hasFrames === null) {
      return null;
    }

    const { scriptVideoEditor } = this.props.ScriptsStore;
    const {
      id,
      exampleVideoFrameId,
      exampleVideoUrl,
      exampleVideoOverlay,
      scriptText
    } = scriptVideoEditor.script;
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 10000
        }}
      >
        <Paper>
          <Editor
            onClose={this.close}
            videoSrc={[`${exampleVideoUrl}.mp4`, exampleVideoUrl]}
            scriptText={scriptText}
            tutorialLink="https://www.digitalcare100.com/video-resources"
            assetsSource={this.loadAssets}
            assetsFilters={[
              {
                title: 'suggested',
                filter: 'messageTemplateId',
                value: id
              },
              {
                title: 'treatment',
                filter: 'type',
                value: TYPE_TREATMENT
              }
            ]}
            availableFonts={[
              'coming soon',
              'montserrat',
              'oswald',
              'short stack',
              'passion one',
              'comfortaa',
              'courier new',
              'sans serif',
              'avenir',
              'railway'
            ]}
            logo={{ url: '/logo.png', bottom: 50, right: 50 }}
            overlay={exampleVideoOverlay}
            onSave={this.saveOverlay}
            enableWindowsChromeWorkaround={true}
            framesSource={hasFrames ? framesSource : null}
            selectedFrame={exampleVideoFrameId}
          />
        </Paper>
      </div>
    );
  }
}

export default inject('ScriptsStore')(observer(ScriptVideoEditor));
