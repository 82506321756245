import React from 'react';
import ReactPropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer, PropTypes } from 'mobx-react';
import Recorder from 'Components/Recorder';
import Paper from '@material-ui/core/Paper';
import FullScreenDialog from 'Components/Dialog/FullScreenDialog';
import agent from 'agent';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing.unit * 3,
    margin: 'auto',
    maxWidth: '80%',
  },
  progress: {
    position: 'absolute',
    left: theme.spacing.unit * 5,
    right: theme.spacing.unit * 5,
    top: theme.spacing.unit * 10,
    zIndex: 1,
  },
});

class ScriptVideoRecorder extends React.Component {
  static propTypes = {
    classes: ReactPropTypes.object.isRequired,
    ScriptsStore: PropTypes.observableObject,
    CommonStore: PropTypes.observableObject,
  };
  state = {};
  startVideoReadyEventListener = () => {
    this.videoReadyEventCheckerInterval = setInterval(
      this.checkVideoReady,
      10000
    );
  };
  componentWillUnmount() {
    clearInterval(this.videoReadyEventCheckerInterval);
  }
  checkVideoReady = async () => {
    const { script } = this.props.ScriptsStore.scriptVideoRecorder;
    const { updatedScript } = this.state;

    if (!updatedScript) {
      const updatedScript = await agent.MessageTemplates.byId(script.id);
      if (
        updatedScript.exampleVideoId !== null &&
        updatedScript.exampleVideoId !== script.exampleVideoId
      ) {
        this.setState({ updatedScript });
      }
    }

    if (updatedScript) {
      const processed = await this.checkVideoProcessed(
        updatedScript.exampleVideoUrl
      );

      if (processed) {
        this.props.ScriptsStore.setUpdatedScript(updatedScript);
        this.startVideoEditor(script.id);
      }
    }
  };
  checkVideoProcessed = async (url) => {
    const path = url
      .split('/')
      .filter((part) => !!part)
      .slice(2)
      .join('/');
    return await agent.VideoRecorder.checkVideoProcessed(path);
  };
  close = () => this.props.ScriptsStore.closeScriptVideoRecorder();
  sendMagicLink = async (phone) => {
    try {
      await agent.MagicLink.send(
        phone,
        'Use this link to record video',
        this.getMagicLinkParameters()
      );
      this.startVideoReadyEventListener();
    } catch (e) {
      console.log(e);
    }
  };
  getMagicLinkParameters() {
    const { script } = this.props.ScriptsStore.scriptVideoRecorder;
    return {
      messageTemplateId: script.id,
      to: '/record-video',
    };
  }
  getQrCodeData = async () => {
    this.startVideoReadyEventListener();
    if (this.state.mobileRecordingLink) {
      return this.state.mobileRecordingLink;
    }

    try {
      const { link } = await agent.MagicLink.get(this.getMagicLinkParameters());
      this.setState({ mobileRecordingLink: link });
      return link;
    } catch (e) {
      this.props.CommonStore.handleError(e);
    }
  };
  uploadVideo = async (video, selectedFrame) => {
    console.log(video);
    const { ScriptsStore } = this.props;
    const { script } = ScriptsStore.scriptVideoRecorder;
    await this.props.ScriptsStore.uploadScriptVideo(video, selectedFrame);

    this.startVideoEditor(script.id);
  };
  startVideoEditor = (scriptId) => {
    setTimeout(() => {
      this.close();
      this.props.ScriptsStore.startScriptVideoEditor(scriptId);
    }, 100);
  };
  useVideoUrl = async (videoUrl) => {
    const { ScriptsStore } = this.props;
    const { script } = ScriptsStore.scriptVideoRecorder;
    await this.props.ScriptsStore.uploadScriptVideoUrl(videoUrl);

    setTimeout(() => {
      this.close();
      ScriptsStore.startScriptVideoEditor(script.id);
    }, 100);
  };
  renderRecorder() {
    const { scriptVideoRecorder } = this.props.ScriptsStore;
    const { title, scriptText } = scriptVideoRecorder.script;
    const scriptTextFormatter = (scriptText) =>
      scriptText.replace(/\[.+?\]/g, '');
    const self = this;
    return (
      <React.Fragment>
        <Recorder
          videoMimeType="video/webm;codecs=vp8"
          script={{
            text: scriptText,
            formatter: scriptTextFormatter,
            title,
          }}
          onScriptTextChange={(text) => console.log(text)}
          onVideoReady={this.uploadVideo}
          onClose={this.close}
          mobileRecordSettings={{
            sendLink: this.sendMagicLink,
          }}
          startTimeout={3}
          autoStopTimeout={1}
          qrCodeData={this.getQrCodeData}
          framesSource={async (offset, limit) => {
            const page = Math.floor(offset / limit);
            const { data } = await agent.VideoFrame.list(
              page,
              limit,
              'createdAt',
              'DESC',
              { available: true }
            );
            return data.map(({ title, imageSource }) => ({
              url: imageSource,
              title,
            }));
          }}
        />
        <label
          style={{
            position: 'absolute',
            bottom: 80,
            left: 30,
            cursor: 'pointer',
          }}
          className="button button--style-default button--size-s button--color-blue-ribbon modal__header-save"
        >
          Upload video
          <input
            accept="video/*"
            style={{ display: 'none', position: 'relative' }}
            multiple
            type="file"
            onChange={async function (event) {
              const file = event.target.files[0];
              const fileData = await file.arrayBuffer();

              self.uploadVideo(
                new Blob([new Uint8Array(fileData)], {
                  type: file.type,
                })
              );
            }}
            title="Upload video"
          />
        </label>
      </React.Fragment>
    );
  }
  render() {
    const { classes } = this.props;
    return (
      <FullScreenDialog>
        <Paper className={classes.paper}>{this.renderRecorder()}</Paper>
      </FullScreenDialog>
    );
  }
}

export default inject('ScriptsStore')(
  withStyles(styles)(observer(ScriptVideoRecorder))
);
