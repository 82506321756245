import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import TextField from '@material-ui/core/TextField';
import FormDialog from 'Components/Dialog/FormDialog';

class CategoryForm extends React.Component {
  static propTypes = {
    CategoriesStore: PropTypes.observableObject
  };
  constructor(props) {
    super(props);
    const { taxonomy } = this.props.CategoriesStore.taxonomyForm;
    this.state = { title: taxonomy ? taxonomy.title : '' };
  }
  handleFormSubmit = e => {
    e.preventDefault();
    this.submit();
  };
  submit = () => {
    const {
      taxonomyForm: { taxonomy }
    } = this.props.CategoriesStore;
    taxonomy
      ? this.props.CategoriesStore.renameTaxonomy(taxonomy.id, this.state.title)
      : this.props.CategoriesStore.createTaxonomy(this.state.title);
  };
  cancel = () => {
    this.props.CategoriesStore.closeTaxonomyForm();
  };
  render() {
    const { isBusy, taxonomy } = this.props.CategoriesStore.taxonomyForm;
    return (
      <FormDialog
        title={taxonomy ? 'Rename category group' : 'Create new category group'}
        isBusy={isBusy}
        handleCancel={this.cancel}
        handleSubmit={this.submit}
      >
        <form onSubmit={this.handleFormSubmit}>
          <TextField
            label="Title"
            autoFocus={true}
            placeholder="Category group title"
            value={this.state.title}
            fullWidth
            margin="normal"
            onChange={e => this.setState({ title: e.target.value })}
          />
        </form>
      </FormDialog>
    );
  }
}

export default inject('CategoriesStore')(observer(CategoryForm));
