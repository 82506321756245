import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import agent from 'agent';
import TextField from '@material-ui/core/TextField';
import FormDialog from 'Components/Dialog/FormDialog';
import DataAutosuggest from 'Components/Autosuggest/DataAutosuggest';
import { debounce } from 'Utils/Decorators';
import {
  GLOBAL_LEVEL,
  MERCHANT_TYPE_LEVEL,
  MERCHANT_LEVEL,
} from 'Stores/PricingStore';

class LevelPricingForm extends React.Component {
  static propTypes = {
    PricingStore: PropTypes.observableObject,
    CommonStore: PropTypes.observableObject,
  };
  searchMerchantType = async (term) => {
    if (!this.merchantTypes) {
      this.merchantTypes = await agent.MerchantTypes.list();
    }
    return {
      data: this.merchantTypes.filter(
        ({ name }) => name.toLowerCase().indexOf(term.toLowerCase()) !== -1
      ),
    };
  };
  constructor(props) {
    super(props);
    this.searchLevels = debounce(async (term, callback) => {
      try {
        const {
          editor: { level },
        } = this.props.PricingStore;

        const { data } =
          level === MERCHANT_LEVEL
            ? await agent.Merchants.search(term)
            : await this.searchMerchantType(term);
        const options = data.map(({ id, name }) => ({
          value: id,
          label: name,
        }));
        callback(options);
      } catch (err) {
        this.props.CommonStore.handleError(err);
      }
    }, 500);
  }
  handleFormSubmit = (e) => {
    e.preventDefault();
    this.submit();
  };
  submit = () => {
    this.props.PricingStore.submitPricingChanges();
  };
  cancel = () => {
    this.props.PricingStore.closeEditor();
  };
  renderLevelFinderInput = () => {
    const {
      setPricingAttribute,
      editor: {
        level,
        isUpdate,
        settings: { levelData },
      },
    } = this.props.PricingStore;
    if (level === GLOBAL_LEVEL) {
      return null;
    }

    let placeholder;

    switch (level) {
      case MERCHANT_LEVEL:
        placeholder = 'Select user';
        break;
      case MERCHANT_TYPE_LEVEL:
        placeholder = 'Select user type';
        break;
      default:
        placeholder = '';
    }

    return (
      <div style={{ marginTop: 20 }}>
        <DataAutosuggest
          loadOptions={this.searchLevels}
          placeholder={placeholder}
          onChange={(selected) => setPricingAttribute('levelData', selected)}
          value={levelData}
          disabled={isUpdate}
          clearable={true}
        />
      </div>
    );
  };
  render() {
    const {
      isSubmittingChanges,
      setPricingAttribute,
      editor: {
        settings: { messagePrice, minimumBalance, fullBalance },
        errors,
      },
    } = this.props.PricingStore;

    const title = 'Change level pricing';

    return (
      <FormDialog
        title={title}
        isBusy={isSubmittingChanges}
        handleCancel={this.cancel}
        handleSubmit={this.submit}
      >
        <form onSubmit={this.handleFormSubmit}>
          {this.renderLevelFinderInput()}
          <TextField
            type="number"
            label="Message price, $"
            placeholder="Message price, $"
            value={messagePrice}
            error={!!errors.messagePrice}
            helperText={errors.messagePrice}
            fullWidth
            margin="normal"
            onChange={(event) =>
              setPricingAttribute('messagePrice', event.target.value)
            }
          />
          <TextField
            type="number"
            label="Minimum balance, $"
            placeholder="Minimum balance, $"
            value={minimumBalance}
            error={!!errors.minimumBalance}
            helperText={errors.minimumBalance}
            fullWidth
            margin="normal"
            onChange={(event) =>
              setPricingAttribute('minimumBalance', event.target.value)
            }
          />
          <TextField
            type="number"
            label="Full balance, $"
            placeholder="Full balance, $"
            value={fullBalance}
            error={!!errors.fullBalance}
            helperText={errors.fullBalance}
            fullWidth
            margin="normal"
            onChange={(event) =>
              setPricingAttribute('fullBalance', event.target.value)
            }
          />
        </form>
      </FormDialog>
    );
  }
}

export default inject(
  'PricingStore',
  'CommonStore'
)(observer(LevelPricingForm));
