import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import Table from 'Components/Table/Table';
import Loader from 'Components/Progress/Loader';
import { Delete } from '@material-ui/icons';
import agent from '../../agent';

class DeliveryFailures extends React.Component {
  state = {};
  static propTypes = {
    DeliveryFailuresStore: PropTypes.observableObject,
    CommonStore: PropTypes.observableObject,
  };
  componentWillMount() {
    this.props.CommonStore.setTitle('Delivery failures');
    this.props.DeliveryFailuresStore.reset();
    this.props.DeliveryFailuresStore.load();
  }
  handleChangePage = (event, page) => {
    this.props.DeliveryFailuresStore.setPage(page);
  };

  handleChangeRowsPerPage = (event) => {
    this.props.DeliveryFailuresStore.setRowsPerPage(event.target.value);
  };
  releasePhone = async (merchantId, phone) => {
    this.setState({ isReleasingPhone: true });
    await agent.Merchants.releasePhone(merchantId, phone);
    setTimeout(() => {
      this.setState({ isReleasingPhone: false });
      this.props.DeliveryFailuresStore.load();
    }, 2000);
  };
  render() {
    const { failures, isLoading, page, rowsPerPage, totalFailuresCount } =
      this.props.DeliveryFailuresStore;

    return (
      <Loader isBusy={isLoading || this.state.isReleasingPhone}>
        <Table
          data={{
            keyAttribute: 'phone',
            columns: DeliveryFailures.getColumns(),
            rows: failures,
            emptyDataText: 'There are no failures yet',
            showEmptyRows: true,
          }}
          rowToolbar={[
            {
              title: 'Release phone number',
              icon: <Delete />,
              onClick: ({ merchantId, phone }) =>
                this.releasePhone(merchantId, phone),
            },
          ]}
          pagination={{
            totalRowsCount: totalFailuresCount,
            currentPage: page,
            handleChangePage: this.handleChangePage,
            handleChangeRowsPerPage: this.handleChangeRowsPerPage,
            rowsPerPage,
          }}
        />
      </Loader>
    );
  }
  static getColumns() {
    return [
      {
        title: 'Phone',
        key: 'phone',
      },
      {
        title: 'User',
        key: 'merchantName',
      },
      {
        title: 'Failures count',
        key: 'failuresCount',
      },
      {
        title: 'Last failure date',
        key: 'lastFailureDate',
        type: 'date-time',
      },
    ];
  }
}
export default inject(
  'DeliveryFailuresStore',
  'CommonStore'
)(observer(DeliveryFailures));
