/* eslint-disable max-statements */

import { action, computed, decorate, flow, observable, toJS } from 'mobx';
import agent from 'agent';
import CommonStore from 'Stores/CommonStore';
import {
  observableMapToObject,
  observableArrayToJs,
  base64ToBlob
} from './utils';
import * as Taxonomy from 'Utils/Taxonomy';

const editableAttributes = [
  'title',
  'description',
  'scriptText',
  'messageText',
  'landingPageText',
  'imageUrl',
  'isLogoPlaceholder'
];

const isBusyKey = 'isBusy';

const formatCategories = categories =>
  categories.map(category => ({
    label: category.path.join('>'),
    value: JSON.stringify(category)
  }));

class ScriptsStore {
  isLoading = false;
  page = 0;
  rowsPerPage = 10;
  totalScriptsCount = 0;
  scriptsRegistry = observable.map();
  scriptEditorRegistry = observable.map({
    [isBusyKey]: false,
    active: false,
    scriptId: null,
    suggestedCategories: null,
    linkedCategories: null,
    values: observable.map(),
    errors: observable.map()
  });
  scriptVideoRecorderRegistry = observable.map({
    [isBusyKey]: false,
    active: false,
    scriptId: null
  });
  scriptVideoEditorRegistry = observable.map({
    [isBusyKey]: false,
    active: false,
    scriptId: null
  });
  scriptVideoSettingsRegistry = observable.map({
    [isBusyKey]: false,
    active: false,
    scriptId: null
  });
  filtersRegistry = observable.map();

  reset() {
    this.isLoading = false;
    this.page = 0;
    this.rowsPerPage = 10;
    this.totalScriptsCount = 0;
    this.scriptsRegistry.clear();
    this.filtersRegistry.clear();
    this.closeScriptEditor();
  }

  loadScripts = flow(function*() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    yield Taxonomy.loadTaxonomies();
    try {
      const { data, count } = yield agent.MessageTemplates.all(
        this.page,
        this.rowsPerPage,
        this.filters
      );
      this.scriptsRegistry.clear();
      data.forEach(script => this.scriptsRegistry.set(script.id, script));
      this.totalScriptsCount = count;
    } catch (e) {
      CommonStore.handleError(e);
    }
    this.isLoading = false;
  });

  setFilters = filters => {
    if (this.isLoading) {
      return;
    }
    this.filtersRegistry.clear();
    Object.keys(filters).map(
      key => filters[key] && this.filtersRegistry.set(key, filters[key])
    );
    this.scriptsRegistry.clear();
    this.loadScripts();
  };

  submitScriptChanges = flow(function*() {
    if (this.scriptEditorRegistry.get(isBusyKey)) {
      return;
    }
    this.scriptEditorRegistry.set(isBusyKey, true);
    try {
      const { values } = this.scriptEditor;
      const linkedCategories = this.scriptEditor.linkedCategories.map(
        cat => cat.value
      );
      const {
        title,
        description,
        scriptText,
        messageText,
        landingPageText,
        imageUrl,
        isLogoPlaceholder
      } = values;

      let newImageUrl = imageUrl;
      if (newImageUrl instanceof File) {
        const { cdnUrl } = yield agent.S3.uploadToDirectory('giphys', imageUrl);
        newImageUrl = cdnUrl;
      }

      let scriptId = this.scriptEditor.scriptId;
      if (scriptId) {
        yield agent.MessageTemplates.update(
          scriptId,
          title,
          description,
          scriptText,
          messageText,
          landingPageText,
          newImageUrl,
          isLogoPlaceholder
        );
      } else {
        const response = yield agent.MessageTemplates.create(
          title,
          description,
          scriptText,
          messageText,
          landingPageText,
          newImageUrl,
          isLogoPlaceholder
        );
        scriptId = response.id;
      }

      const alreadyLinkedCategories = formatCategories(
        Taxonomy.getLinkedCategories(scriptId)
      ).map(({ value }) => value);
      const categoriesToRemove = alreadyLinkedCategories.filter(
        i => linkedCategories.indexOf(i) < 0
      );
      const categoriesToLinkTo = linkedCategories.filter(
        i => alreadyLinkedCategories.indexOf(i) < 0
      );

      const promises = [];
      for (let i in categoriesToRemove) {
        const { taxonomyId, path } = JSON.parse(categoriesToRemove[i]);
        promises.push(
          agent.ScriptCategories.deleteScript(
            taxonomyId,
            JSON.stringify(path.slice(1)),
            scriptId
          )
        );
      }
      for (let i in categoriesToLinkTo) {
        const { taxonomyId, path } = JSON.parse(categoriesToLinkTo[i]);
        promises.push(
          agent.ScriptCategories.addScript(
            taxonomyId,
            JSON.stringify(path.slice(1)),
            scriptId
          )
        );
      }

      yield Promise.all(promises);

      yield Taxonomy.loadTaxonomies(true);

      this.loadScripts();
      this.closeScriptEditor();
    } catch (err) {
      const { validationErrors } = err.response.body;
      this.setEditorErrors(validationErrors);
    }
    this.scriptEditorRegistry.set(isBusyKey, false);
  });
  deleteScript = flow(function*(id) {
    try {
      this.scriptsRegistry.delete(id);
      yield agent.MessageTemplates.delete(id);
      this.loadScripts();
    } catch (e) {
      CommonStore.handleError(e);
    }
  });

  uploadScriptVideo = flow(function*(video, selectedFrame) {
    if (this.scriptVideoRecorderRegistry.get(isBusyKey)) {
      return;
    }
    this.scriptVideoRecorderRegistry.set(isBusyKey, true);
    const { script } = this.scriptVideoRecorder;
    if (!script) {
      return;
    }

    const { key, cdnUrl } = yield agent.S3.uploadToDirectory('video', video);
    const videoTitle = `"${script.title}" example video`;
    const { id } = yield agent.Video.record(
      videoTitle,
      cdnUrl,
      key,
      {
        scriptText: script.scriptText,
        templateId: script.id
      },
      selectedFrame ? selectedFrame.id : null
    );

    yield agent.MessageTemplates.addExampleVideo(script.id, id);

    const updatedScript = {
      ...script,
      exampleVideoId: id,
      exampleVideoTitle: videoTitle,
      exampleVideoCaption: null,
      exampleVideoUrl: cdnUrl,
      exampleVideoThumbnailUrl: null,
      exampleVideoOverlay: null
    };
    this.scriptsRegistry.set(updatedScript.id, updatedScript);
    this.scriptVideoRecorderRegistry.set(isBusyKey, false);
  });

  deleteScriptVideo = flow(function*(templateId) {
    if (this.scriptVideoRecorderRegistry.get(isBusyKey)) {
      return;
    }
    this.scriptVideoRecorderRegistry.set(isBusyKey, true);
    const template = this.scriptsRegistry.get(templateId);
    if (!template) {
      return;
    }

    yield agent.MessageTemplates.addExampleVideo(templateId, null);

    const updatedTemplate = {
      ...template,
      exampleVideoId: null,
      exampleVideoTitle: null,
      exampleVideoCaption: null,
      exampleVideoUrl: null,
      exampleVideoThumbnailUrl: null,
      exampleVideoOverlay: null
    };
    this.scriptsRegistry.set(updatedTemplate.id, updatedTemplate);
    this.scriptVideoRecorderRegistry.set(isBusyKey, false);
  });

  setUpdatedScript(script) {
    this.scriptsRegistry.set(script.id, script);
  }

  uploadScriptVideoUrl = flow(function*(videoUrl) {
    if (this.scriptVideoRecorderRegistry.get(isBusyKey)) {
      return;
    }
    this.scriptVideoRecorderRegistry.set(isBusyKey, true);
    const { script } = this.scriptVideoRecorder;
    const videoTitle = `"${script.title}" example video`;
    if (!script) {
      return;
    }
    const { id } = yield agent.Video.record(videoTitle, videoUrl, '', {
      scriptText: script.scriptText,
      templateId: script.id
    });

    yield agent.MessageTemplates.addExampleVideo(script.id, id);

    const updatedScript = {
      ...script,
      exampleVideoId: id,
      exampleVideoTitle: videoTitle,
      exampleVideoCaption: null,
      exampleVideoUrl: videoUrl,
      exampleVideoThumbnailUrl: null,
      exampleVideoOverlay: null
    };
    this.scriptsRegistry.set(updatedScript.id, updatedScript);
    this.scriptVideoRecorderRegistry.set(isBusyKey, false);
  });
  removeExampleVideo = flow(function*() {
    const { script } = this.scriptVideoRecorder;
    if (!script) {
      return;
    }
    yield agent.MessageTemplates.addExampleVideo(script.id, null);
    const updatedScript = {
      ...script,
      exampleVideoId: null,
      exampleVideoUrl: null,
      exampleVideoOverlay: null
    };
    this.scriptsRegistry.set(updatedScript.id, updatedScript);
  });
  saveVideoOverlay = flow(function*(overlay, selectedFrame) {
    if (this.scriptVideoEditorRegistry.get(isBusyKey)) {
      return;
    }
    this.scriptVideoEditorRegistry.set(isBusyKey, true);
    const { script } = this.scriptVideoEditor;
    if (!script) {
      return;
    }
    yield agent.Video.customize(
      script.exampleVideoId,
      overlay,
      selectedFrame ? selectedFrame.id : null
    );

    const updatedScript = { ...script, exampleVideoOverlay: overlay };
    this.scriptsRegistry.set(updatedScript.id, updatedScript);
    this.scriptVideoEditorRegistry.set(isBusyKey, false);
  });
  updateScriptVideoSettings = flow(function*(title, caption, thumbnail) {
    if (this.scriptVideoSettingsRegistry.get(isBusyKey)) {
      return;
    }
    this.scriptVideoSettingsRegistry.set(isBusyKey, true);
    const { script } = this.scriptVideoSettings;
    if (!script) {
      return;
    }

    let thumbnailUrl = null;
    if (thumbnail) {
      const response = yield agent.S3.uploadToDirectory(
        'video-thumbnails',
        base64ToBlob(
          thumbnail.replace(/^data:image\/jpeg;base64,/, ''),
          'image/jpeg'
        )
      );
      thumbnailUrl = response.cdnUrl;
    }

    yield agent.Video.changeSettings(
      script.exampleVideoId,
      title,
      caption,
      thumbnailUrl
    );

    const updatedScript = {
      ...script,
      exampleVideoTitle: title,
      exampleVideoCaption: caption,
      exampleVideoThumbnailUrl: thumbnailUrl
    };
    this.scriptsRegistry.set(updatedScript.id, updatedScript);
    this.scriptVideoSettingsRegistry.set(isBusyKey, false);
  });

  get scripts() {
    return Object.values(observableMapToObject(this.scriptsRegistry));
  }

  get filters() {
    return observableMapToObject(this.filtersRegistry);
  }

  get scriptEditor() {
    return {
      [isBusyKey]: this.scriptEditorRegistry.get(isBusyKey),
      active: this.scriptEditorRegistry.get('active'),
      scriptId: this.scriptEditorRegistry.get('scriptId'),
      suggestedCategories:
        observableArrayToJs(
          this.scriptEditorRegistry.get('suggestedCategories')
        ) || null,
      linkedCategories:
        observableArrayToJs(
          this.scriptEditorRegistry.get('linkedCategories')
        ) || null,
      values: observableMapToObject(this.scriptEditorRegistry.get('values')),
      errors: observableMapToObject(this.scriptEditorRegistry.get('errors'))
    };
  }

  get scriptVideoRecorder() {
    return {
      [isBusyKey]: this.scriptVideoRecorderRegistry.get(isBusyKey),
      active: this.scriptVideoRecorderRegistry.get('active'),
      script: this.getScript(this.scriptVideoRecorderRegistry.get('scriptId'))
    };
  }

  get scriptVideoEditor() {
    const script = this.getScript(
      this.scriptVideoEditorRegistry.get('scriptId')
    );
    return {
      [isBusyKey]: this.scriptVideoEditorRegistry.get(isBusyKey),
      active: this.scriptVideoEditorRegistry.get('active'),
      script: script ? toJS(script) : null
    };
  }

  get scriptVideoSettings() {
    const script = this.getScript(
      this.scriptVideoSettingsRegistry.get('scriptId')
    );
    return {
      [isBusyKey]: this.scriptVideoSettingsRegistry.get(isBusyKey),
      active: this.scriptVideoSettingsRegistry.get('active'),
      script: script ? toJS(script) : null
    };
  }

  getScript(id) {
    return this.scriptsRegistry.get(id);
  }

  setPage(page) {
    this.page = page;
  }

  setRowsPerPage(rowsPerPage) {
    this.rowsPerPage = rowsPerPage;
  }

  setEditorErrors(errors = {}) {
    const editorErrors = this.scriptEditorRegistry.get('errors');
    editorErrors.clear();
    Object.keys(errors).map(attribute =>
      editorErrors.set(attribute, errors[attribute])
    );
  }

  startScriptEditor = id => {
    this.scriptEditorRegistry.set('active', true);
    this.scriptEditorRegistry.set('scriptId', id);
    const script = id ? this.getScript(id) : {};

    if (!this.scriptEditorRegistry.get('suggestedCategories')) {
      this.scriptEditorRegistry.set(
        'suggestedCategories',
        Taxonomy.getFlatTaxonomies()
      );
    }

    editableAttributes.forEach(attribute =>
      this.registerScriptChange(attribute, script[attribute])
    );
    this.scriptEditorRegistry.set(
      'linkedCategories',
      formatCategories(Taxonomy.getLinkedCategories(id))
    );
    this.setEditorErrors();
  };

  registerScriptChange(attribute, value) {
    if (!this.scriptEditorRegistry.get('active')) {
      return;
    }
    this.scriptEditorRegistry.get('values').set(attribute, value);
  }

  changeLinkedCategoriesSet(categories) {
    this.scriptEditorRegistry.set('linkedCategories', categories || []);
  }

  closeScriptEditor() {
    this.scriptEditorRegistry.set('active', false);
  }

  startScriptVideoRecorder(id) {
    this.scriptVideoRecorderRegistry.set('active', true);
    this.scriptVideoRecorderRegistry.set('scriptId', id);
  }

  closeScriptVideoRecorder() {
    this.scriptVideoRecorderRegistry.set('active', false);
  }

  startScriptVideoEditor(id) {
    this.scriptVideoEditorRegistry.set('active', true);
    this.scriptVideoEditorRegistry.set('scriptId', id);
  }

  closeScriptVideoEditor() {
    this.scriptVideoEditorRegistry.set('active', false);
  }

  startScriptVideoSettings(id) {
    this.scriptVideoSettingsRegistry.set('active', true);
    this.scriptVideoSettingsRegistry.set('scriptId', id);
  }

  closeScriptVideoSettings() {
    this.scriptVideoSettingsRegistry.set('active', false);
  }
}

const MobxScriptsStore = decorate(ScriptsStore, {
  isLoading: observable,
  page: observable,
  rowsPerPage: observable,
  scriptsRegistry: observable,
  totalScriptsCount: observable,

  scriptEditor: computed,
  scripts: computed,
  scriptVideoEditor: computed,
  scriptVideoRecorder: computed,
  filters: computed,

  changeLinkedCategoriesSet: action,
  closeScriptEditor: action,
  closeScriptVideoEditor: action,
  closeScriptVideoRecorder: action,
  closeScriptVideoSettings: action,
  createScript: action,
  deleteScript: action,
  loadScript: action,
  loadScripts: action,
  registerScriptChange: action,
  removeExampleVideo: action,
  reset: action,
  setPage: action,
  setRowsPerPage: action,
  setUpdatedScript: action,
  startScriptEditor: action,
  startScriptVideoEditor: action,
  startScriptVideoSettings: action,
  startScriptVideoRecorder: action,
  submitScriptChanges: action,
  updateScript: action,
  uploadScriptVideo: action,
  setFilters: action
});

export default new MobxScriptsStore();
