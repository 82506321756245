import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade/Fade';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import green from '@material-ui/core/colors/green';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
});

function ProgressButton(props) {
  const { isBusy, children, classes, ...rest } = props;
  return (
    <div className={classes.wrapper}>
      <Button {...rest} disabled={isBusy}>
        {children}
      </Button>
      <Fade
        in={isBusy}
        style={{
          transitionDelay: isBusy ? '800ms' : '0ms'
        }}
        unmountOnExit
      >
        <CircularProgress size={24} className={classes.buttonProgress} />
      </Fade>
    </div>
  );
}

ProgressButton.propTypes = {
  ...Button.propTypes,
  classes: PropTypes.object.isRequired,
  isBusy: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default withStyles(styles)(ProgressButton);
