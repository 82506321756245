import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from 'Components/Table/Table';
import agent from '../../agent';
import Progress from './Progress';

function IndividualMessageReport({ messageId, startDate, endDate }) {
  const [pagination, setPagination] = useState({ page: 0, pageSize: 10 });
  const [report, setReport] = useState({ data: [], total: 0 });
  const [isLoading, setIsLoading] = useState(false);

  async function loadReport() {
    setIsLoading(true);
    try {
      const report = await agent.Analytic.message(
        messageId,
        pagination.page * pagination.pageSize,
        pagination.pageSize,
        startDate,
        endDate
      );
      setReport(report);
    } catch (e) {
      // do nothing
    }

    setIsLoading(false);
  }

  function changePageSettings(page, pageSize) {
    if (isLoading) {
      return;
    }
    setPagination({ page, pageSize });
  }

  useEffect(() => {
    loadReport();
  }, []);
  useEffect(() => {
    loadReport();
  }, [pagination]);

  if (isLoading) {
    return <Progress />;
  }

  return (
    <React.Fragment>
      <Table
        noWrap
        toolbar={{ title: 'Customers' }}
        data={{
          columns: [
            {
              title: 'Name',
              key: 'name',
              width: 300
            },
            {
              title: 'Phone',
              key: 'phone'
            },
            {
              title: 'Sent from number',
              key: 'sentFromNumber'
            },
            {
              title: 'Date',
              key: 'sentAt',
              type: 'date-time',
              width: 200
            },
            {
              title: 'Carrier',
              key: 'carrierName',
              width: 200
            },
            {
              title: 'Delivered',
              key: 'isDelivered',
              type: 'flag'
            }
          ],
          rows: report.data.map(record => {
            const { firstName, lastName, ...rest } = record;
            return {
              ...rest,
              name: [firstName, lastName].filter(val => !!val).join(' ')
            };
          }),
          keyAttribute: 'customerId'
        }}
        pagination={{
          totalRowsCount: report.total,
          currentPage: pagination.page,
          handleChangePage: (e, newPage) =>
            changePageSettings(newPage, pagination.pageSize),
          handleChangeRowsPerPage: e =>
            changePageSettings(pagination.page, e.target.value),
          rowsPerPage: pagination.pageSize
        }}
      />
      <br />
    </React.Fragment>
  );
}

IndividualMessageReport.propTypes = {
  messageId: PropTypes.string.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string
};

export default IndividualMessageReport;
