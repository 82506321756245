import agent from 'agent';

let taxonomies;

export const loadTaxonomies = async (force = false) => {
  if (!force && taxonomies) {
    return;
  }
  const availableTaxonomies = await agent.ScriptCategories.list();
  taxonomies = await Promise.all(
    availableTaxonomies.map(({ id }) => agent.ScriptCategories.loadTree(id))
  );
};

export const getLinkedCategories = templateId => {
  return taxonomies.reduce((result, taxonomy) => {
    const linkedCategories = findInTree(taxonomy.tree, templateId);
    return result.concat(
      linkedCategories.map(linkedCategoryPath => ({
        taxonomyId: taxonomy.id,
        path: linkedCategoryPath
      }))
    );
  }, []);
};

const findInTree = (tree, templateId) => {
  const { items = [], title, categories = {} } = tree;
  const result = [];
  if (items.indexOf(templateId) !== -1) {
    result.push([title]);
  }
  const nestedResults = Object.values(categories).reduce(
    (result, nestedTree) => result.concat(findInTree(nestedTree, templateId)),
    []
  );
  return result.concat(
    nestedResults.map(nestedResult => [title].concat(nestedResult))
  );
};

export const getFlatTaxonomies = () => {
  return taxonomies.map(taxonomy => ({
    id: taxonomy.id,
    label: taxonomy.title,
    options: _getSubcategories(taxonomy.id, taxonomy.title, taxonomy.tree)
  }));
};

const _getSubcategories = (taxonomyId, taxonomyTitle, category) => {
  let result = [];
  Object.values(category.categories).forEach(category => {
    const { path } = category;
    const parsedPath = [taxonomyTitle].concat(JSON.parse(path));
    result.push({
      value: JSON.stringify({ taxonomyId, path: parsedPath }),
      label: parsedPath.join('>')
    });
    result = result.concat(
      _getSubcategories(taxonomyId, taxonomyTitle, category)
    );
  });

  return result;
};
