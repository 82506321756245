import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import queryString from 'query-string';
import Loader from 'Components/Progress/Loader';
import agent from 'agent';
import Recorder from 'Components/Recorder';
import FullScreenDialog from '../../Components/Dialog/FullScreenDialog';

class RecordVideo extends React.Component {
  state = { messageTemplate: null };
  componentDidMount = async () => {
    const { Routing, CommonStore } = this.props;
    const { search } = Routing.history.location;

    const query = queryString.parse(search);
    const { messageTemplateId } = query;
    try {
      const messageTemplate = await agent.MessageTemplates.byId(
        messageTemplateId
      );
      this.setState({ messageTemplate });
    } catch (e) {
      CommonStore.handleError(e);
    }
  };
  uploadVideo = async (video, selectedFrame) => {
    const { messageTemplate } = this.state;
    const { key, cdnUrl } = await agent.S3.uploadToDirectory('video', video);
    await agent.Video.record(
      `"${messageTemplate.title}" example video`,
      cdnUrl,
      key,
      {
        scriptText: messageTemplate.scriptText,
        templateId: messageTemplate.id
      },
      selectedFrame ? selectedFrame.id : null
    );
    this.close();
  };
  useVideoUrl = async videoUrl => {
    const { messageTemplate } = this.state;
    const {
      id
    } = await agent.Video.record(
      `"${messageTemplate.title}" example video`,
      videoUrl,
      '',
      { scriptText: messageTemplate.scriptText, templateId: messageTemplate.id }
    );
    await agent.MessageTemplates.addExampleVideo(messageTemplate.id, id);
    this.close();
  };
  close = () => {
    window.close();
  };
  renderRecorder() {
    const { messageTemplate } = this.state;
    const { title, scriptText } = messageTemplate;
    const scriptTextFormatter = scriptText =>
      scriptText.replace(/\[.+?\]/g, '');
    return (
      <Recorder
        videoMimeType="video/webm;codecs=vp8"
        script={{ text: scriptText, formatter: scriptTextFormatter, title }}
        onVideoReady={this.uploadVideo}
        onClose={this.close}
        startTimeout={3}
        autoStopTimeout={1}
        framesSource={async (offset, limit) => {
          const page = Math.floor(offset / limit);
          const { data } = await agent.VideoFrame.list(
            page,
            limit,
            'createdAt',
            'DESC',
            { available: true }
          );
          return data.map(({ title, imageSource }) => ({
            url: imageSource,
            title
          }));
        }}
      />
    );
  }
  render() {
    const { messageTemplate } = this.state;
    return (
      <Loader isBusy={!messageTemplate}>
        {messageTemplate && (
          <FullScreenDialog>{this.renderRecorder()}</FullScreenDialog>
        )}
      </Loader>
    );
  }
}

RecordVideo.propTypes = {
  CommonStore: PropTypes.observableObject,
  Routing: PropTypes.observableObject
};

export default inject('CommonStore', 'Routing')(observer(RecordVideo));
