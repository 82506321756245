import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import Table from 'Components/Table/Table';
import Grid from '@material-ui/core/Grid';
import MerchantPicker from 'Components/MerchantPicker';
import Progress from './Progress';
import MerchantTypeSelector from './MerchantTypeSelector';
import VideoPreview from 'Components/Video/Preview';

function VideosReport({ AnalyticStore }) {
  const {
    isLoadingVideosReport,
    videosReport: { data, total },
    videosReportFilters: { merchant, merchantType },
    videosReportPagination: { page, pageSize },
    changeVideosReportFilters,
    changeVideosReportPageSettings,
  } = AnalyticStore;
  if (isLoadingVideosReport) {
    return <Progress />;
  }

  return (
    <Table
      noWrap
      minWidth={1300}
      toolbar={{
        title: 'Videos',
        render: () => {
          return (
            <div style={{ width: '100%', paddingLeft: 40, textAlign: 'left' }}>
              <Grid container alignContent="center" spacing={8}>
                <Grid item xs={6}>
                  <MerchantPicker
                    value={merchant}
                    onChange={(merchant) => {
                      changeVideosReportFilters(merchant, merchantType);
                    }}
                    disabled={isLoadingVideosReport}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MerchantTypeSelector
                    value={merchantType}
                    onChange={(merchantType) =>
                      changeVideosReportFilters(merchant, merchantType)
                    }
                    disabled={isLoadingVideosReport}
                  />
                </Grid>
              </Grid>
            </div>
          );
        },
      }}
      data={{
        columns: [
          {
            title: 'Preview',
            type: 'custom',
            key: 'url',
            width: '200px',
            render: (video) => {
              return (
                <VideoPreview
                  url={[`${video.url}.mp4`, video.url]}
                  overlay={video.overlay}
                  thumbnailUrl={video.thumbnailUrl}
                  frameSource={video.frameImageSource}
                />
              );
            },
          },
          {
            title: 'Title',
            key: 'title',
            width: 300,
          },
          {
            title: 'User',
            key: 'merchantName',
            width: 200,
          },
          {
            title: 'Company',
            key: 'officeName',
            width: 200,
          },
          {
            title: 'Views',
            key: 'viewsCount',
          },
          {
            title: 'Plays',
            key: 'playsCount',
          },
          {
            title: 'Unique plays',
            key: 'uniquePlaysCount',
          },
          {
            title: 'Played seconds',
            key: 'playedSecondsCount',
          },
          {
            title: 'Average played seconds',
            key: 'averagePlayedSeconds',
          },
        ],
        rows: data.map((row) => {
          const { playsCount, playedSecondsCount } = row;
          const averagePlayedSeconds = playsCount
            ? (playedSecondsCount / playsCount).toFixed(2)
            : 0;
          return { ...row, averagePlayedSeconds };
        }),
        keyAttribute: 'videoId',
        showEmptyRows: true,
      }}
      pagination={{
        totalRowsCount: total,
        currentPage: page,
        handleChangePage: (e, newPage) =>
          changeVideosReportPageSettings(newPage, pageSize),
        handleChangeRowsPerPage: (e) =>
          changeVideosReportPageSettings(page, e.target.value),
        rowsPerPage: pageSize,
      }}
    />
  );
}

VideosReport.propTypes = {
  AnalyticStore: PropTypes.observableObject.isRequired,
};

export default inject('AnalyticStore')(observer(VideosReport));
