import React from 'react';
import ReactDOM from 'react-dom';
import { configure } from 'mobx';
import { createBrowserHistory } from 'history';
import { Provider } from 'mobx-react';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { Router } from 'react-router';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import './customFonts.css';
//import registerServiceWorker from './registerServiceWorker';
import BillingPlanStore from 'Stores/BillingPlanStore';
import CommonStore from 'Stores/CommonStore';
import AuthStore from 'Stores/AuthStore';
import MerchantsStore from 'Stores/MerchantsStore';
import MerchantTypesStore from 'Stores/MerchantTypesStore';
import CategoriesStore from 'Stores/CategoriesStore';
import ScriptsStore from 'Stores/ScriptsStore';
import GraphicAssetsStore from 'Stores/GraphicAssetsStore';
import VideoFramesStore from 'Stores/VideoFramesStore';
import PricingStore from 'Stores/PricingStore';
import AnalyticStore from 'Stores/AnalyticStore';
import DeliveryFailuresStore from 'Stores/DeliveryFailuresStore';
import App from 'App';

const isDev = process.env.NODE_ENV === 'development';

let DevTools;

if (isDev) {
  DevTools = require('mobx-react-devtools').default;
}

configure({
  enforceActions: 'always',
});

const browserHistory = createBrowserHistory();
const routingStore = new RouterStore();

const history = syncHistoryWithStore(browserHistory, routingStore);

const stores = {
  Routing: routingStore,
  BillingPlanStore,
  CommonStore,
  AuthStore,
  MerchantsStore,
  CategoriesStore,
  ScriptsStore,
  GraphicAssetsStore,
  PricingStore,
  MerchantTypesStore,
  VideoFramesStore,
  AnalyticStore,
  DeliveryFailuresStore,
};

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
});

ReactDOM.render(
  <Provider {...stores}>
    <Router history={history}>
      <React.Fragment>
        <MuiThemeProvider theme={theme}>
          <App />
        </MuiThemeProvider>
        {isDev && <DevTools />}
      </React.Fragment>
    </Router>
  </Provider>,
  document.getElementById('root')
);
//registerServiceWorker();
