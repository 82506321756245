import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import Table from 'Components/Table/Table';
import { Edit } from '@material-ui/icons';
import { GLOBAL_LEVEL } from 'Stores/PricingStore';

const getColumns = () => [
  {
    title: 'Message price, $',
    key: 'messagePrice'
  },
  {
    title: 'Minimum balance, $',
    key: 'minimumBalance'
  },
  {
    title: 'Full balance, $',
    key: 'fullBalance'
  }
];

class GlobalSettings extends React.Component {
  static propTypes = {
    PricingStore: PropTypes.observableObject
  };
  render() {
    const { globalSettings, startEditor } = this.props.PricingStore;
    return (
      <Table
        toolbar={{
          title: 'Global settings'
        }}
        data={{
          columns: getColumns(),
          rows: [{ ...globalSettings, id: 'global' }]
        }}
        rowToolbar={[
          {
            title: 'Edit',
            icon: <Edit />,
            onClick: () => startEditor(GLOBAL_LEVEL)
          }
        ]}
      />
    );
  }
}

export default inject('PricingStore')(observer(GlobalSettings));
